// store/slices/globalSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import formatDate from "../../utils/services";
import axiosInstance from "../../helpers/axios";

// Async thunk for fetching holidays
export const fetchHolidays = createAsyncThunk(
  "global/fetchHolidays",
  async () => {
    const response = await axiosInstance.get(`/holidays`);
    const holidaysData = response.data;
    const holidays = response.data.map((holiday) => formatDate(holiday.date));

    return {
      holidaysData,
      holidays,
    };
  }
);

// Async thunk for fetching designations
export const fetchDesignations = createAsyncThunk(
  "global/fetchDesignations",
  async () => {
    const designationsData = await axiosInstance.get(`/designations`);
    const allDesignations = designationsData.data.data.map((d) => d.name);
    const billedDesignations = designationsData.data.data
      .filter((designation) => designation.billed)
      .map((d) => d.name);
    const unBilledDesignations = designationsData.data.data
      .filter((designation) => !designation.billed)
      .map((d) => d.name);

    return {
      designations: designationsData.data.data,
      allDesignations,
      billedDesignations,
      unBilledDesignations,
    };
  }
);

const globalSlice = createSlice({
  name: "global",
  initialState: {
    holidays: [],
    holidaysData: [],
    designations: [],
    allDesignations: [],
    billedDesignations: [],
    unBilledDesignations: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Handle holidays
      .addCase(fetchHolidays.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchHolidays.fulfilled, (state, action) => {
        state.holidays = action.payload.holidays;
        state.holidaysData = action.payload.holidaysData;
        state.loading = false;
      })
      .addCase(fetchHolidays.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      // Handle designations
      .addCase(fetchDesignations.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDesignations.fulfilled, (state, action) => {
        state.designations = action.payload.designations;
        state.allDesignations = action.payload.allDesignations;
        state.billedDesignations = action.payload.billedDesignations;
        state.unBilledDesignations = action.payload.unBilledDesignations;
        state.loading = false;
      })
      .addCase(fetchDesignations.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default globalSlice.reducer;
