// actions/currencyActions.js
import axiosInstance from "../helpers/axios";
import { errorAlert, successAlert } from "../utils/services";

export const FETCH_CURRENCY_SETTINGS = "FETCH_CURRENCY_SETTINGS";
export const UPDATE_CURRENCY_SETTINGS = "UPDATE_CURRENCY_SETTINGS";

// Fetch currency settings action
export const fetchCurrencySettings = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/currency-settings`);
    if (response.data.success) {
      dispatch({
        type: FETCH_CURRENCY_SETTINGS,
        payload: response.data.data,
      });
    }
  } catch (error) {
    console.error("Error fetching currency settings:", error);
    errorAlert("Failed to fetch currency settings.");
  }
};

// Update currency settings action
export const updateCurrencySettings = (currencyData) => async (dispatch) => {
  try {
    await axiosInstance.post(`/currency-settings`, currencyData);
    dispatch({
      type: UPDATE_CURRENCY_SETTINGS,
      payload: currencyData,
    });
    successAlert("Currency settings updated successfully!");
  } catch (error) {
    console.error("Error updating currency settings:", error);
    errorAlert("Failed to update currency settings.");
  }
};
