// src/utils/storage.js

const isObject = (value) => {
    return value && typeof value === 'object' && !Array.isArray(value);
  };
  
  const storage = {
    setItem: (key, value) => {
      try {
        if (isObject(value)) {
          localStorage.setItem(key, JSON.stringify(value));
        } else {
          // Store strings as-is without JSON.stringify to avoid extra quotes
          localStorage.setItem(key, value);
        }
      } catch (error) {
        console.error(`Error setting item ${key} in localStorage:`, error);
        throw error;
      }
    },
  
    getItem: (key) => {
      try {
        const value = localStorage.getItem(key);
        // Attempt to parse JSON; if it fails, return the string as-is
        try {
          return JSON.parse(value);
        } catch {
          return value;
        }
      } catch (error) {
        console.error(`Error getting item ${key} from localStorage:`, error);
        throw error;
      }
    },
  
    removeItem: (key) => {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error(`Error removing item ${key} from localStorage:`, error);
        throw error;
      }
    },
  
    clear: () => {
      try {
        localStorage.clear();
      } catch (error) {
        console.error('Error clearing localStorage:', error);
        throw error;
      }
    },
  };
  
  export default storage;
  