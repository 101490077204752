import { combineReducers } from "redux";
import adminClientReducer from "./admin.client.reducer";
import adminTimeReducer from "./admin.time.reducer";
import authReducer from "./auth.reducers";
import matterReducers from "../store/slices/matterSlice";
import employeeReducer from "../store/slices/employeesSlice";
import timeReducers from "./time.reducers";
import writeoffReducer from "./admin.writeOff.reducer";
import currencyReducer from "./currencyReducer";
import globalSlice from "../store/slices/globalSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  employee: employeeReducer,
  matter: matterReducers,
  time: timeReducers,
  adminTime: adminTimeReducer,
  adminClient: adminClientReducer,
  writeoff: writeoffReducer,
  currency: currencyReducer,
  global: globalSlice,
});

export default rootReducer;
