import React from "react";
import { Container, Typography, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import CustomButton from "./ui/CustomButton";
import useResponsive from "../constants/useResponsive";

const NotFound = () => {
  const { isSm } = useResponsive();

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: isSm ? 2 : 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: isSm ? "100%" : "100%",
          padding: isSm ? 4 : 10,
        }}
      >
        <Typography variant="h1" fontWeight={700} gutterBottom>
          404
        </Typography>
        <Typography variant="h4" component="div" gutterBottom paddingBottom={2}>
          Oops! Page Not Found
        </Typography>
        <CustomButton component={RouterLink} to="/">
          Back to Home
        </CustomButton>
      </Box>
    </Container>
  );
};

export default NotFound;
