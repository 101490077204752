import moment from "moment";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";

export const authToken = localStorage.getItem("authToken");

//Number format
export function formatCurrency(value) {
  return `INR ${parseFloat(value).toLocaleString("en-IN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
}

export const getFinancialYearDateRange = () => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth(); // 0-indexed: 0 = January, 11 = December

  let startYear, endYear;
  if (currentMonth >= 3) {
    // April (3) to December (11)
    startYear = currentYear;
    endYear = currentYear + 1;
  } else {
    // January (0) to March (2)
    startYear = currentYear - 1;
    endYear = currentYear;
  }

  const startDate = new Date(startYear, 3, 1); // April 1st
  const endDate = new Date(endYear, 2, 31, 23, 59, 59); // March 31st 23:59:59

  return {
    startDate: formatDate(startDate), // Ensure this function formats date as 'YYYY-MM-DD'
    endDate: formatDate(endDate),
  };
};

//Main Date Format - yyyy-mm-dd
export default function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

// dd-mm-yyy to mm-dd-yyyy
export function formatDateToYYYYMMDD(dateString) {
  // Assuming dateString is in 'dd-mm-yyyy' format
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`; // Return in 'yyyy-mm-dd' format
}

// 07-06-2023(day-month-year)
export function formatDatePrevious(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [day, month, year].join("-");
}

// 8th June
export function formatMonthDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });

  // Get the day with suffix
  const dayWithSuffix = getDayWithSuffix(day);

  // Format the date
  const formattedDate = `${dayWithSuffix} ${month}`;

  return formattedDate;
}

// June 7th, 2023
export function formatLetterDate(dateString) {
  const date = moment(dateString, "YYYY-MM-DD");
  const month = date.format("MMMM");
  const day = date.date(); // Get the day as a number
  const year = date.format("YYYY");

  // Get the day with suffix
  const dayWithSuffix = getDayWithSuffix(day);

  // Format the date
  const formattedDate = `${month} ${dayWithSuffix}, ${year}`;

  return formattedDate;
}

// Reusable function to get the day with suffix
function getDayWithSuffix(day) {
  if (day === 1 || day === 21 || day === 31) {
    return `${day}st`;
  } else if (day === 2 || day === 22) {
    return `${day}nd`;
  } else if (day === 3 || day === 23) {
    return `${day}rd`;
  } else {
    return `${day}th`;
  }
}

//03/06 (month / day) - timespent report
export function formatNumberDate(dateString) {
  const date = new Date(dateString);
  const month = date.getMonth() + 1; // Get month (0-11) and add 1 to match desired format
  const day = date.getDate();

  // Pad month and day with leading zeros if necessary
  const formattedMonth = month.toString().padStart(2, "0");
  const formattedDay = day.toString().padStart(2, "0");

  // Format the date
  const formattedDate = `${formattedDay}/${formattedMonth}`;

  return formattedDate;
}

//Notification Functions
export function errorAlert(error) {
  toast.error(error || "Internal Server Error", {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 1500,
  });
}

export function successAlert(success) {
  toast.success(success, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 800,
  });
}

//Confirmation Alert
export const ConfirmationAlert = ({ icon, question, answer, onDeleteOff }) => {
  return (
    <div>
      <p>{question}</p>
      <Button
        variant="contained"
        color="error"
        startIcon={icon}
        onClick={onDeleteOff}
      >
        {answer}
      </Button>
    </div>
  );
};

export const customStyles = {
  control: (provided) => ({
    ...provided,
    minHeight: "30px", // Minimum height of the control
    height: "30px", // Force height of the control
    padding: "0px", // Remove padding
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0px 8px", // Adjust the padding of the value container
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px", // Remove margin
    padding: "0px", // Remove padding
  }),
  indicatorSeparator: () => ({
    display: "none", // Remove the separator
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "4px", // Adjust padding for dropdown indicator
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0px", // Remove margin-top from the dropdown menu
    zIndex: 9999,
  }),
  // menuPortal: (base) => ({
  //   ...base,
  //   marginTop: "0px", // Remove margin-top from the dropdown menu
  //   zIndex: 9999,
  // }),
  option: (provided) => ({
    ...provided,
    padding: "8px 12px", // Adjust padding for dropdown items
    zIndex: 2,
  }),
};
