import { Typography } from "@mui/material";
import React from "react";

const NoDataFound = ({ message = "Oops! No Data Found" }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div>
        <img
          src="https://comput-images.blr1.cdn.digitaloceanspaces.com/public/NoDataFound.gif"
          // src={`${process.env.PUBLIC_URL}/NoDataFound.gif`}
          style={{
            height: 220,
            width: "100%",
          }}
          alt="No Data Found"
        />
      </div>
      <div>
        <Typography variant="h5" align="center">
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default NoDataFound;
