import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Container,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";

const AdminConsent = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("Processing admin consent...");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const extractQueryParams = () => {
      const href = window.location.href;
      const queryString = href.includes("?")
        ? href.split("?")[1].split("#")[0] // Extract query string before the fragment
        : "";
      return new URLSearchParams(queryString);
    };

    const queryParams = extractQueryParams();
    const adminConsent = queryParams.get("admin_consent");
    const tenantId = queryParams.get("tenant");
    const companyCode = queryParams.get("state");

    console.log("Admin Consent:", adminConsent);
    console.log("Tenant ID:", tenantId);
    console.log("Company code", companyCode);

    if (adminConsent === "True" && tenantId) {
      axios
        .post("https://my-app-7fjx6.ondigitalocean.app/api/v1/storeTenantId", {
          tenantId,
          companyCode,
        })
        .then(() => {
          setMessage("Admin consent granted successfully!");
        })
        .catch((error) => {
          console.error("Error storing tenant ID:", error);
          setMessage("An error occurred while processing admin consent.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setMessage("Admin consent was not granted or tenant ID is missing.");
      setLoading(false);
    }
  }, []);

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
      <Box
        sx={{
          p: 3,
          boxShadow: 3,
          borderRadius: 2,
          bgcolor: "background.paper",
        }}
      >
        <Typography variant="h5" component="h1" gutterBottom>
          Admin Consent
        </Typography>
        {loading ? (
          <CircularProgress />
        ) : (
          <Typography variant="body1" sx={{ mb: 3 }}>
            {message}
          </Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/")}
          sx={{ mt: 2 }}
        >
          Go to Login
        </Button>
      </Box>
    </Container>
  );
};

export default AdminConsent;
