import { timeConstants } from "../actions/constants";

const initState = {
  userWorkHour: [],
  workHourLoading: false,
  opeLoading: false,
  loading: false,
  error: null,
  message: "",
  totalCount: [],
  filterLoading: false,
  filteredWorkHour: [],
  filteredError: null,
  pendingEntriesCount: 0,
};

const timeReducer = (state = initState, action) => {
  switch (action.type) {
    case timeConstants.ADD_TIME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case timeConstants.ADD_TIME_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case timeConstants.ADD_TIME_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case timeConstants.GET_WORK_HOUR_REQUEST:
      return {
        ...state,
        workHourLoading: true,
      };
    case timeConstants.GET_WORK_HOUR_SUCCESS:
      return {
        ...state,
        userWorkHour: action.payload.workHours,
        totalCount: action.payload.totalCount,
        workHourLoading: false,
      };
    case timeConstants.GET_WORK_HOUR_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        workHourLoading: false,
      };

    // OPE reducers
    case timeConstants.ADD_NEW_OPE_REQUEST:
      return {
        ...state,
        opeLoading: true,
      };
    case timeConstants.ADD_NEW_OPE_SUCCESS:
      return {
        ...state,
        opeLoading: false,
      };
    case timeConstants.ADD_NEW_OPE_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        opeLoading: false,
      };

    case timeConstants.FILTER_DATA_FOR_USER_REQUEST:
      return {
        ...state,
        filterLoading: true,
      };
    case timeConstants.FILTER_DATA_FOR_USER_SUCCESS:
      return {
        ...state,
        filteredWorkHour: action.payload.workHours,
        filterLoading: false,
      };
    case timeConstants.FILTER_DATA_FOR_USER_FAILURE:
      return {
        ...state,
        filteredError: action.payload.error,
        filterLoading: false,
      };

    //pending entries reducer
    case timeConstants.GET_PENDING_ENTRIES_COUNT_SUCCESS:
      return {
        ...state,
        pendingEntriesCount: action.payload,
      };
    default:
      return state;
  }
};

export default timeReducer;
