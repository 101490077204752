import React from "react";

const DateRangePickerTst = ({ startDate, endDate, onDateRangeChange }) => {
  const parseDate = (dateString) => {
    if (!dateString) return null;
    const [year, month, day] = dateString.split("-");
    return new Date(year, month - 1, day, 0, 0, 0); // Set to midnight to avoid timezone issues
  };

  const start = parseDate(startDate);
  const end = parseDate(endDate);
  const [hoveredDate, setHoveredDate] = React.useState(null); // Track hovered date
  const [currentMonth, setCurrentMonth] = React.useState(new Date()); // Current month

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

  // Get today's date
  const today = new Date();
  const isToday = (date) =>
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear();

  const formatDate = (date) =>
    `${months[date.getMonth()].slice(
      0,
      3
    )} ${date.getDate()}, ${date.getFullYear()}`;

  const isInRange = (date) => {
    const rangeEnd = end || hoveredDate; // Use hovered date if no end date is selected
    return start && rangeEnd && date >= start && date <= rangeEnd;
  };

  const handleDateClick = (date) => {
    const adjustedDate = new Date(date.setHours(0, 0, 0, 0));

    if (!start || (start && end)) {
      onDateRangeChange(adjustedDate.toLocaleDateString("en-CA"), ""); // Set start date and reset end date
      setHoveredDate(null); // Reset hovered date
    } else if (adjustedDate < start) {
      onDateRangeChange(
        adjustedDate.toLocaleDateString("en-CA"),
        start.toLocaleDateString("en-CA")
      ); // Swap if clicked date is before start date
      setHoveredDate(null); // Reset hovered date
    } else {
      onDateRangeChange(
        start.toLocaleDateString("en-CA"),
        adjustedDate.toLocaleDateString("en-CA")
      ); // Set end date
      setHoveredDate(null); // Reset hovered date
    }
  };

  const handleDateMouseEnter = (date) => {
    if (start && !end) {
      setHoveredDate(new Date(date.setHours(0, 0, 0, 0))); // Set the hovered date
    }
  };

  const renderCalendar = (month) => {
    const firstDay = new Date(
      month.getFullYear(),
      month.getMonth(),
      1
    ).getDay();
    const daysInMonth = new Date(
      month.getFullYear(),
      month.getMonth() + 1,
      0
    ).getDate();
    const weeks = [];
    let daysArray = [];
    let dayCount = 1;

    // Total slots to always render 6 weeks (6 rows)
    const totalSlots = 42; // 7 days * 6 weeks

    for (let i = 0; i < totalSlots; i++) {
      const date = new Date(
        month.getFullYear(),
        month.getMonth(),
        i - firstDay + 1,
        0,
        0,
        0
      );

      if (i < firstDay || dayCount > daysInMonth) {
        // Empty cells for days outside the current month
        daysArray.push(<td key={`empty-${i}`} className="p-1"></td>);
      } else {
        const isSelected =
          date.getTime() === start?.getTime() ||
          date.getTime() === end?.getTime();
        const isRangeDate = isInRange(date);
        const isTodayDate = isToday(date);

        daysArray.push(
          <td key={dayCount} className="p-1">
            <button
              onClick={() => handleDateClick(date)}
              onMouseEnter={() => handleDateMouseEnter(date)}
              className={`w-7 h-7 rounded-full flex items-center justify-center text-xs
                ${isSelected ? "bg-black text-white" : ""}
                ${
                  isRangeDate && !isSelected
                    ? "bg-gray-300 text-black rounded-full"
                    : ""
                }
                ${!isRangeDate && !isSelected ? "hover:bg-gray-200" : ""}
                ${
                  isRangeDate && date > start && !end
                    ? "rounded-full bg-gray-400 text-black"
                    : ""
                }
                ${isTodayDate ? "border border-blue-500" : ""}
              `}
            >
              {dayCount}
            </button>
          </td>
        );
        dayCount++;
      }

      if ((i + 1) % 7 === 0) {
        weeks.push(<tr key={`week-${weeks.length}`}>{daysArray}</tr>);
        daysArray = [];
      }
    }

    return weeks;
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  return (
    <div
      className="bg-white text-black p-4 rounded-lg shadow-lg"
      style={{ width: "100%", maxWidth: "500px", minHeight: "400px" }} // Fixed height to ensure consistent layout
    >
      {/* Date range display */}
      <div className="mb-2">
        <button className="bg-gray-200 text-black px-2 py-1 rounded-md w-full text-left text-sm">
          {start ? formatDate(start) : "Start Date"} -{" "}
          {end ? formatDate(end) : "End Date"}
        </button>
      </div>

      {/* Calendar container */}
      <div className="flex flex-col md:flex-row items-stretch">
        {[0, 1].map((offset) => (
          <div key={offset} className="flex-1">
            {/* Month Navigation */}
            <div className="flex justify-between items-center h-10 mb-2">
              {offset === 0 && (
                <button
                  onClick={handlePrevMonth}
                  className="text-gray-500 hover:text-black text-sm"
                >
                  &lt;
                </button>
              )}
              <h2 className="text-base font-semibold text-center flex-1">
                {
                  months[
                    new Date(
                      currentMonth.getFullYear(),
                      currentMonth.getMonth() + offset
                    ).getMonth()
                  ]
                }{" "}
                {new Date(
                  currentMonth.getFullYear(),
                  currentMonth.getMonth() + offset
                ).getFullYear()}
              </h2>
              {offset === 1 && (
                <button
                  onClick={handleNextMonth}
                  className="text-gray-500 hover:text-black text-sm"
                >
                  &gt;
                </button>
              )}
            </div>
            {/* Calendar */}
            <table className="w-full table-fixed">
              <thead>
                <tr>
                  {days.map((day) => (
                    <th
                      key={day}
                      className="p-1 text-center text-gray-500 text-sm"
                    >
                      {day}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="h-40">
                {renderCalendar(
                  new Date(
                    currentMonth.getFullYear(),
                    currentMonth.getMonth() + offset
                  )
                )}
              </tbody>
            </table>
          </div>
        ))}
      </div>

      {/* Predefined Range Buttons */}
      <div className="mt-2 flex flex-wrap justify-center">
        {[
          { label: "Last Week", range: [getLastWeekStart(), getLastWeekEnd()] },
          {
            label: "This Month",
            range: [
              getMonthStart(new Date()),
              new Date().toLocaleDateString("en-CA"),
            ],
          },
          {
            label: "Last Month",
            range: [getLastMonthStart(), getLastMonthEnd()],
          },
        ].map(({ label, range }) => (
          <button
            key={label}
            className="bg-gray-200 text-black px-2 py-1 m-1 rounded text-xs"
            onClick={() => onDateRangeChange(...range)}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

// Helper functions for predefined ranges
const getLastWeekStart = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const start = new Date(today);
  start.setDate(today.getDate() - dayOfWeek - 7);
  return start.toLocaleDateString("en-CA");
};

const getLastWeekEnd = () => {
  const today = new Date();
  const dayOfWeek = today.getDay();
  const end = new Date(today);
  end.setDate(today.getDate() - dayOfWeek - 1);
  return end.toLocaleDateString("en-CA");
};

const getMonthStart = (date) =>
  new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("en-CA");
const getLastMonthStart = () =>
  new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
    1
  ).toLocaleDateString("en-CA");
const getLastMonthEnd = () =>
  new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    0
  ).toLocaleDateString("en-CA");

export default DateRangePickerTst;
