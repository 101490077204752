import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  Typography,
} from "@mui/material";
import useResponsive from "../../constants/useResponsive";

const InfoPopup = ({ open, handleClose }) => {
  const { isSm } = useResponsive();

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle align="center" fontWeight="700">
        Color Dot Schema
      </DialogTitle>
      <DialogContent>
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            gap: isSm ? 2 : 5,
            flexDirection: isSm ? "column" : "row",
            alignItems: isSm ? "left" : "center",
            justifyContent: "center",
            borderRadius: 1,
            padding: "1rem 1rem",
            marginBottom: 2,
          }}
        >
          <div className="flex flex-col gap-2">
            <Typography variant="body1">🔴 Less than 3.5 hours</Typography>
            <Typography variant="body1">
              🟠 Greater than or equal to 3.5 hours
            </Typography>
          </div>
          
          <div className="flex flex-col gap-2">
            <Typography
              variant="body1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  backgroundColor: "green",
                  width: isSm ? "10px" : "15px",
                  height: isSm ? "10px" : "15px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              ></span>
              {">= 9 hrs/Leave"}
            </Typography>
            <Typography
              variant="body1"
              style={{ display: "flex", alignItems: "center" }}
            >
              <span
                style={{
                  backgroundColor: "skyblue",
                  width: isSm ? "10px" : "15px",
                  height: isSm ? "10px" : "15px",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              ></span>
              Holiday
            </Typography>
          </div>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default InfoPopup;
