import React, { useState, useEffect, useCallback } from "react";
import "jspdf-autotable";
import formatDate, {
  formatMonthDate,
  errorAlert,
  successAlert,
} from "../../utils/services";
import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Box,
  Tooltip,
  IconButton,
  TableBody,
  TextField,
} from "@mui/material";
import axiosInstance from "../../helpers/axios";
import NoDataFound from "../../components/NoDataFound";
import { ToastContainer } from "react-toastify";
import TableSkeletonLoader from "../../components/ui/TableSkeletonLoader";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import InfoIcon from "@mui/icons-material/Info";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

const TimespentTracker = () => {
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 7));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [uniqueMatterNames, setUniqueMatterNames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enteredNames, setEnteredNames] = useState({});
  const [enteredRemarks, setEnteredRemarks] = useState({});
  const [enteredLastSentDate, setEnteredLastSentDate] = useState({});
  const [editingMatter, setEditingMatter] = useState({});

  // Handle changes to fields
  const handleFieldChange = (matterId, field, value) => {
    if (field === "enteredBy") {
      setEnteredNames((prev) => ({
        ...prev,
        [matterId]: value,
      }));
    } else if (field === "remarks") {
      setEnteredRemarks((prev) => ({
        ...prev,
        [matterId]: value,
      }));
    } else if (field === "lastSentTSDate") {
      setEnteredLastSentDate((prev) => ({
        ...prev,
        [matterId]: value,
      }));
    }
  };

  // Handle edit mode toggling
  const handleEditMatter = (matterId) => {
    setEditingMatter((prev) => ({
      ...prev,
      [matterId]: true,
    }));

    // Initialize input fields with current values
    const matter = uniqueMatterNames.find((m) => m.id === matterId);
    setEnteredNames((prev) => ({
      ...prev,
      [matterId]: matter.enteredBy || "",
    }));
    setEnteredRemarks((prev) => ({
      ...prev,
      [matterId]: matter.remarks || "",
    }));
    setEnteredLastSentDate((prev) => ({
      ...prev,
      [matterId]: matter.lastSentTSDate
        ? matter.lastSentTSDate.split("T")[0]
        : "",
    }));
  };

  // Handle save action
  const handleSaveMatter = async (matterId) => {
    try {
      const enteredBy = enteredNames[matterId]?.trim();
      const remarks = enteredRemarks[matterId]?.trim() || "";
      const lastSentTSDate = enteredLastSentDate[matterId];

      if (!enteredBy) {
        return errorAlert("Please enter your name before saving.");
      }

      if (!lastSentTSDate) {
        return errorAlert("Please select a Last Sent TS Date before saving.");
      }

      // Update the backend
      await axiosInstance.put("/updateLastSentTSDate", {
        id: matterId,
        lastSentTSDate,
        enteredBy,
        remarks,
      });

      successAlert("Matter updated successfully!");

      // Update the local state to reflect changes
      setUniqueMatterNames((prevMatters) =>
        prevMatters.map((matter) =>
          matter.id === matterId
            ? {
                ...matter,
                enteredBy,
                remarks,
                lastSentTSDate: new Date(lastSentTSDate).toISOString(),
              }
            : matter
        )
      );

      // Exit edit mode
      setEditingMatter((prev) => ({
        ...prev,
        [matterId]: false,
      }));
    } catch (error) {
      console.error("Error updating matter:", error);
      errorAlert("Failed to update matter.");
    }
  };

  // Handle cancel action
  const handleCancelEdit = (matterId) => {
    // Reset local state to original values
    const matter = uniqueMatterNames.find((m) => m.id === matterId);
    setEnteredNames((prev) => ({
      ...prev,
      [matterId]: matter.enteredBy || "",
    }));
    setEnteredRemarks((prev) => ({
      ...prev,
      [matterId]: matter.remarks || "",
    }));
    setEnteredLastSentDate((prev) => ({
      ...prev,
      [matterId]: matter.lastSentTSDate
        ? matter.lastSentTSDate.split("T")[0]
        : "",
    }));

    // Exit edit mode
    setEditingMatter((prev) => ({
      ...prev,
      [matterId]: false,
    }));
  };

  // GET - TS data
  const filterBetweenDates = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        "/workHour/getTimespentTrackerData",
        {
          params: {
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
          },
        }
      );
      const { data } = response.data; // Extract the data array
      setUniqueMatterNames(data);
    } catch (error) {
      errorAlert("Error fetching timespent tracker data!");
    } finally {
      setLoading(false);
    }
  }, [startSelectedDate, endSelectedDate]);

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  useEffect(() => {
    if (startSelectedDate && endSelectedDate) {
      filterBetweenDates();
    }
  }, [startSelectedDate, endSelectedDate, filterBetweenDates]);

  return (
    <>
      <ToastContainer />

      <Paper elevation={0} className="rounded-full">
        <Grid
          container
          spacing={1}
          display="flex"
          alignItems="center"
          marginBottom="1rem"
        >
          {/* Main Heading  */}
          <Grid item xs={12} sm={12} md={6} display="flex" gap={0.5}>
            <Typography variant="h5">Timespent Tracker</Typography>
            <Tooltip
              title="*This will only display matters where time has been entered for the selected period."
              placement="top"
              arrow
            >
              <IconButton>
                <InfoIcon style={{ fontSize: "20px" }} />
              </IconButton>
            </Tooltip>
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <CustomTooltip text={"Select Dates"}>
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
              />
            </CustomTooltip>

            <Grid item display="flex" flexDirection="row" gap={1}>
              <Typography>{formatMonthDate(startSelectedDate)} </Typography>
              <Typography>-</Typography>
              <Typography>{formatMonthDate(endSelectedDate)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "75vh" }}>
          <Box className="flex justify-between mx-10">
            <Typography variant="body2" align="center" marginY={2} gutterBottom>
              MATTERWISE TIMESPENT TRACKER ({formatMonthDate(startSelectedDate)}{" "}
              - {formatMonthDate(endSelectedDate)})
            </Typography>

            <Typography variant="body2" align="center" marginY={2} gutterBottom>
              Total Matters : {uniqueMatterNames.length}
            </Typography>
          </Box>

          <Table aria-label="reusable table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Sr No.</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Matter</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Last Entry Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Last Sent TS Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Entered by</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Remarks</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableSkeletonLoader rows={6} colSpan={11} />
              ) : uniqueMatterNames.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={13}>
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              ) : (
                uniqueMatterNames.map((matter, index) => (
                  <TableRow key={index}>
                    {/* Sr No. */}
                    <TableCell>
                      <Typography variant="body1">{index + 1}</Typography>
                    </TableCell>
                    {/* Matter Name */}
                    <TableCell
                      style={{
                        color: matter.lastSentTSDate ? "inherit" : "red",
                        fontWeight: matter.lastSentTSDate ? "normal" : "bold",
                      }}
                    >
                      <Typography variant="body1">{matter.name}</Typography>
                    </TableCell>
                    {/* Last Entry Date */}
                    <TableCell>
                      <Typography variant="body1">
                        {matter.lastEntryDate
                          ? formatMonthDate(matter.lastEntryDate)
                          : "-"}
                      </Typography>
                    </TableCell>
                    {/* Last Sent TS Date */}
                    <TableCell>
                      {editingMatter[matter.id] ? (
                        <TextField
                          variant="outlined"
                          size="small"
                          type="date"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={enteredLastSentDate[matter.id] || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              matter.id,
                              "lastSentTSDate",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <Typography variant="body1">
                          {matter.lastSentTSDate
                            ? formatMonthDate(matter.lastSentTSDate)
                            : "-"}
                        </Typography>
                      )}
                    </TableCell>
                    {/* Entered by */}
                    <TableCell>
                      {editingMatter[matter.id] ? (
                        <TextField
                          variant="outlined"
                          size="small"
                          value={enteredNames[matter.id] || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              matter.id,
                              "enteredBy",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <Typography variant="body1">
                          {matter.enteredBy || "-"}
                        </Typography>
                      )}
                    </TableCell>
                    {/* Remarks */}
                    <TableCell>
                      {editingMatter[matter.id] ? (
                        <TextField
                          variant="outlined"
                          size="small"
                          value={enteredRemarks[matter.id] || ""}
                          onChange={(e) =>
                            handleFieldChange(
                              matter.id,
                              "remarks",
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <Typography variant="body1">
                          {matter.remarks || "-"}
                        </Typography>
                      )}
                    </TableCell>
                    {/* Actions */}
                    <TableCell>
                      {editingMatter[matter.id] ? (
                        <>
                          <IconButton
                            size="small"
                            onClick={() => handleSaveMatter(matter.id)}
                            disabled={
                              !enteredNames[matter.id]?.trim() ||
                              !enteredLastSentDate[matter.id]
                            }
                          >
                            <SaveIcon fontSize="small" />
                          </IconButton>

                          <IconButton
                            size="small"
                            onClick={() => handleCancelEdit(matter.id)}
                          >
                            <CancelIcon fontSize="small" />
                          </IconButton>
                        </>
                      ) : (
                        <IconButton
                          size="small"
                          onClick={() => handleEditMatter(matter.id)}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default TimespentTracker;
