import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CssBaseline,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CustomButton from "../../components/ui/CustomButton";
import { AdminSignIn } from "../../actions/auth.actions";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import fetchCompanyConfig from "../../utils/fetchCompanyConfig";

const validationSchemaStep1 = Yup.object().shape({
  companyCode: Yup.string()
    .required("Company Code is required")
    .matches(/^\w+$/, "Company Code must be alphanumeric"),
});

const validationSchemaStep2 = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password should be 6 characters long"),
});

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1); // Track current step
  const [loading, setLoading] = useState(false); // Loading state for fetchCompanyConfig

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Step 1: Company Code Form
  const {
    register: registerStep1,
    handleSubmit: handleSubmitStep1,
    formState: { errors: errorsStep1 },
  } = useForm({
    resolver: yupResolver(validationSchemaStep1),
  });

  // Step 2: Login Form
  const {
    register: registerStep2,
    handleSubmit: handleSubmitStep2,
    formState: { errors: errorsStep2 },
  } = useForm({
    resolver: yupResolver(validationSchemaStep2),
  });

  // Handle navigation to forgot password
  const onForgotPassword = () => {
    navigate("/forgotPassword");
  };

  // Handle Step 1 Submission
  const onSubmitStep1 = async (data) => {
    const { companyCode } = data;
    setLoading(true);
    const success = await fetchCompanyConfig(companyCode);
    setLoading(false);
    if (success) {
      setStep(2);
    }
  };

  // Handle Step 2 Submission
  const onSubmitStep2 = (data) => {
    const { email, password } = data;
    const user = {
      email,
      password,
    };
    dispatch(AdminSignIn(user));
  };

  // Handle Authentication Side Effects
  useEffect(() => {
    if (auth.error) {
      toast.error(`Authentication Error: ${auth.error}`, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 5000,
      });
    }
  }, [auth.error]);

  useEffect(() => {
    if (auth?.authenticate) {
      if (auth.user?.role === "admin") {
        navigate("/admin/reports/timespent");
      } else if (auth.user?.role === "employee") {
        navigate("/resource/addtime");
      } else {
        navigate("/");
      }
    }
  }, [auth?.authenticate, auth.user, navigate]);

  // Check localStorage for company data and skip to step 2 if found
  useEffect(() => {
    const companyCode = localStorage.getItem("COMPANY_CODE");
    const baseURL = localStorage.getItem("API_BASE_URL");
    const companyName = localStorage.getItem("COMPANY_NAME");

    if (companyCode && baseURL && companyName) {
      setStep(2);
    }
  }, []);

  return (
    <>
      <ToastContainer />
      <CssBaseline />
      <Grid container>
        {/* Left Image */}
        <Grid
          item
          md={6}
          justifyContent="center"
          alignItems="center"
          height="100vh"
          sx={{
            display: { xs: "none", md: "flex" },
            backgroundImage: `url(https://comput-images.blr1.cdn.digitaloceanspaces.com/login-bg-image.jpeg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Typography
            variant="h3"
            align="center"
            color="white"
            fontWeight="600"
          >
            Welcome to Compüt Labs
          </Typography>
        </Grid>

        {/* Right Form */}
        <Grid
          item
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          padding={5}
          width="100%"
          gap="15px"
        >
          {/* Logo */}
          <Box
            justifyContent="center"
            alignItems="center"
            sx={{
              display: { md: "none", xs: "flex" },
            }}
          >
            <img
              src={`https://comput-images.blr1.cdn.digitaloceanspaces.com/Comput_labs_black.png`}
              alt="logo"
              style={{ maxWidth: "30%" }}
              loading="lazy"
            />
          </Box>

          <Typography variant="h5">Log in to Comput Labs</Typography>

          {/* Form */}
          <Box
            component="form"
            onSubmit={
              step === 1
                ? handleSubmitStep1(onSubmitStep1)
                : handleSubmitStep2(onSubmitStep2)
            }
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            {step === 1 && (
              <TextField
                fullWidth
                variant="outlined"
                label="Enter Company Code"
                margin="normal"
                {...registerStep1("companyCode")}
                error={!!errorsStep1.companyCode}
                helperText={errorsStep1.companyCode?.message}
              />
            )}

            {step === 2 && (
              <>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Email address"
                  margin="normal"
                  {...registerStep2("email")}
                  error={!!errorsStep2.email}
                  helperText={errorsStep2.email?.message}
                />

                <FormControl
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  error={!!errorsStep2.password}
                >
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    sx={{ color: errorsStep2.password ? "red" : "" }}
                  >
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    {...registerStep2("password")}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "red",
                        },
                    }}
                  />
                  {errorsStep2.password && (
                    <Typography variant="caption" color="error">
                      {errorsStep2.password.message}
                    </Typography>
                  )}
                </FormControl>
              </>
            )}

            <CustomButton type="submit" disabled={loading || auth.loading}>
              {loading || auth.loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : step === 1 ? (
                "Next"
              ) : (
                "Log In"
              )}
            </CustomButton>
          </Box>

          {/* Extra Button */}
          {step === 2 && (
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              marginTop="10px"
            >
              <CustomButton
                variant="text"
                color="gray"
                onClick={onForgotPassword}
              >
                Forgot password?
              </CustomButton>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
