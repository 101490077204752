import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  TextField,
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Add, Check, Close } from "@mui/icons-material";
import CustomButton from "../../components/ui/CustomButton";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { ToastContainer } from "react-toastify";
import { fetchCurrencySettings, updateCurrencySettings } from "../../actions/currencyActions";

const AddCurrencySettings = () => {
  const dispatch = useDispatch();
  const currencyData = useSelector((state) => state.currency);

  const [localCurrencyData, setLocalCurrencyData] = useState(currencyData);
  const [nativeCurrencyEdit, setNativeCurrencyEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  useEffect(() => {
    dispatch(fetchCurrencySettings());
  }, [dispatch]);

  useEffect(() => {
    setLocalCurrencyData(currencyData);
  }, [currencyData]);

  const handleNativeCurrencyChange = (e) => {
    const { name, value } = e.target;
    setLocalCurrencyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveNativeCurrency = () => {
    dispatch(updateCurrencySettings(localCurrencyData));
    setNativeCurrencyEdit(false);
  };

  const handleAddConversionRate = () => {
    setLocalCurrencyData((prevData) => ({
      ...prevData,
      conversionRates: [...prevData.conversionRates, { currencyCode: "", rate: "" }],
    }));
    setEditIndex(localCurrencyData.conversionRates.length);
    setOpenEditDialog(true);
  };

  const handleConversionRateChange = (index, e) => {
    const { name, value } = e.target;
    const updatedRates = [...localCurrencyData.conversionRates];
    updatedRates[index] = { ...updatedRates[index], [name]: value };
    setLocalCurrencyData((prevData) => ({
      ...prevData,
      conversionRates: updatedRates,
    }));
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
    setOpenEditDialog(true);
  };

  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setOpenDeleteDialog(true);
  };

  const handleEditSubmit = () => {
    dispatch(updateCurrencySettings(localCurrencyData));
    setOpenEditDialog(false);
    setEditIndex(null);
  };

  const handleDeleteConfirm = () => {
    const updatedRates = localCurrencyData.conversionRates.filter((_, i) => i !== deleteIndex);
    setLocalCurrencyData((prevData) => ({
      ...prevData,
      conversionRates: updatedRates,
    }));
    dispatch(updateCurrencySettings({ ...localCurrencyData, conversionRates: updatedRates }));
    setOpenDeleteDialog(false);
    setDeleteIndex(null);
  };

  return (
    <Card elevation={0}>
      <ToastContainer />
      <CardContent>
        <Typography variant="h5" marginBottom={2}>
          Currency Settings
        </Typography>

        {/* Native Currency Section */}
        <Box mt={2} mb={4} p={2} style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}>
          <Typography variant="h6" style={{ fontWeight: 600 }}>
            Native Currency
          </Typography>
          <Grid container spacing={2} mt={1}>
            {nativeCurrencyEdit ? (
              <>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Currency Name"
                    name="nativeCurrencyName"
                    value={localCurrencyData.nativeCurrencyName}
                    onChange={handleNativeCurrencyChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Currency Code"
                    name="nativeCurrencyCode"
                    value={localCurrencyData.nativeCurrencyCode}
                    onChange={handleNativeCurrencyChange}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveNativeCurrency}
                    startIcon={<Check />}
                  >
                    Save
                  </Button>
                  <Button
                    variant="outlined"
                    style={{ marginLeft: "10px" }}
                    onClick={() => setNativeCurrencyEdit(false)}
                    startIcon={<Close />}
                  >
                    Cancel
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Currency Name:
                  </Typography>
                  <Typography variant="body1">{currencyData.nativeCurrencyName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Currency Code:
                  </Typography>
                  <Typography variant="body1">{currencyData.nativeCurrencyCode}</Typography>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Button
                    variant="outlined"
                    onClick={() => setNativeCurrencyEdit(true)}
                    startIcon={<CiEdit />}
                  >
                    Edit
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        {/* Conversion Rates Section */}
        <Typography variant="h6" gutterBottom>
          Conversion Rates
        </Typography>
        <TableContainer component={Paper} elevation={0} style={{ marginBottom: "20px" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Currency Code</strong></TableCell>
                <TableCell><strong>Conversion Rate</strong></TableCell>
                <TableCell align="right"><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {localCurrencyData.conversionRates.map((rate, index) => (
                <TableRow key={index}>
                  <TableCell>{rate.currencyCode}</TableCell>
                  <TableCell>{rate.rate}</TableCell>
                  <TableCell align="right">
                    <IconButton color="primary" onClick={() => handleEditClick(index)}>
                      <CiEdit />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDeleteClick(index)}>
                      <RiDeleteBin6Line />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddConversionRate}
          style={{ marginTop: "20px" }}
        >
          Add Conversion Rate
        </Button>

        {/* Edit Dialog */}
        <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
          <DialogTitle>Edit Conversion Rate</DialogTitle>
          <DialogContent>
            {editIndex !== null && (
              <>
                <TextField
                  fullWidth
                  label="Currency Code"
                  name="currencyCode"
                  value={localCurrencyData.conversionRates[editIndex].currencyCode}
                  onChange={(e) => handleConversionRateChange(editIndex, e)}
                  variant="outlined"
                  margin="dense"
                />
                <TextField
                  fullWidth
                  label="Conversion Rate"
                  name="rate"
                  type="number"
                  value={localCurrencyData.conversionRates[editIndex].rate}
                  onChange={(e) => handleConversionRateChange(editIndex, e)}
                  variant="outlined"
                  margin="dense"
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <CustomButton style={{ width: "100%" }} onClick={handleEditSubmit} startIcon={<Check />}>
              Save
            </CustomButton>
            <CustomButton variant="outlined" onClick={() => setOpenEditDialog(false)} startIcon={<Close />}>
              Cancel
            </CustomButton>
          </DialogActions>
        </Dialog>

        {/* Delete Dialog */}
        <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>Are you sure you want to delete this conversion rate?</Typography>
          </DialogContent>
          <DialogActions>
            <CustomButton style={{ width: "100%" }} onClick={handleDeleteConfirm} startIcon={<Check />}>
              Confirm
            </CustomButton>
            <CustomButton variant="outlined" onClick={() => setOpenDeleteDialog(false)} startIcon={<Close />}>
              Cancel
            </CustomButton>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default AddCurrencySettings;


// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   TextField,
//   Typography,
//   IconButton,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Box,
//   Card,
//   CardContent,
//   Button,
//   TableCell,
//   TableBody,
//   TableRow,
//   TableHead,
//   Table,
//   TableContainer,
//   Paper,
// } from "@mui/material";
// import { Add, Check, Close } from "@mui/icons-material";
// import CustomButton from "../../components/ui/CustomButton";
// import { RiDeleteBin6Line } from "react-icons/ri";
// import { CiEdit } from "react-icons/ci";
// import axiosInstance from "../../helpers/axios";
// import { errorAlert, successAlert } from "../../utils/services";
// import { ToastContainer } from "react-toastify";

// const AddCurrencySettings = () => {
//   const [currencyData, setCurrencyData] = useState({
//     nativeCurrencyName: "",
//     nativeCurrencyCode: "",
//     conversionRates: [],
//   });
//   const [editIndex, setEditIndex] = useState(null);
//   const [deleteIndex, setDeleteIndex] = useState(null);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [isNewRateEditing, setIsNewRateEditing] = useState(false);
//   const [nativeCurrencyEdit, setNativeCurrencyEdit] = useState(false); // New state for editing native currency

//   useEffect(() => {
//     fetchCurrencySettings();
//   }, []);

//   const fetchCurrencySettings = async () => {
//     try {
//       const response = await axiosInstance.get(`/currency-settings`);
//       if (response.data.success) {
//         const { nativeCurrencyName, nativeCurrencyCode, conversionRates } =
//           response.data.data;
//         setCurrencyData({
//           nativeCurrencyName,
//           nativeCurrencyCode,
//           conversionRates: conversionRates || [],
//         });
//       }
//     } catch (error) {
//       console.error("Error fetching currency settings:", error);
//     }
//   };

//   const handleNativeCurrencyChange = (e) => {
//     const { name, value } = e.target;
//     setCurrencyData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSaveNativeCurrency = async () => {
//     try {
//       await axiosInstance.post(`/currency-settings`, currencyData);
//       successAlert("Native currency updated successfully!");
//       setNativeCurrencyEdit(false);
//       fetchCurrencySettings();
//     } catch (error) {
//       console.error("Error updating native currency:", error);
//       errorAlert("Error updating native currency. Please try again.");
//     }
//   };

//   const handleConversionRateChange = (index, e) => {
//     const { name, value } = e.target;
//     const updatedRates = [...currencyData.conversionRates];
//     updatedRates[index] = { ...updatedRates[index], [name]: value };
//     setCurrencyData((prevData) => ({
//       ...prevData,
//       conversionRates: updatedRates,
//     }));
//   };

//   const handleEditClick = (index) => {
//     setEditIndex(index);
//     setOpenEditDialog(true);
//   };

//   const handleEditSubmit = async () => {
//     setOpenEditDialog(false);
//     setEditIndex(null);
//     try {
//       await axiosInstance.post(`/currency-settings`, currencyData);
//       successAlert("Currency settings updated successfully!");
//       fetchCurrencySettings();
//     } catch (error) {
//       console.error("Error updating currency settings:", error);
//       errorAlert("Error updating currency settings. Please try again.");
//     }
//   };

//   const handleDeleteClick = (index) => {
//     setDeleteIndex(index);
//     setOpenDeleteDialog(true);
//   };

//   const handleDeleteConfirm = async () => {
//     const updatedRates = currencyData.conversionRates.filter(
//       (_, i) => i !== deleteIndex
//     );
//     setCurrencyData((prevData) => ({
//       ...prevData,
//       conversionRates: updatedRates,
//     }));
//     setOpenDeleteDialog(false);
//     setDeleteIndex(null);
//     try {
//       await axiosInstance.post(`/currency-settings`, {
//         ...currencyData,
//         conversionRates: updatedRates,
//       });
//       successAlert("Conversion rate deleted successfully!");
//       fetchCurrencySettings();
//     } catch (error) {
//       console.error("Error deleting conversion rate:", error);
//       errorAlert("Error deleting conversion rate. Please try again.");
//     }
//   };

//   const handleAddConversionRate = () => {
//     setCurrencyData((prevData) => ({
//       ...prevData,
//       conversionRates: [
//         ...prevData.conversionRates,
//         { currencyCode: "", rate: "" },
//       ],
//     }));
//     setIsNewRateEditing(true); // Enable edit mode for the newly added row
//   };

//   const handleSaveNewRate = async () => {
//     const lastRate =
//       currencyData.conversionRates[currencyData.conversionRates.length - 1];
//     if (!lastRate.currencyCode || !lastRate.rate) {
//       errorAlert("Please enter both currency code and conversion rate.");
//       return;
//     }

//     try {
//       await axiosInstance.post(`/currency-settings`, currencyData);
//       successAlert("New conversion rate added successfully!");
//       setIsNewRateEditing(false);
//       fetchCurrencySettings();
//     } catch (error) {
//       console.error("Error adding new conversion rate:", error);
//       errorAlert("Error adding new conversion rate. Please try again.");
//     }
//   };

//   return (
//     <Card elevation={0}>
//       <ToastContainer />
//       <CardContent>
//         <Typography variant="h5" marginBottom={2}>
//           Currency Settings
//         </Typography>

//         {/* Native Currency Section */}
//         <Box
//           mt={2}
//           mb={4}
//           p={2}
//           style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
//         >
//           <Typography variant="h6" style={{ fontWeight: 600 }}>
//             Native Currency
//           </Typography>
//           <Grid container spacing={2} mt={1}>
//             {nativeCurrencyEdit ? (
//               <>
//                 <Grid item xs={6}>
//                   <TextField
//                     fullWidth
//                     label="Currency Name"
//                     name="nativeCurrencyName"
//                     value={currencyData.nativeCurrencyName}
//                     onChange={handleNativeCurrencyChange}
//                     variant="outlined"
//                   />
//                 </Grid>
//                 <Grid item xs={6}>
//                   <TextField
//                     fullWidth
//                     label="Currency Code"
//                     name="nativeCurrencyCode"
//                     value={currencyData.nativeCurrencyCode}
//                     onChange={handleNativeCurrencyChange}
//                     variant="outlined"
//                   />
//                 </Grid>
//                 <Grid item xs={12} mt={2}>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     onClick={handleSaveNativeCurrency}
//                     startIcon={<Check />}
//                   >
//                     Save
//                   </Button>
//                   <Button
//                     variant="outlined"
//                     style={{ marginLeft: "10px" }}
//                     onClick={() => setNativeCurrencyEdit(false)}
//                     startIcon={<Close />}
//                   >
//                     Cancel
//                   </Button>
//                 </Grid>
//               </>
//             ) : (
//               <>
//                 <Grid item xs={6}>
//                   <Typography variant="body2" color="textSecondary">
//                     Currency Name:
//                   </Typography>
//                   <Typography variant="body1">
//                     {currencyData.nativeCurrencyName}
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={6}>
//                   <Typography variant="body2" color="textSecondary">
//                     Currency Code:
//                   </Typography>
//                   <Typography variant="body1">
//                     {currencyData.nativeCurrencyCode}
//                   </Typography>
//                 </Grid>
//                 <Grid item xs={12} mt={2}>
//                   <Button
//                     variant="outlined"
//                     onClick={() => setNativeCurrencyEdit(true)}
//                     startIcon={<CiEdit />}
//                   >
//                     Edit
//                   </Button>
//                 </Grid>
//               </>
//             )}
//           </Grid>
//         </Box>

//         {/* Conversion Rates Section */}
//         <Typography variant="h6" gutterBottom>
//           Conversion Rates
//         </Typography>
//         <TableContainer
//           component={Paper}
//           elevation={0}
//           style={{ marginBottom: "20px" }}
//         >
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>
//                   <strong>Currency Code</strong>
//                 </TableCell>
//                 <TableCell>
//                   <strong>Conversion Rate</strong>
//                 </TableCell>
//                 <TableCell align="right">
//                   <strong>Actions</strong>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {currencyData.conversionRates.map((rate, index) => (
//                 <TableRow key={index}>
//                   {isNewRateEditing &&
//                   index === currencyData.conversionRates.length - 1 ? (
//                     <>
//                       <TableCell>
//                         <TextField
//                           fullWidth
//                           label="Currency Code"
//                           name="currencyCode"
//                           value={rate.currencyCode}
//                           onChange={(e) => handleConversionRateChange(index, e)}
//                           variant="outlined"
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           fullWidth
//                           label="Conversion Rate"
//                           name="rate"
//                           type="number"
//                           value={rate.rate}
//                           onChange={(e) => handleConversionRateChange(index, e)}
//                           variant="outlined"
//                         />
//                       </TableCell>
//                       <TableCell align="right">
//                         <CustomButton
//                           onClick={handleSaveNewRate}
//                           startIcon={<Check />}
//                         >
//                           Save
//                         </CustomButton>
//                       </TableCell>
//                     </>
//                   ) : (
//                     <>
//                       <TableCell>{rate.currencyCode}</TableCell>
//                       <TableCell>{rate.rate}</TableCell>
//                       <TableCell align="right">
//                         <IconButton
//                           color="primary"
//                           onClick={() => handleEditClick(index)}
//                         >
//                           <CiEdit />
//                         </IconButton>
//                         <IconButton
//                           color="secondary"
//                           onClick={() => handleDeleteClick(index)}
//                         >
//                           <RiDeleteBin6Line />
//                         </IconButton>
//                       </TableCell>
//                     </>
//                   )}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         <Button
//           variant="contained"
//           color="primary"
//           startIcon={<Add />}
//           onClick={handleAddConversionRate}
//           style={{ marginTop: "20px" }}
//         >
//           Add Conversion Rate
//         </Button>
//       </CardContent>
//     </Card>
//   );
// };

// export default AddCurrencySettings;

// import React, { useState, useEffect } from "react";
// import {
//   Grid,
//   TextField,
//   Typography,
//   IconButton,
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogContentText,
//   DialogTitle,
//   Box,
//   Card,
//   CardContent,
//   Button,
//   TableCell,
//   TableBody,
//   TableRow,
//   TableHead,
//   Table,
//   TableContainer,
//   Paper,
// } from "@mui/material";
// import { Add, Check, Close } from "@mui/icons-material";
// import CustomButton from "../../components/ui/CustomButton";
// import { RiDeleteBin6Line } from "react-icons/ri";
// import { CiEdit } from "react-icons/ci";
// import axiosInstance from "../../helpers/axios";
// import { errorAlert, successAlert } from "../../utils/services";
// import { ToastContainer } from "react-toastify";

// const AddCurrencySettings = () => {
//   const [currencyData, setCurrencyData] = useState({
//     nativeCurrencyName: "",
//     nativeCurrencyCode: "",
//     conversionRates: [],
//   });
//   const [editIndex, setEditIndex] = useState(null);
//   const [deleteIndex, setDeleteIndex] = useState(null);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
//   const [isNewRateEditing, setIsNewRateEditing] = useState(false);

//   useEffect(() => {
//     fetchCurrencySettings();
//   }, []);

//   const fetchCurrencySettings = async () => {
//     try {
//       const response = await axiosInstance.get(`/currency-settings`);
//       if (response.data.success) {
//         const { nativeCurrencyName, nativeCurrencyCode, conversionRates } =
//           response.data.data;
//         setCurrencyData({
//           nativeCurrencyName,
//           nativeCurrencyCode,
//           conversionRates: conversionRates || [],
//         });
//       }
//     } catch (error) {
//       console.error("Error fetching currency settings:", error);
//     }
//   };

//   const handleConversionRateChange = (index, e) => {
//     const { name, value } = e.target;
//     const updatedRates = [...currencyData.conversionRates];
//     updatedRates[index] = { ...updatedRates[index], [name]: value };
//     setCurrencyData((prevData) => ({
//       ...prevData,
//       conversionRates: updatedRates,
//     }));
//   };

//   const handleEditClick = (index) => {
//     setEditIndex(index);
//     setOpenEditDialog(true);
//   };

//   const handleEditSubmit = async () => {
//     setOpenEditDialog(false);
//     setEditIndex(null);
//     try {
//       await axiosInstance.post(`/currency-settings`, currencyData);
//       successAlert("Currency settings updated successfully!");
//       fetchCurrencySettings();
//     } catch (error) {
//       console.error("Error updating currency settings:", error);
//       errorAlert("Error updating currency settings. Please try again.");
//     }
//   };

//   const handleDeleteClick = (index) => {
//     setDeleteIndex(index);
//     setOpenDeleteDialog(true);
//   };

//   const handleDeleteConfirm = async () => {
//     const updatedRates = currencyData.conversionRates.filter(
//       (_, i) => i !== deleteIndex
//     );
//     setCurrencyData((prevData) => ({
//       ...prevData,
//       conversionRates: updatedRates,
//     }));
//     setOpenDeleteDialog(false);
//     setDeleteIndex(null);
//     try {
//       await axiosInstance.post(`/currency-settings`, {
//         ...currencyData,
//         conversionRates: updatedRates,
//       });
//       successAlert("Conversion rate deleted successfully!");
//       fetchCurrencySettings();
//     } catch (error) {
//       console.error("Error deleting conversion rate:", error);
//       errorAlert("Error deleting conversion rate. Please try again.");
//     }
//   };

//   const handleAddConversionRate = () => {
//     setCurrencyData((prevData) => ({
//       ...prevData,
//       conversionRates: [
//         ...prevData.conversionRates,
//         { currencyCode: "", rate: "" },
//       ],
//     }));
//     setIsNewRateEditing(true); // Enable edit mode for the newly added row
//   };

//   const handleSaveNewRate = async () => {
//     // Check if the new rate has both fields filled
//     const lastRate =
//       currencyData.conversionRates[currencyData.conversionRates.length - 1];
//     if (!lastRate.currencyCode || !lastRate.rate) {
//       errorAlert("Please enter both currency code and conversion rate.");
//       return;
//     }

//     try {
//       await axiosInstance.post(`/currency-settings`, currencyData);
//       successAlert("New conversion rate added successfully!");
//       setIsNewRateEditing(false); // Disable edit mode after saving
//       fetchCurrencySettings();
//     } catch (error) {
//       console.error("Error adding new conversion rate:", error);
//       errorAlert("Error adding new conversion rate. Please try again.");
//     }
//   };

//   return (
//     <Card elevation={0}>
//       <ToastContainer />
//       <CardContent>
//         <Typography variant="h5" marginBottom={2}>
//           Currency Settings
//         </Typography>

//         {/* Native Currency Section */}
//         <Box
//           mt={2}
//           mb={4}
//           p={2}
//           style={{ backgroundColor: "#f9f9f9", borderRadius: "8px" }}
//         >
//           <Typography variant="h6" style={{ fontWeight: 600 }}>
//             Native Currency
//           </Typography>
//           <Grid container spacing={2} mt={1}>
//             <Grid item xs={6}>
//               <Typography variant="body2" color="textSecondary">
//                 Currency Name:
//               </Typography>
//               <Typography variant="body1">
//                 {currencyData.nativeCurrencyName}
//               </Typography>
//             </Grid>
//             <Grid item xs={6}>
//               <Typography variant="body2" color="textSecondary">
//                 Currency Code:
//               </Typography>
//               <Typography variant="body1">
//                 {currencyData.nativeCurrencyCode}
//               </Typography>
//             </Grid>
//           </Grid>
//         </Box>

//         {/* Conversion Rates Section */}
//         <Typography variant="h6" gutterBottom>
//           Conversion Rates
//         </Typography>
//         <TableContainer
//           component={Paper}
//           elevation={0}
//           style={{ marginBottom: "20px" }}
//         >
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>
//                   <strong>Currency Code</strong>
//                 </TableCell>
//                 <TableCell>
//                   <strong>Conversion Rate</strong>
//                 </TableCell>
//                 <TableCell align="right">
//                   <strong>Actions</strong>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {currencyData.conversionRates.map((rate, index) => (
//                 <TableRow key={index}>
//                   {isNewRateEditing &&
//                   index === currencyData.conversionRates.length - 1 ? (
//                     <>
//                       <TableCell>
//                         <TextField
//                           fullWidth
//                           label="Currency Code"
//                           name="currencyCode"
//                           value={rate.currencyCode}
//                           onChange={(e) => handleConversionRateChange(index, e)}
//                           variant="outlined"
//                         />
//                       </TableCell>
//                       <TableCell>
//                         <TextField
//                           fullWidth
//                           label="Conversion Rate"
//                           name="rate"
//                           type="number"
//                           value={rate.rate}
//                           onChange={(e) => handleConversionRateChange(index, e)}
//                           variant="outlined"
//                         />
//                       </TableCell>
//                       <TableCell align="right">
//                         <CustomButton
//                           onClick={handleSaveNewRate}
//                           startIcon={<Check />}
//                         >
//                           Save
//                         </CustomButton>
//                       </TableCell>
//                     </>
//                   ) : (
//                     <>
//                       <TableCell>{rate.currencyCode}</TableCell>
//                       <TableCell>{rate.rate}</TableCell>
//                       <TableCell align="right">
//                         <IconButton
//                           color="primary"
//                           onClick={() => handleEditClick(index)}
//                         >
//                           <CiEdit />
//                         </IconButton>
//                         <IconButton
//                           color="secondary"
//                           onClick={() => handleDeleteClick(index)}
//                         >
//                           <RiDeleteBin6Line />
//                         </IconButton>
//                       </TableCell>
//                     </>
//                   )}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         <Button
//           variant="contained"
//           color="primary"
//           startIcon={<Add />}
//           onClick={handleAddConversionRate}
//           style={{ marginTop: "20px" }}
//         >
//           Add Conversion Rate
//         </Button>

//         {/* Edit and Delete Dialogs */}
//         <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
//           <DialogTitle>Edit Conversion Rate</DialogTitle>
//           <DialogContent>
//             {editIndex !== null && (
//               <>
//                 <TextField
//                   fullWidth
//                   label="Currency Code"
//                   name="currencyCode"
//                   value={currencyData.conversionRates[editIndex].currencyCode}
//                   onChange={(e) => handleConversionRateChange(editIndex, e)}
//                   variant="outlined"
//                   margin="dense"
//                 />
//                 <TextField
//                   fullWidth
//                   label="Conversion Rate"
//                   name="rate"
//                   type="number"
//                   value={currencyData.conversionRates[editIndex].rate}
//                   onChange={(e) => handleConversionRateChange(editIndex, e)}
//                   variant="outlined"
//                   margin="dense"
//                 />
//               </>
//             )}
//           </DialogContent>
//           <DialogActions>
//             <CustomButton
//               style={{ width: "100%" }}
//               onClick={handleEditSubmit}
//               startIcon={<Check />}
//             >
//               Save
//             </CustomButton>
//             <CustomButton
//               variant="outlined"
//               onClick={() => setOpenEditDialog(false)}
//               startIcon={<Close />}
//             >
//               Cancel
//             </CustomButton>
//           </DialogActions>
//         </Dialog>

//         <Dialog
//           open={openDeleteDialog}
//           onClose={() => setOpenDeleteDialog(false)}
//         >
//           <DialogTitle>Confirm Delete</DialogTitle>
//           <DialogContent>
//             <DialogContentText>
//               Are you sure you want to delete this conversion rate?
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <CustomButton
//               style={{ width: "100%" }}
//               onClick={handleDeleteConfirm}
//               startIcon={<Check />}
//             >
//               Confirm
//             </CustomButton>
//             <CustomButton
//               variant="outlined"
//               onClick={() => setOpenDeleteDialog(false)}
//               startIcon={<Close />}
//             >
//               Cancel
//             </CustomButton>
//           </DialogActions>
//         </Dialog>
//       </CardContent>
//     </Card>
//   );
// };

// export default AddCurrencySettings;
