import React, { useCallback, useEffect, useState } from "react";
import formatDate, {
  formatLetterDate,
  formatMonthDate,
  successAlert,
  errorAlert,
  ConfirmationAlert,
  formatDatePrevious,
  customStyles,
} from "../../utils/services";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Button,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Download, Search, Update } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import CustomTooltip from "../../components/ui/CustomTootltip";
import CustomButton from "../../components/ui/CustomButton";
import NoDataFound from "../../components/NoDataFound";
import TableSkeletonLoader from "../../components/ui/TableSkeletonLoader";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import { MdOutlineFilterAlt } from "react-icons/md";
import CustomModal from "../../components/ui/CustomModal";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import { fetchCurrencySettings } from "../../actions/currencyActions";
import { fetchAllMatters } from "../../store/slices/matterSlice";
import { fetchEmployees } from "../../store/slices/employeesSlice";

const Ope = () => {
  const dispatch = useDispatch();
  const allCurrencyData = useSelector((state) => state.currency);
  const { filteredMatters } = useSelector((state) => state.matter);
  const { employees } = useSelector((state) => state.employee);

  useEffect(() => {
    dispatch(fetchAllMatters());
    dispatch(fetchEmployees());
  }, [dispatch]);

  const auth = useSelector((state) => state.auth);
  const adminId = auth.user._id;

  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 30));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );

  const [filteredWorkHour, setFilteredWorkHour] = useState([]);
  const [totalFilteredWorkHours, setTotalFilteredWorkHours] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [editingope, setEditingope] = useState(null);
  const [date, setDate] = useState(formatDate(new Date()));
  const [amount, setAmount] = useState(0);
  const [opeDescription, setopeDescription] = useState("");
  const [client, setClient] = useState("");
  const [matter, setMatter] = useState(""); //matter id
  const [selectedMatterName, setSelectedMatterName] = useState(""); //matter name
  const [selectedLawyer, setSelectedLawyer] = useState("");
  // const [currency, setCurrency] = useState("INR");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState("");

  //modal states
  const [matterModal, setMatterModal] = useState(""); //matter id
  const [selectedMatterNameModal, setSelectedMatterNameModal] = useState(""); //matter name
  const [selectedLawyerIdModal, setSelectedLawyerIdModal] = useState("");
  const [selectedLawyerNameModal, setSelectedLawyerNameModal] = useState("");
  const [filteredStatus, setFilteredStatus] = useState(null);

  //OPE states
  const [uploading, setUploading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  //multiple selection entries states
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [newStatus, setNewStatus] = useState("");

  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);

  const [currencyData, setCurrencyData] = useState({
    nativeCurrencyName: "",
    nativeCurrencyCode: "",
    conversionRates: [],
  });

  useEffect(() => {
    setCurrencyData(currencyData);
  }, [currencyData]);

  // Fetch currency settings when the component mounts if not already loaded
  useEffect(() => {
    if (!allCurrencyData.nativeCurrencyName) {
      dispatch(fetchCurrencySettings());
    }
  }, [dispatch, allCurrencyData.nativeCurrencyName]);

  // Set currency data to local state for easy manipulation (optional)
  useEffect(() => {
    setCurrencyData(allCurrencyData);
  }, [allCurrencyData]);

  const handleRemoveImage = async (event, index) => {
    // console.log("handleRemoveImage called");

    const updatedSelectedImages = [...selectedImages];
    const imageToRemove = updatedSelectedImages[index];

    // console.log("Attempting to delete image at index:", index);
    // console.log("Image URL:", imageToRemove);

    try {
      // Send a request to the backend to delete the image
      await axiosInstance.delete(`/deleteExpensesBill`, {
        data: { filePath: imageToRemove },
      });

      // console.log("Delete response:", result);

      // Remove the image from the state only if deletion is successful
      updatedSelectedImages.splice(index, 1);
      setSelectedImages(updatedSelectedImages);

      // console.log("Image deleted successfully from DigitalOcean Spaces.");
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");
    }
  };

  const handleFileUpload = async (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;

      // Create an array to store the promises of file uploads
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Create FormData for each file and add necessary details
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user._id);
        formData.append("employeeName", selectedLawyerNameModal);
        formData.append("employeeId", selectedLawyerIdModal);

        console.log("formData for images", formData);

        // Push the promise for each file upload to the array
        uploadPromises.push(
          axiosInstance.post(`/uploadExpensesBill`, formData)
        );
      }

      try {
        setUploading(true);

        // Wait for all file uploads to complete
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.data)
        );

        uploadResponses.forEach((response) => {
          if (!response.data) {
            console.error(
              `Error with status ${response.status} during file upload`
            );
          }
        });

        // Retrieve and store the URLs of uploaded files
        const uploadedFileUrls = uploadData.map((data) => data.url);
        setSelectedImages([...selectedImages, ...uploadedFileUrls]);

        // Reset the file input field
        e.target.value = "";
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Modal for add and update ope
  const openModal = (ope) => {
    if (ope) {
      console.log("Edit ope data", ope);
      setEditingope(ope);
      // setCurrency(ope.currency);
      setDate(ope.expenseDate);
      setAmount(ope.amount.$numberDecimal);
      setopeDescription(ope.description);
      setMatterModal(ope.matter._id);
      setSelectedMatterNameModal(ope.matter.name);
      setClient(ope.matter.client);
      setSelectedLawyerIdModal(ope.user._id);
      setSelectedLawyerNameModal(ope.user.fullName);
      setStatus(ope.status);
    } else {
      setEditingope(null);
      setDate(formatDate(new Date()));
      setMatterModal("");
      setSelectedMatterNameModal("");
      setSelectedLawyerIdModal("");
      setSelectedLawyerNameModal("");
      setClient("");
      setAmount(0);
      setopeDescription("");
      // setCurrency("INR");
      setStatus("pending");
    }
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedImages([]);
  };

  //Get all opees
  const fetchAllopees = useCallback(async () => {
    setLoading(true);

    try {
      const response = await axiosInstance.get(`/expense/admin`, {
        params: {
          user: selectedLawyer,
          fromDate: startSelectedDate, // Format the date as 'YYYY-MM-DD'
          toDate: endSelectedDate,
          matterId: matter,
          adminId,
          limit: rowsPerPage,
          page: page,
        },
      });

      if (response.data) {
        setFilteredWorkHour(response.data.expenses);
        setTotalFilteredWorkHours(response.data.totalCount);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorAlert("Error in fetching OPE data");
      console.error("Error in fetching OPE data", error);
    }
  }, [
    selectedLawyer,
    startSelectedDate,
    endSelectedDate,
    matter,
    adminId,
    rowsPerPage,
    page,
  ]);

  //Update and Create
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = {
      admin: adminId,
      user: selectedLawyerIdModal,
      matter: matterModal,
      expenseDate: date,
      description: opeDescription,
      // currency: currency,
      amount: amount,
      opeBills: selectedImages,
      status,
    };

    console.log("formData", formData);

    try {
      console.log("editingope", editingope);
      if (editingope) {
        // Update existing leave
        const response = await axiosInstance.put(
          `/expense/${editingope._id}`,
          formData
        );
        if (response.data) {
          successAlert("OPE updated successfully!");
        }
      } else {
        // Add new ope
        const response = await axiosInstance.post(`/expense`, formData);
        if (response.data) {
          successAlert("Ope added successfully!");
        }
      }
      fetchAllopees();
      closeModal();
    } catch (error) {
      console.error("Error:", error);
      errorAlert("Error : Something went wrong", error);
    }
  };

  const generateopeReport = async () => {
    const doc = new jsPDF();

    const tableColumns = [
      "Date",
      "Resource",
      "Matter",
      "Description",
      `Amount ${currencyData.nativeCurrencyCode}`,
      "Status",
    ];

    const tableData = [];

    // Generate table data
    if (filteredWorkHour.length > 0) {
      filteredWorkHour.forEach((ope, index) => {
        const rowData = [
          formatDatePrevious(ope.expenseDate),
          ope.user.fullName,
          ope.matter.name,
          ope.description,
          parseFloat(ope.amount.$numberDecimal)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ope.status,
        ];
        tableData.push(rowData);
      });
    }

    const footerRow1 = [
      {
        content: `Total OPE Amount (${currencyData.nativeCurrencyCode})`,
        colSpan: 4,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      filteredWorkHour
        .reduce(
          (total, ope) => parseFloat(ope.amount.$numberDecimal) + total,
          0
        )
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    tableData.push(footerRow1);

    //First Page
    doc.setFontSize(25);
    doc.setFont("helvetica", "bold");
    doc.text("OPE Report", 80, 20);

    doc.setFont("helvetica", "underline");
    doc.setFontSize(15);
    if (startSelectedDate !== "") {
      doc.text(
        `${formattedStartDate}` +
          (startSelectedDate !== endSelectedDate
            ? ` - ${formattedEndDate}`
            : ""),
        (210 -
          doc.getTextWidth(
            `${formattedStartDate}` +
              (startSelectedDate !== endSelectedDate
                ? ` - ${formattedEndDate}`
                : "")
          )) /
          2,
        30
      );
    }

    doc.autoTable(tableColumns, tableData, {
      startY: 40,
      theme: "grid", // Set the theme to 'grid' to display internal lines
      tableLineColor: [0, 0, 0], // Color of the table lines (black in this example)
      tableLineWidth: 0.1, // Width of the table lines in units (default is 0.1)
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
      },
    });

    let finalY = doc.autoTable.previous.finalY;

    // Loading and adding images
    for (const ope of filteredWorkHour) {
      // Flag to track if at least one image was successfully loaded for the operation
      let imageLoaded = false;

      for (const imageUrl of ope.opeBills) {
        const imageHeight = 70;
        const imageWidth = 70;
        const margin = 10;

        // Check if there is enough space on the current page for the image
        if (
          doc.internal.pageSize.getHeight() - finalY <
          imageHeight + margin * 2
        ) {
          doc.addPage();
          finalY = margin; // Reset Y coordinate at the top of the new page
        }

        // Add image
        await new Promise((resolve, reject) => {
          if (!imageUrl) {
            console.warn("Skipping image with null URL");
            resolve(); // Resolve the promise to continue processing other images
            return;
          }

          let img = new Image();
          img.onload = () => {
            imageLoaded = true;
            doc.addImage(
              img,
              "JPEG",
              margin + 5,
              finalY + 10,
              imageWidth,
              imageHeight
            );

            // Calculate position for the text
            const text = `${ope.user.fullName} | ${formatDatePrevious(
              ope.expenseDate
            )} | ${currencyData.nativeCurrencyCode} ${
              ope.amount.$numberDecimal
            }`;

            if (!imageLoaded) {
              // Display operation data if no image was loaded
              doc.text(text, margin, finalY + 10);
            } else {
              // Calculate position for the text when image is loaded
              const textWidth =
                (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) /
                doc.internal.scaleFactor;
              const textX = margin + imageWidth + 10; // 5 is a small buffer space between image and text

              // Ensure text does not go outside the page
              const textEndX = textX + textWidth;
              if (textEndX > doc.internal.pageSize.getWidth() - margin) {
                // If text goes outside, start from a new line below the image
                doc.text(text, margin, finalY + imageHeight + 15);
              } else {
                // Else, align to the right of the image
                doc.text(
                  text,
                  textX,
                  finalY + imageHeight / 2 + doc.internal.getFontSize() / 3
                );
              }
            }

            finalY += imageHeight + margin * 2; // Update Y coordinate after adding the image
            resolve();
          };
          img.onerror = (error) => {
            console.error("Error loading image:", error);
            reject(error); // Reject the promise if there's an error loading the image
          };

          const correctedUrl = imageUrl.startsWith("https://")
            ? imageUrl
            : `https://${imageUrl}`;
          img.src = correctedUrl;
        });
      }
    }

    doc.save("Ope_Report.pdf");
  };

  const handleFilterChange = (status) => {
    setFilteredStatus(status);
  };

  //mupltiple select entries functions
  const handleSelectEntry = (id, checked) => {
    if (checked) {
      setSelectedEntries([...selectedEntries, id]);
    } else {
      setSelectedEntries(selectedEntries.filter((entryId) => entryId !== id));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    // console.log(isChecked);
    setSelectAll(isChecked);

    // If "Select All" checkbox is checked, set all entry IDs as selected; otherwise, clear selection
    setSelectedEntries(
      isChecked ? filteredWorkHour.map((entry) => entry._id) : []
    );
    // console.log(selectedEntries);
  };

  const handleUpdateEntries = () => {
    // Send Axios request to backend to update selected entries with new matter code

    if (newStatus) {
      handleUpdateEntriesAlert();
    } else {
      errorAlert("Select the new matter");
    }
  };

  const handleUpdateEntriesAlert = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onUpdateMultipleEntries(id)}
        question={"Are you sure to update matter of all selected entries?"}
        answer={"Yes, Update Status"}
        icon={<Update />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onUpdateMultipleEntries = async (entryId) => {
    axiosInstance
      .post(`/updateMultipleExpensesStatus`, {
        entries: selectedEntries,
        newStatus,
      })
      .then((response) => {
        // Handle success response
        fetchAllopees();
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating entries:", error);
      });
  };

  const filteredOpes = filteredStatus
    ? filteredWorkHour.filter((entry) => entry.status === filteredStatus)
    : filteredWorkHour;

  // console.log("OPE filteredWorkHour", filteredWorkHour);

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  //Filter function and states
  const [anchorEl, setAnchorEl] = useState(null);

  // Function to handle the opening of the menu
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Function to handle the closing of the menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Add event listener to close modal on outside click
  useEffect(() => {
    if (showModal) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    // Cleanup event listener
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showModal]);

  // Close modal when clicking outside of it
  const handleOutsideClick = (e) => {
    if (e.target.className === "modal") {
      setShowModal(false);
    }
  };

  // State to keep track of the deleted item
  const [deletedItem, setDeletedItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Delete handler
  const handleSoftDelete = async (entryId) => {
    try {
      const response = await axiosInstance.delete(
        `/expense/softdelete/${entryId}`
      );

      if (response.data) {
        setDeletedItem({ entryId, ...response.data });
        // Show the undo alert
        setSnackbarOpen(true);
        console.log("Deleted Item State:", { entryId, ...response.data }); // Debug the deleted item state
        fetchAllopees();
      } else {
        errorAlert("Failed to delete ope.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting ope.");
    }
  };

  // Undo handler
  const handleUndo = async () => {
    try {
      if (deletedItem) {
        const response = await axiosInstance.post(
          `/expense/restore/${deletedItem.entryId}`
        );

        if (response.data) {
          setDeletedItem(null);
          successAlert("Ope restored successfully.");
          fetchAllopees();
        } else {
          errorAlert("Failed to restore ope.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while restoring ope.");
    }
  };

  useEffect(() => {
    fetchAllopees();
  }, [
    dispatch,
    fetchAllopees,
    page,
    rowsPerPage,
    selectedLawyer,
    selectedMatterName,
  ]);

  useEffect(() => {
    if (startSelectedDate && endSelectedDate) {
      fetchAllopees();
    }
  }, [startSelectedDate, endSelectedDate, fetchAllopees]);

  return (
    <div>
      <ToastContainer />

      {/* Snackbar for Undo Action */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds if no action is taken
        onClose={() => setSnackbarOpen(false)}
        message="Expense deleted"
        action={
          <Button color="secondary" size="small" onClick={handleUndo}>
            UNDO
          </Button>
        }
      />

      {/* Search Bar  */}
      <Paper elevation={0} className="rounded-full">
        <Grid container spacing={1} alignItems="center" marginBottom="1rem">
          {/* Main Heading  */}
          <Grid item container spacing={1} xs={12} sm={7} md={3}>
            <Grid item display="flex">
              <Typography variant="h5">Expenses</Typography>
            </Grid>
            <Grid item>
              <CustomTooltip text="Add Ope">
                <CustomButton
                  style={{
                    borderRadius: "50%",
                    minWidth: "5px",
                    height: "30px",
                    transition:
                      "transform 0.3s ease-in-out, background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#886a47",
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => openModal(null)}
                >
                  <AddIcon />
                </CustomButton>
              </CustomTooltip>
            </Grid>
          </Grid>

          {/* Matters  */}
          <Grid item xs={12} sm={7} md={2.5}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatter(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);
              }}
              isSearchable
              placeholder="Search Matter | Client"
              styles={customStyles}
            />
          </Grid>

          {/* Resources  */}
          <Grid item xs={12} sm={5} md={1.5}>
            <Select
              options={employees}
              value={employees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Resource"
              styles={customStyles}
            />
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <CustomTooltip text={"Select Dates"}>
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
              />
            </CustomTooltip>

            <Typography>
              {formatMonthDate(startSelectedDate)} -{" "}
              {formatMonthDate(endSelectedDate)}
            </Typography>
          </Grid>

          {/* Search Icon Button */}
          <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Search expenses">
              <IconButton
                // onClick={() => fetchAllopees()}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <Search style={{ fontSize: "20px" }} /> {/* Custom icon size */}
              </IconButton>
            </CustomTooltip>
          </Grid>

          {/* Download Icon Button */}
          <Grid item xs={4} sm={4} md={0.4}>
            <CustomTooltip text="Download Report">
              <IconButton
                onClick={generateopeReport}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <Download style={{ fontSize: "20px" }} />
              </IconButton>
            </CustomTooltip>
          </Grid>

          <Grid item xs={4} sm={4} md={0.4}>
            {filteredWorkHour.length > 0 && (
              <div
                style={{
                  margin: "1rem 0",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CustomTooltip text="Filter By Status">
                  <IconButton
                    onClick={handleClick}
                    className=" text-black hover:bg-gray-800"
                  >
                    <MdOutlineFilterAlt style={{ fontSize: "20px" }} />
                  </IconButton>
                </CustomTooltip>

                {/* Dropdown Menu */}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => handleFilterChange(null)}>
                    All
                  </MenuItem>
                  <MenuItem onClick={() => handleFilterChange("pending")}>
                    Pending
                  </MenuItem>
                  <MenuItem onClick={() => handleFilterChange("paid")}>
                    Paid
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Grid>
        </Grid>

        {selectedEntries.length > 1 && (
          <Grid container spacing={1} alignItems="baseline" marginTop="1rem">
            <Grid item xs={12} sm={7} md={5}>
              <select
                value={newStatus}
                name="newStatus"
                onChange={(e) => setNewStatus(e.target.value)}
                style={{ width: "100%", padding: 7 }}
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="paid">Paid</option>
              </select>
            </Grid>

            <Grid item xs={4} sm={4} md={3}>
              <CustomButton
                variant="contained"
                style={{ width: "100%" }}
                onClick={() => handleUpdateEntries()}
              >
                <Update style={{ marginRight: "8px" }} />
                Update Status
              </CustomButton>
            </Grid>
          </Grid>
        )}
      </Paper>

      {/* Modal  */}
      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={closeModal}
          title={editingope ? "Edit OPE" : "Add OPE"}
          onSubmit={handleSubmit}
        >
          {/* 1st line inputs */}
          <div className="flex gap-4 w-full items-end">
            <div style={{ width: "50%" }}>
              {selectedMatterNameModal}
              <Select
                options={filteredMatters}
                value={filteredMatters.find(
                  (option) =>
                    option.label === `${selectedMatterName} | ${client}`
                )}
                onChange={(e) => {
                  setMatterModal(e.value);
                  setSelectedMatterName(e.label.split("|")[0]);
                  setClient(e.label.split("|")[1]);
                }}
                isSearchable
                placeholder="Search Matter | Client"
                styles={customStyles}
              />
            </div>
            <div style={{ width: "50%" }}>
              <Select
                options={employees}
                value={employees.find(
                  (resOption) =>
                    resOption.label === `${selectedLawyerNameModal}`
                )}
                onChange={(e) => {
                  setSelectedLawyerIdModal(e.value);
                  setSelectedLawyerNameModal(e.label.split("|")[0]);
                  // console.log(e.label);
                }}
                isSearchable
                placeholder="Select Resource"
                styles={customStyles}
              />
            </div>
          </div>

          {/* 2nd line inputs  */}
          <div className="flex gap-4 w-full">
            <div style={{ width: "100%" }}>
              <label>Date</label>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            {/* <div style={{ width: "50%" }}>
              <label>Currency</label>
              <select
                value={currency}
                name="currency"
                onChange={(e) => setCurrency(e.target.value)}
                style={{ width: "100%", padding: 7 }}
              >
                <option value="">Select Currency</option>
                <option value="USD">USD</option>
                <option value="INR">INR</option>
                <option value="SGD">SGD</option>
                <option value="POUND">POUND</option>
                <option value="EURO">EURO</option>
                <option value="YEN">YEN</option>
              </select>
            </div> */}
          </div>

          {/* 3rd line inputs */}
          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <label>Amount ({currencyData.nativeCurrencyCode})</label>
              <input
                type="number"
                value={amount}
                placeholder="Enter amount"
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
            <div style={{ width: "50%" }}>
              <label>Status</label>
              <select
                value={status}
                name="status"
                onChange={(e) => setStatus(e.target.value)}
                style={{ width: "100%", padding: 7 }}
              >
                <option value="">Select Status</option>
                <option value="pending">Pending</option>
                <option value="paid">Paid</option>
              </select>
            </div>
          </div>

          {/* 4th line inputs  */}
          <div className="flex gap-4 w-full">
            <input
              type="file"
              // multiple
              accept={"image/jpeg, image/png, image/webp, image/heif"}
              onChange={(e) => {
                if (!selectedLawyerNameModal) {
                  toast.warning("Please select a resource first!");
                  e.target.value = null;
                } else {
                  handleFileUpload(e);
                }
              }}
              disabled={uploading}
            />
            {selectedImages.map((file, index) => (
              <div key={index}>
                <img
                  src={file}
                  alt={index + 1}
                  style={{ maxWidth: "100px", height: "100px" }}
                />
                <button
                  type="button"
                  onClick={(event) => handleRemoveImage(event, index)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>

          {uploading ? <p>Uploading...</p> : null}

          <div>
            <textarea
              type="text"
              placeholder="Ope description"
              value={opeDescription}
              onChange={(e) => setopeDescription(e.target.value)}
              style={{ width: "100%" }}
            />
          </div>
        </CustomModal>
      )}

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="subtitle1"
            style={{ marginLeft: "10px", fontWeight: 300, fontStyle: "italic" }}
          >
            All expenses related to the matter are automatically added to the
            invoices.
          </Typography>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={totalFilteredWorkHours}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        <TableContainer sx={{ maxHeight: "62vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              {/* columns row  */}
              <TableRow>
                <TableCell id="table-header">
                  <CustomTooltip text="Select All">
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      checked={selectAll}
                      onChange={(e) => handleSelectAll(e)}
                    />
                  </CustomTooltip>
                </TableCell>

                <TableCell style={{ fontWeight: "bold" }}>Date</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Resource</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Matter</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>
                  Description
                </TableCell>
                {/* <TableCell style={{ fontWeight: "bold" }}>Currency</TableCell> */}
                <TableCell style={{ fontWeight: "bold" }}>
                  Amount ({currencyData.nativeCurrencyCode})
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Bills</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Edit</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <>
                  <TableSkeletonLoader rows={6} colSpan={11} />
                </>
              ) : filteredOpes.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <NoDataFound message="Oops! No OPE Found." />
                  </TableCell>
                </TableRow>
              ) : (
                filteredOpes.map((ope, index) => (
                  <TableRow key={ope._id} role="checkbox" tabIndex={-1}>
                    <TableCell>
                      <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={selectedEntries.includes(ope._id)}
                        onChange={(e) =>
                          handleSelectEntry(ope._id, e.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell>{formatMonthDate(ope.expenseDate)}</TableCell>
                    <TableCell>{ope.user.fullName}</TableCell>
                    <TableCell>{ope.matter.name}</TableCell>
                    <TableCell>{ope.description}</TableCell>
                    {/* <TableCell>{ope.currency}</TableCell> */}
                    <TableCell>
                      {parseFloat(ope.amount.$numberDecimal)
                        .toFixed(1)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </TableCell>
                    <TableCell>
                      {ope?.opeBills?.map((imageUrl, index) => {
                        // Check if imageUrl is not null or undefined
                        if (!imageUrl) {
                          console.warn(
                            `Skipping image with null URL for index ${index} for entry:`,
                            ope
                          );
                          return null; // Skip rendering the image if URL is null
                        }

                        const correctedUrl = imageUrl.startsWith("https://")
                          ? imageUrl
                          : `https://${imageUrl}`;

                        return (
                          <div key={index}>
                            <a
                              href={correctedUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              <img
                                src={correctedUrl}
                                alt={`Bill ${index + 1}`}
                                style={{
                                  maxWidth: "100px",
                                  margin: "0.5rem",
                                }}
                                onError={(e) => {
                                  console.error(
                                    `Image load error for index ${index} for entry:`,
                                    ope,
                                    e
                                  );
                                }}
                              />
                            </a>
                          </div>
                        );
                      })}
                    </TableCell>
                    <TableCell>{ope.status}</TableCell>
                    <TableCell className="table-cell">
                      <CiEdit
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(ope)}
                      />
                    </TableCell>
                    <TableCell className="table-cell">
                      <RiDeleteBin6Line
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSoftDelete(ope._id)}
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            <TableRow>
              <TableCell colSpan={5}>
                <b>Total</b>
              </TableCell>
              <TableCell>
                <b>{currencyData.nativeCurrencyCode}</b>
              </TableCell>
              <TableCell>
                <b>
                  {filteredOpes.length > 0 &&
                    filteredOpes
                      .reduce(
                        (total, ope) =>
                          parseFloat(ope.amount.$numberDecimal) + total,
                        0
                      )
                      .toFixed(1)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </b>
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default Ope;
