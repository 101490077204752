import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import {
  customStyles,
  errorAlert,
  formatDateToYYYYMMDD,
  successAlert,
} from "../../utils/services";
import Select from "react-select";
import "./EditMatter.css";
import CustomButton from "../../components/ui/CustomButton";
import { Download } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import { COLORS } from "../../constants/theme";
import { Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import CustomModal from "../../components/ui/CustomModal";
import { fetchCurrencySettings } from "../../actions/currencyActions";
import { fetchDesignations } from "../../store/slices/globalSlice";
import { fetchEmployees } from "../../store/slices/employeesSlice";

const Modal = ({ isOpen, onClose, entryData }) => {
  // Constants
  const dispatch = useDispatch();

  //global redux states
  const auth = useSelector((state) => state.auth);
  const adminClient = useSelector((state) => state.adminClient);
  const allCurrencyData = useSelector((state) => state.currency);
  const { billedDesignations } = useSelector((state) => state.global);
  const { employees, filteredEmployees } = useSelector(
    (state) => state.employee
  );
  const { clientList } = adminClient;

  //Fetching global redux states
  useEffect(() => {
    dispatch(fetchDesignations());
    dispatch(fetchEmployees());
  }, [dispatch]);

  const [currencyData, setCurrencyData] = useState({
    nativeCurrencyName: "",
    nativeCurrencyCode: "",
    conversionRates: [],
  });

  useEffect(() => {
    setCurrencyData(currencyData);
  }, [currencyData]);

  // Fetch currency settings when the component mounts if not already loaded
  useEffect(() => {
    if (!allCurrencyData.nativeCurrencyName) {
      dispatch(fetchCurrencySettings());
    }
  }, [dispatch, allCurrencyData.nativeCurrencyName]);

  // Set currency data to local state for easy manipulation (optional)
  useEffect(() => {
    setCurrencyData(allCurrencyData);
  }, [allCurrencyData]);

  const clientNames = clientList.map((client) => ({
    value: client._id,
    label: `${client.name}`,
  }));

  // States
  const [formData, setFormData] = useState({
    admin: auth?.user?._id,
    entryId: "",
    clientName: "",
    matterName: "",
    currency: "",
    resourceSpecificPrice: [],
    pricePerHour: "",
    status: "",
    disableDate: "",
    matterOpenDate: "",
    matterAddress: "",
    engagementLetter: [],
    flatFeesAmount: "",
    advanceAmount: "",
  });

  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  // State for designation prices
  const [designationPrices, setDesignationPrices] = useState({});

  // New resource input states
  const [newResource, setNewResource] = useState("");
  const [newResourcePrice, setNewResourcePrice] = useState("");

  // State for custom price input
  const [customPrice, setCustomPrice] = useState("");
  const [showPriceInput, setShowPriceInput] = useState(false);

  // State for shift mode
  const [shiftMode, setShiftMode] = useState("");

  useEffect(() => {
    setFormData({
      admin: auth?.user?._id,
      entryId: entryData.id || "",
      clientName: entryData.client || "",
      matterName: entryData.matter || "",
      currency: entryData.currency || "",
      pricePerHour:
        (typeof entryData.price === "string" && entryData.price) || "",
      resourceSpecificPrice:
        Array.isArray(entryData.price) && entryData.price.length > 0
          ? entryData.price
          : [],
      matterAddress: entryData.matterAddress || "",
      status: entryData.status || "",
      disableDate: formatDateToYYYYMMDD(entryData.disableDate) || "",
      matterOpenDate: formatDateToYYYYMMDD(entryData.matterOpenDate) || "",
      engagementLetter: entryData?.engagementLetter || [],
      flatFeesAmount: entryData.flatFeesAmount || 0,
      advanceAmount: entryData.advanceAmount || 0,
    });

    if (entryData.engagementLetter.length > 0) {
      setSelectedFile(entryData.engagementLetter);
    }
    dispatch(fetchEmployees());
  }, [entryData]);

  const currencies = [
    currencyData.nativeCurrencyCode,
    ...currencyData.conversionRates.map((curr) => curr.currencyCode),
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    console.log("-------", name, value);

    let updatedFormData = { ...formData, [name]: value };

    // Automatically update status when disable date is entered or cleared
    if (name === "disableDate") {
      updatedFormData.status = value ? "disable" : "enable";
    }

    // Clear disableDate when status is set to enable
    if (name === "status" && value === "enable") {
      updatedFormData.disableDate = "";
    }

    setFormData(updatedFormData);
  };

  const handleEmployeePriceChange = (e, index) => {
    let { value } = e.target;

    // Remove any leading or trailing spaces
    value = value.trim();

    // Ensure only numbers are entered
    if (!isNaN(value)) {
      let updatedResourceSpecificPrice = [...formData.resourceSpecificPrice];
      const [employeeName] = updatedResourceSpecificPrice[index].split(":");

      // Update the price with the trimmed value and ensure it's correctly formatted
      updatedResourceSpecificPrice[index] = `${employeeName.trim()}: ${value}`;

      // Update formData with the new resourceSpecificPrice array
      setFormData({
        ...formData,
        resourceSpecificPrice: updatedResourceSpecificPrice,
      });
    } else {
      // Optionally handle the case where the input is not a valid number
      errorAlert("Please enter a valid number for the price.");
    }
  };

  const handleFileUpload = async (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user._id);

        uploadPromises.push(axiosInstance.post(`/uploadMatterEL`, formData));
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.data)
        );

        uploadResponses.forEach((response) => {
          if (!response.data) {
            console.error(
              `Error with status ${response.status} during file upload`
            );
          }
        });

        const uploadedFileUrls = uploadData.map((data) => data.url);
        setSelectedFile([...selectedFile, ...uploadedFileUrls]);

        e.target.value = "";
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleRemoveFile = async (index) => {
    const fileToRemove = selectedFile[index];

    try {
      // Send a request to the backend to delete the file
      const result = await axiosInstance.delete("/deleteEngagementLetter", {
        filePath: fileToRemove,
      });

      // console.log("Delete response:", result);

      const newSelectedFile = selectedFile.filter((_, i) => i !== index);
      setSelectedFile(newSelectedFile);

      // console.log("File deleted successfully from DigitalOcean Spaces.");
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("Failed to delete file. Please try again.");
    }
  };

  const handleAddNewResource = () => {
    if (newResource && newResourcePrice) {
      // Create a new resource entry
      const newEntry = `${newResource.trim()}: ${newResourcePrice}`;

      // Add the new entry to the existing resourceSpecificPrice array
      setFormData((prevState) => ({
        ...prevState,
        resourceSpecificPrice: [...prevState.resourceSpecificPrice, newEntry],
      }));

      // Reset the new resource inputs
      setNewResource("");
      setNewResourcePrice("");
    } else {
      errorAlert("Please enter both resource name and price.");
    }
  };

  // Handle designation price change
  const handleDesignationPriceChange = (designation, price) => {
    setDesignationPrices((prevPrices) => ({
      ...prevPrices,
      [designation]: price,
    }));
  };

  // Apply designation prices to resources
  const applyDesignationPrices = () => {
    const newResourceSpecificPrices = employees
      .filter(
        (emp) =>
          billedDesignations.includes(emp.designation) &&
          emp.status === "active"
      )
      .map((employee) => {
        const price = designationPrices[employee.designation] || 0;
        return `${employee.fullName}: ${price}`;
      });

    setFormData((prevState) => ({
      ...prevState,
      resourceSpecificPrice: newResourceSpecificPrices,
      pricePerHour: "", // Clear price per hour
    }));

    setShowPriceInput(false); // Hide the custom price input modal
    successAlert("Price applied based on designations.");
  };

  const handleShiftToResourceSpecific = () => {
    if (formData.pricePerHour) {
      setShiftMode("hourToResource");
      setShowPriceInput(true);
    } else {
      errorAlert("No price per hour to shift.");
    }
  };

  const handleShiftToPricePerHour = () => {
    if (formData.resourceSpecificPrice.length > 0) {
      setShiftMode("resourceToHour");
      setShowPriceInput(true);
    } else {
      errorAlert("No resource-specific prices to shift.");
    }
  };

  const applyCustomPriceToPricePerHour = () => {
    if (isNaN(customPrice) || customPrice <= 0) {
      errorAlert("Please enter a valid price.");
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      pricePerHour: customPrice,
      resourceSpecificPrice: [], // Clear the resource-specific prices
    }));

    setCustomPrice(""); // Reset custom price input
    setShowPriceInput(false);
    successAlert("Price successfully shifted to price per hour.");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure disableDate is entered if the status is 'disable'
    if (formData.status === "disable" && !formData.disableDate) {
      errorAlert("Please enter a disable date before disabling the matter.");
      return;
    }

    // Filter out empty prices from resourceSpecificPrice before submission
    const filteredResourceSpecificPrice = formData.resourceSpecificPrice.filter(
      (priceEntry) => {
        const [, price] = priceEntry.split(":");
        return price.trim() !== "";
      }
    );

    const form = {
      admin: auth?.user?._id,
      entryId: formData.entryId,
      clientName: formData.clientName,
      matterName: formData.matterName,
      currency: formData.currency,
      resourceSpecificPrice: filteredResourceSpecificPrice, // Use the filtered prices
      pricePerHour: formData.pricePerHour,
      status: formData.status,
      disableDate: formData.disableDate,
      matterOpenDate: formData.matterOpenDate,
      engagementLetter: selectedFile,
      matterAddress: formData.matterAddress,
      flatFeesAmount: formData.flatFeesAmount,
      advanceAmount: formData.advanceAmount,
    };

    // console.log("Edit matter data going to backend", form);

    try {
      const response = await axiosInstance.put(
        `/matter/updateMatter/${formData.entryId}`,
        form
      );
      // console.log("Updated Matter Data Response from server:", response.data);

      if (response.data) {
        successAlert("Matter updated successfully");
      }
      onClose();
    } catch (error) {
      console.error("Error updating matter details:", error);
      errorAlert("Error updating matter details, try again...");
    }
  };

  const handleRemoveResource = (index) => {
    const updatedResourceSpecificPrice = formData.resourceSpecificPrice.filter(
      (_, i) => i !== index
    );
    setFormData({
      ...formData,
      resourceSpecificPrice: updatedResourceSpecificPrice,
    });
  };

  return (
    <CustomModal
      showModal={isOpen}
      closeModal={onClose}
      title="Edit Matter"
      onSubmit={handleSubmit}
    >
      {/* Client, Matter and Currency  */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          marginTop: 15,
        }}
      >
        <div style={{ width: "60%" }}>
          <label>
            <p>
              <b>Client</b>
            </p>
          </label>
          <Select
            name="clientName"
            options={clientNames}
            value={clientNames.find(
              (option) => option.label === formData.clientName
            )}
            onChange={(selectedOption) => handleInputChange(selectedOption)}
            isSearchable
            placeholder="Search Client"
          />
        </div>
        <div style={{ width: "60%" }}>
          <label>
            <p>
              <b>Matter</b>
            </p>
          </label>
          <input
            type="text"
            name="matterName"
            value={formData.matterName}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ width: "40%" }}>
          <label>
            <b>Currency</b>
          </label>
          <select
            type="text"
            name="currency"
            value={formData.currency}
            onChange={handleInputChange}
            style={{ width: "100%" }}
          >
            {currencies.map((curr) => (
              <option value={curr}>{curr}</option>
            ))}
          </select>
        </div>
      </div>

      {/* Prices inputs */}
      <div>
        {!formData.resourceSpecificPrice.length > 0 && (
          <div>
            <input
              type="text"
              name="pricePerHour"
              value={formData.pricePerHour}
              onChange={handleInputChange}
            />
            <CustomButton
              type="button"
              onClick={handleShiftToResourceSpecific}
              style={{ margin: "10px", width: "50%" }}
            >
              Shift to Resource Specific
            </CustomButton>
          </div>
        )}

        {/* Only show the new resource input fields if pricePerHour is empty */}
        {formData.resourceSpecificPrice.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 20,
              width: "100%",
              marginTop: 15,
              justifyContent: "center",
            }}
          >
            <div style={{ width: "50%" }}>
              {/* <label>Select User</label> */}
              <Select
                options={filteredEmployees.map((employee) => ({
                  value: employee.label,
                  label: employee.label,
                }))}
                value={
                  newResource
                    ? { label: newResource, value: newResource }
                    : null
                }
                onChange={(selectedOption) =>
                  setNewResource(selectedOption.value)
                }
                placeholder="Select resource"
                isSearchable
                styles={customStyles}
              />
            </div>
            <div style={{ width: "50%" }}>
              {/* <label>New Resource Price</label> */}
              <input
                type="number"
                value={newResourcePrice}
                onChange={(e) => setNewResourcePrice(e.target.value)}
                placeholder="Enter resource price"
                onWheel={(e) => e.target.blur()} // Disable scroll for number input
              />
            </div>
            <div style={{ width: "50%" }}>
              <CustomButton
                type="button"
                onClick={handleAddNewResource}
                style={{ width: "100%" }}
              >
                Add Resource
              </CustomButton>
            </div>
          </div>
        )}

        {formData?.resourceSpecificPrice &&
          formData?.resourceSpecificPrice.map((employee, index) => {
            const [employeeName, employeePrice] = employee.split(":");
            return (
              <>
                <div
                  key={index}
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    width: "100%",
                    alignItems: "center", // Align the cross icon with input field
                  }}
                >
                  <div style={{ width: "40%" }}>
                    <Typography variant="body2" marginRight="10px">
                      {employeeName.trim()}
                    </Typography>
                  </div>
                  <div style={{ width: "20%" }}>
                    <input
                      type="text"
                      name={`employeePrice${index}`}
                      value={employeePrice}
                      onChange={(e) => {
                        handleEmployeePriceChange(e, index);
                      }}
                      style={{ textAlign: "end" }}
                    />
                  </div>
                  <div style={{ width: "10%", textAlign: "right" }}>
                    <Close
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={() => handleRemoveResource(index)} // Cross icon triggers resource removal
                    />
                  </div>
                </div>
              </>
            );
          })}

        {formData?.resourceSpecificPrice.length > 0 && (
          <CustomButton
            type="button"
            onClick={handleShiftToPricePerHour}
            style={{ marginTop: "10px", width: "fit-content" }}
          >
            Change to Blended Rate/Hour
          </CustomButton>
        )}
      </div>

      {/* Custom price input modal */}
      {showPriceInput && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            zIndex: 1000,
          }}
        >
          {shiftMode === "hourToResource" ? (
            <>
              <h3 className="mb-2 display-flex align-items-center text-center">
                Enter Prices for Designations
              </h3>
              {billedDesignations.map((designation) => (
                <div key={designation} style={{ marginBottom: "10px" }}>
                  <label>{designation}: </label>
                  <input
                    type="number"
                    value={designationPrices[designation] || ""}
                    onChange={(e) =>
                      handleDesignationPriceChange(designation, e.target.value)
                    }
                    placeholder="Enter price"
                    style={{ width: "100%" }}
                    onWheel={(e) => e.target.blur()} // Disable scroll for number input
                  />
                </div>
              ))}
              <CustomButton
                type="button"
                onClick={applyDesignationPrices}
                style={{ marginRight: "10px", width: "auto" }}
              >
                Apply to Resource Specific
              </CustomButton>
            </>
          ) : (
            <>
              <input
                type="number"
                value={customPrice}
                onChange={(e) => setCustomPrice(e.target.value)}
                placeholder="Blended Rate/Hour"
                style={{ width: "100%", marginBottom: "10px" }}
                onWheel={(e) => e.target.blur()} // Disable scroll for number input
              />
              <CustomButton
                type="button"
                onClick={applyCustomPriceToPricePerHour}
                style={{ marginRight: "auto", width: "auto" }}
              >
                Apply Blended Rate
              </CustomButton>
            </>
          )}
          <CustomButton
            type="button"
            variant="outlined"
            style={{ width: "auto", height: "auto" }}
            onClick={() => setShowPriceInput(false)}
          >
            Cancel
          </CustomButton>
        </div>
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
          width: "100%",
          marginTop: 15,
        }}
      >
        <div style={{ width: "50%" }}>
          <label>Fixed Fees ({formData.currency})</label>
          <input
            type="number"
            name="flatFeesAmount"
            value={formData.flatFeesAmount || 0}
            onChange={handleInputChange}
            onWheel={(e) => e.target.blur()}
          />
        </div>
        <div style={{ width: "50%" }}>
          <label>Advance ({formData.currency})</label>
          <input
            type="number"
            name="advanceAmount"
            value={formData.advanceAmount || 0}
            onChange={handleInputChange}
            onWheel={(e) => e.target.blur()}
          />
        </div>
      </div>

      <div style={{ width: "100%", display: "flex", gap: "20px" }}>
        <div style={{ width: "50%" }}>
          <label>Matter Opened when</label>
          <input
            type="date"
            name="matterOpenDate"
            value={formData?.matterOpenDate}
            onChange={(e) => handleInputChange(e)}
          />
        </div>

        <div style={{ width: "50%" }}>
          <label>Disabled when</label>
          <input
            type="date"
            name="disableDate"
            value={formData?.disableDate}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        <div>
          <label>Status</label>
          <select
            name="status"
            value={formData?.status}
            onChange={handleInputChange}
            style={{ width: "100%" }}
          >
            <option value="enable">Enable</option>
            <option value="disable">Disable</option>
          </select>
        </div>
      </div>

      <div style={{ width: "100%" }}>
        {/* <label>Matter Address</label> */}
        <textarea
          name="matterAddress"
          placeholder="Matter Address"
          value={formData?.matterAddress}
          onChange={handleInputChange}
          style={{ width: "100%", height: "50px" }}
        />
      </div>

      <div>
        <label>Engagement Letter</label>
        <input
          type="file"
          accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msword"
          onChange={handleFileUpload}
          disabled={uploading}
        />

        {selectedFile.map((file, index) => (
          <div
            key={index}
            style={{
              marginTop: "20px",
              width: "100%",
              display: "flex",
              gap: "10px",
            }}
          >
            <CustomButton variant="outlined" width="30%">
              <Download style={{ marginRight: "8px" }} />
              <a
                href={file}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: COLORS.primary }}
              >
                Existing Engagement Letter
              </a>
            </CustomButton>
            <CustomButton
              width="30%"
              type="button"
              onClick={() => handleRemoveFile(index)}
            >
              Remove
            </CustomButton>
          </div>
        ))}
      </div>
    </CustomModal>
  );
};

export default Modal;
