import { clientConstants } from "../actions/constants";

const initState = {
  clientList: [],
  totalClients: 0,
  loading: false,
  error: null,
  message: "",
  addClientLoading: false,
};

const adminClientReducer = (state = initState, action) => {
  switch (action.type) {
    case clientConstants.GET_ALL_CLIENT_BY_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case clientConstants.GET_ALL_CLIENT_BY_ADMIN_SUCCESS:
      return {
        ...state,
        clientList: action.payload.clients,
        totalClients: action.payload.totalClients,
        loading: false,
      };
    case clientConstants.GET_ALL_CLIENT_BY_ADMIN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    case clientConstants.CREATE_NEW_CLIENT_REQUEST:
      return {
        ...state,
        addClientLoading: true,
      };
    case clientConstants.CREATE_NEW_CLIENT_SUCCESS:
      return {
        ...state,
        addClientLoading: false,
      };
    case clientConstants.CREATE_NEW_CLIENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        addClientLoading: false,
      };
    default:
      return state;
  }
};

export default adminClientReducer;
