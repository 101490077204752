import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import { ClipLoader } from "react-spinners";
// import { Box } from "@mui/material";

function Protected({ children, allowedRoles }) {
  // const [loading, setLoading] = useState(true);
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    // setLoading(true);
    if (!auth.user) {
      navigate("/", { replace: true });
      // setLoading(false);
    } else if (allowedRoles && !allowedRoles.includes(auth.user.role)) {
      navigate(
        `/${
          auth.user.role === "employee" ? "resource/addtime" : "adminDashboard"
        }`,
        { replace: true }
      );
      // setLoading(false);
    } else {
      // setLoading(false);
    }
  }, [auth, navigate, allowedRoles]);

  // if (loading) {
  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "100vh",
  //       }}
  //     >
  //       <ClipLoader size={150} color={"#123abc"} loading={loading} />
  //     </Box>
  //   );
  // }

  if (!auth.user || (allowedRoles && !allowedRoles.includes(auth.user.role))) {
    // console.log("Not authenticated")
    return null;
  }

  return children;
}

export default Protected;
