import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Tooltip,
  Chip,
  IconButton,
  Box,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Add, Edit, Delete } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import CustomButton from "../../components/ui/CustomButton";

const SuperAdminPortal = () => {
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [editingCompany, setEditingCompany] = useState(null);
  const [formData, setFormData] = useState({
    companyName: "",
    companyCode: "",
    backendUrl: "",
    adminEmail: "",
    userAccountLimit: 10,
    createdUsers: 0,
    status: "Inactive",
  });

  const fetchCompanies = async () => {
    try {
      const response = await axiosInstance.get("/super-admin/companies");
      setCompanies(response.data);
    } catch (error) {
      console.error("Failed to fetch companies:", error);
    }
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleOpen = (company = null) => {
    setEditingCompany(company);
    setFormData(
      company || {
        companyName: "",
        companyCode: "",
        backendUrl: "",
        adminEmail: "",
        userAccountLimit: 10,
        createdUsers: 0,
        status: "Inactive",
      }
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingCompany(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      if (editingCompany) {
        await axiosInstance.patch(
          `/super-admin/companies/${editingCompany._id}`,
          formData
        );
      } else {
        await axiosInstance.post("/super-admin/companies", formData);
      }
      fetchCompanies();
      handleClose();
    } catch (error) {
      console.error("Failed to save company:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      if (window.confirm("Are you sure you want to delete this company?")) {
        await axiosInstance.delete(`/super-admin/companies/${id}`);
        fetchCompanies();
      }
    } catch (error) {
      console.error("Failed to delete company:", error);
    }
  };

  return (
    <Box p={3}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4" gutterBottom>
          Super Admin Portal
        </Typography>
        <IconButton
          color="primary"
          onClick={() => handleOpen()}
          sx={{
            backgroundColor: "primary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "white",
              color: "primary.main",
              border: "1px solid black",
            },
          }}
        >
          <Add style={{ fontSize: "25px" }} />
        </IconButton>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Company Name</strong>
              </TableCell>
              <TableCell>
                <strong>Company Code</strong>
              </TableCell>
              <TableCell>
                <strong>Backend URL</strong>
              </TableCell>
              <TableCell>
                <strong>Admin Email</strong>
              </TableCell>
              <TableCell>
                <strong>User Limit</strong>
              </TableCell>
              <TableCell>
                <strong>Users Created</strong>
              </TableCell>
              <TableCell>
                <strong>Status</strong>
              </TableCell>
              <TableCell>
                <strong>Actions</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companies.map((company) => (
              <TableRow key={company._id}>
                <TableCell>{company.companyName}</TableCell>
                <TableCell>{company.companyCode}</TableCell>
                <TableCell>
                  <Tooltip title={company.backendUrl}>
                    <span>{company.backendUrl}</span>
                  </Tooltip>
                </TableCell>
                <TableCell>{company.adminEmail}</TableCell>
                <TableCell>{company.userAccountLimit}</TableCell>
                <TableCell>{company.createdUsers}</TableCell>
                <TableCell>
                  <Chip
                    label={company.status}
                    color={company.status === "Active" ? "success" : "default"}
                  />
                </TableCell>
                <TableCell>
                  <IconButton
                    color="primary"
                    onClick={() => handleOpen(company)}
                    title="Edit"
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    onClick={() => handleDelete(company._id)}
                    title="Delete"
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>
          {editingCompany ? "Edit Company" : "Add Company"}
        </DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Company Name"
            name="companyName"
            fullWidth
            value={formData.companyName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Company Code"
            name="companyCode"
            fullWidth
            value={formData.companyCode}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Backend URL"
            name="backendUrl"
            fullWidth
            value={formData.backendUrl}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Admin Email"
            name="adminEmail"
            fullWidth
            value={formData.adminEmail}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="User Account Limit"
            name="userAccountLimit"
            type="number"
            fullWidth
            value={formData.userAccountLimit}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Created Users"
            name="createdUsers"
            type="number"
            fullWidth
            value={formData.createdUsers}
            onChange={handleChange}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formData.status}
              onChange={handleChange}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions
          style={{
            width: "50%",
            display: "flex",
            marginLeft: "25%",
          }}
        >
          <CustomButton style={{ width: "100%" }} onClick={handleSubmit}>
            Save
          </CustomButton>
          <CustomButton onClick={handleClose} variant="outlined">
            Cancel
          </CustomButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SuperAdminPortal;
