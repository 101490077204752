import storage from './storage';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Helper function to display error messages using react-toastify
const showError = (title, message) => {
  toast.error(`${title}: ${message}`, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
  });
};

// Helper function to display success messages
const showSuccess = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,
  });
};

const fetchCompanyConfig = async (companyCode) => {
  try {
    const response = await fetch(
      `https://my-app-7fjx6.ondigitalocean.app/api/v1/api/companies/${companyCode.toLowerCase()}`
    );

    if (response.ok) {
      const config = await response.json();

      // Store configurations in localStorage without extra quotes
      storage.setItem('API_BASE_URL', config.backendUrl); // Assuming backendUrl is a string
      storage.setItem('COMPANY_CODE', companyCode.toLowerCase());
      storage.setItem('COMPANY_NAME', config.companyName);
      // Store other configs as needed

      showSuccess('Company configuration loaded successfully!');
      return true;
    } else {
      showError('Invalid Company Code', 'Please enter a valid company code.');
      return false;
    }
  } catch (error) {
    console.error('Error fetching company config:', error);
    showError('Error', 'Unable to retrieve company configuration.');
    return false;
  }
};

export default fetchCompanyConfig;
