import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import { Tooltip, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

//icons
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PeopleIcon from "@mui/icons-material/People";
import SickIcon from "@mui/icons-material/Sick";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import { HiUserGroup } from "react-icons/hi2";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { PropertySafetyFilled } from "@ant-design/icons";
import { Logout, Settings } from "@mui/icons-material";
import "./SideDrawer.css";
import { COLORS } from "../../constants/theme";

// Define drawer width
const drawerWidth = 150;

// Define the opened and closed mixins for drawer width
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

// Drawer and AppBar styles
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideDrawer({ children }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [open, setOpen] = React.useState(true); // Initially open the drawer

  const handleDrawerItemClick = (route) => {
    navigate(route);
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const adminDrawerItems = [
    {
      name: "Dashboard",
      route: "/adminDashboard",
      // route: "/admin/reports/financial",
      icon: <AssessmentIcon style={{ color: "black", fontSize: "20px" }} />,
    },
    // {
    //   name: "Admin Dashboard",
    //   route: "/adminDashboard",
    //   icon: <DashboardIcon style={{ color: "black", fontSize: "20px" }} />,
    // },
    {
      name: "Timespent",
      route: "/admin/reports/timespent",
      icon: <TimelapseIcon style={{ color: "black", fontSize: "20px" }} />,
    },
    {
      name: "Performance",
      route: "/admin/reports/performance",
      icon: <NetworkCheckIcon style={{ color: "black", fontSize: "20px" }} />,
    },
    {
      name: "TS Tracker",
      route: "/admin/reports/tstracker",
      icon: (
        <PropertySafetyFilled style={{ color: "black", fontSize: "20px" }} />
      ),
    },
    {
      name: "WriteOff",
      route: "/admin/reports/writeOff",
      icon: <TimerOffIcon style={{ color: "black", fontSize: "20px" }} />,
    },
    {
      name: "Invoices",
      route: "/admin/reports/invoice",
      icon: <MonetizationOnIcon style={{ color: "black", fontSize: "20px" }} />,
    },

    {
      name: "Matters",
      route: "/admin/showmatters",
      icon: <AccountTreeIcon style={{ color: "black", fontSize: "20px" }} />,
    },
    {
      name: "Resources",
      route: "/admin/allresources",
      icon: <PeopleIcon style={{ color: "black", fontSize: "20px" }} />,
    },
    {
      name: "Clients",
      route: "/admin/showclient",
      icon: (
        <HiUserGroup size={20} style={{ color: "black", fontSize: "20px" }} />
      ),
    },
    {
      name: "Expenses",
      route: "/admin/reports/ope",
      icon: <CreditScoreIcon style={{ color: "black", fontSize: "20px" }} />,
    },
    {
      name: "Leaves",
      route: "/admin/reports/leave",
      icon: <SickIcon style={{ color: "black", fontSize: "20px" }} />,
    },

    // {
    //   name: "Opex",
    //   route: "/admin/reports/opex",
    //   icon: (
    //     <ShoppingCartCheckoutIcon
    //       style={{ color: "black", fontSize: "20px" }}
    //     />
    //   ),
    // },
    {
      name: "Company Setup",
      route: "/admin/addnewcompany",
      icon: <Settings style={{ color: "black", fontSize: "20px" }} />,
    },
  ];

  const onLogOut = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    localStorage.removeItem("selectedComponent");
    dispatch({
      type: "LOGOUT_SUCCESS",
    });
    navigate("/");
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {/* Header */}
      <AppBar position="fixed" open={open} elevation={1}>
        <Toolbar
          sx={{
            justifyContent: "space-between",
            backgroundColor: COLORS.secondary,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ marginRight: 5 }}
          >
            {/* <MenuIcon /> */}
            {open ? (
              <ChevronLeftIcon style={{ fontSize: "30px" }} />
            ) : (
              <ChevronRightIcon style={{ fontSize: "30px" }} />
            )}
          </IconButton>

          <Typography variant="h6" color="black" noWrap component="div">
            Compüt Labs
          </Typography>

          <button
            onClick={onLogOut}
            className="font-semibold text-white bg-black px-4 py-2 rounded-lg"
          >
            <Logout className="mx-1" /> Logout
          </button>
        </Toolbar>
      </AppBar>

      {/* Side Drawer */}
      <Drawer variant="permanent" open={open}>
        {/* Admin name  */}
        <DrawerHeader
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 8px",
            textAlign: "center",
          }}
        >
          <Typography
            style={{
              fontSize: "14px",
              fontWeight: 700,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "100%",
            }}
          >
            {auth?.user?.fullName}
          </Typography>
          {/* <IconButton onClick={handleDrawerToggle}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon style={{ fontSize: "27px" }} />
            ) : (
              <ChevronLeftIcon style={{ fontSize: "27px" }} />
            )}
          </IconButton> */}
        </DrawerHeader>
        <Divider />
        <List>
          {adminDrawerItems.map((item) => (
            <ListItem key={item.route} disablePadding sx={{ display: "block" }}>
              <Tooltip title={item.name} placement="right">
                <ListItemButton
                  onClick={() => handleDrawerItemClick(item.route)}
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  {!open && (
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                  )}
                  {/* Show text only when drawer is open */}
                  <ListItemText
                    primary={item.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Layout */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
