import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { errorAlert, successAlert } from "../../utils/services";
import {
  Grid,
  TextField,
  Card,
  CardContent,
  CardMedia,
  Typography,
  IconButton,
} from "@mui/material";
import CustomButton from "../../components/ui/CustomButton";
import { UploadFile } from "@mui/icons-material";
import AddHolidays from "./AddHolidays";
import AddDesignations from "./AddDesignations";
import CustomTooltip from "../../components/ui/CustomTootltip";
import { CiEdit } from "react-icons/ci";
import axiosInstance from "../../helpers/axios";
import AddCurrencySettings from "./AddCurrencySettings";

const AddNewCompany = () => {
  const [editMode, setEditMode] = useState(false);
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyHREmail: "",
    companyAddress: "",
    companyLogo: null,
    companyWebsite: "",
    companyAccountsEmail: "",
    companyCorrespondentBankName: "",
    companyCorrespondentSwiftCode: "",
    companyIntermediaryBankClearingCode: "",
    companyIntermediaryAccountNumber: "",
    companyBankSwiftCode: "",
    companyABAFEDNumber: "",
    companyBeneficiaryName: "",
    companyBeneficiaryAccountNumber: "",
    companyBeneficiaryBankName: "",
    companyIFSCCode: "",
    companyGSTIN: "",
    companySAC: "",
    companyPAN: "",
    companyLUT: "",
  });

  const auth = useSelector((state) => state.auth);
  const adminId = auth.user._id;
  const [uploading, setUploading] = useState(false);

  const [errors, setErrors] = useState({
    companyName: "",
    companyEmail: "",
    companyHREmail: "",
    companyAddress: "",
    companyWebsite: "",
    companyAccountsEmail: "",
  });

  const validateFields = () => {
    let valid = true;
    const newErrors = {};

    if (!companyData.companyName.trim()) {
      newErrors.companyName = "Company Name is required.";
      valid = false;
    }
    if (
      !companyData.companyEmail.trim() ||
      !/\S+@\S+\.\S+/.test(companyData.companyEmail)
    ) {
      newErrors.companyEmail = "Valid Company Email is required.";
      valid = false;
    }
    if (
      !companyData.companyAccountsEmail.trim() ||
      !/\S+@\S+\.\S+/.test(companyData.companyAccountsEmail)
    ) {
      newErrors.companyAccountsEmail = "Valid Accounts Email is required.";
      valid = false;
    }
    if (
      !companyData.companyHREmail.trim() ||
      !/\S+@\S+\.\S+/.test(companyData.companyHREmail)
    ) {
      newErrors.companyHREmail = "Valid HR Email is required.";
      valid = false;
    }
    if (!companyData.companyAddress.trim()) {
      newErrors.companyAddress = "Company Address is required.";
      valid = false;
    }
    if (
      !companyData.companyWebsite.trim() ||
      !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(
        companyData.companyWebsite
      )
    ) {
      newErrors.companyWebsite = "Valid Company Website is required.";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleRemoveImage = () => {
    setCompanyData({
      ...companyData,
      companyLogo: null,
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file type (accept only image files)
    const validTypes = ["image/jpeg", "image/png", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      errorAlert("Please upload a valid image file (JPEG/PNG).");
      return;
    }

    // Check file size (example: max 150KB)
    const maxSize = 150 * 1024; // 150KB
    if (file.size > maxSize) {
      errorAlert("File size exceeds the limit of 150KB.");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("adminId", auth.user._id);

    try {
      setUploading(true);

      const response = await axiosInstance.post(`/uploadFile`, formData);

      console.log("Upload response:", response.data);

      if (response.data && response.data.url) {
        setCompanyData({
          ...companyData,
          companyLogo: response.data.url,
        });
        successAlert("Logo uploaded successfully!");
      } else {
        errorAlert(`Failed to upload image: ${response.data.message}`);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      errorAlert("Error uploading image.");
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        const response = await axiosInstance.get(`/company/details/${adminId}`);
        setCompanyData((prevState) => ({ ...prevState, ...response.data }));
      } catch (error) {
        console.error("Failed to fetch company details:", error);
      }
    };

    fetchCompanyDetails();
  }, [adminId]);

  const handleChange = (e) => {
    setCompanyData({ ...companyData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateFields()) {
      errorAlert("Please correct the highlighted errors.");
      return;
    }

    try {
      const result = await axiosInstance.post(
        `/company/details/${adminId}`,
        companyData
      );
      if (result) {
        successAlert("Company details updated successfully!");
      }
      setEditMode(false);
    } catch (error) {
      console.error("Error updating company details:", error);
      errorAlert("Failed to update company details.");
    }
  };

  return (
    <div>
      {editMode ? (
        <>
          <Typography variant="h5" marginBottom={2}>
            Update Company Details
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Name"
                  name="companyName"
                  value={companyData.companyName}
                  onChange={handleChange}
                  variant="outlined"
                  error={!!errors.companyName}
                  helperText={errors.companyName}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Email"
                  name="companyEmail"
                  type="email"
                  value={companyData.companyEmail}
                  onChange={handleChange}
                  variant="outlined"
                  error={!!errors.companyEmail}
                  helperText={errors.companyEmail}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Accounts Email"
                  name="companyAccountsEmail"
                  type="email"
                  value={companyData.companyAccountsEmail}
                  onChange={handleChange}
                  variant="outlined"
                  error={!!errors.companyAccountsEmail}
                  helperText={errors.companyAccountsEmail}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company HR Email"
                  name="companyHREmail"
                  type="email"
                  value={companyData.companyHREmail}
                  onChange={handleChange}
                  variant="outlined"
                  error={!!errors.companyHREmail}
                  helperText={errors.companyHREmail}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Website"
                  name="companyWebsite"
                  value={companyData.companyWebsite}
                  onChange={handleChange}
                  variant="outlined"
                  error={!!errors.companyWebsite}
                  helperText={errors.companyWebsite}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Company Address"
                  name="companyAddress"
                  value={companyData.companyAddress}
                  onChange={handleChange}
                  variant="outlined"
                  error={!!errors.companyAddress}
                  helperText={errors.companyAddress}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Correspondent Bank Name"
                  name="companyCorrespondentBankName"
                  value={companyData.companyCorrespondentBankName}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Correspondent Swift Code"
                  name="companyCorrespondentSwiftCode"
                  value={companyData.companyCorrespondentSwiftCode}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Intermediary Bank Clearing Code"
                  name="companyIntermediaryBankClearingCode"
                  value={companyData.companyIntermediaryBankClearingCode}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Intermediary Account Number"
                  name="companyIntermediaryAccountNumber"
                  value={companyData.companyIntermediaryAccountNumber}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Bank Swift Code"
                  name="companyBankSwiftCode"
                  value={companyData.companyBankSwiftCode}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="ABA/FED Number"
                  name="companyABAFEDNumber"
                  value={companyData.companyABAFEDNumber}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Beneficiary Name"
                  name="companyBeneficiaryName"
                  value={companyData.companyBeneficiaryName}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Beneficiary Account Number"
                  name="companyBeneficiaryAccountNumber"
                  value={companyData.companyBeneficiaryAccountNumber}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Beneficiary Bank Name"
                  name="companyBeneficiaryBankName"
                  value={companyData.companyBeneficiaryBankName}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="IFSC Code"
                  name="companyIFSCCode"
                  value={companyData.companyIFSCCode}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="GSTIN"
                  name="companyGSTIN"
                  value={companyData.companyGSTIN}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="SAC"
                  name="companySAC"
                  value={companyData.companySAC}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="PAN"
                  name="companyPAN"
                  value={companyData.companyPAN}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Letter of Undertaking Number"
                  name="companyLUT"
                  value={companyData.companyLUT}
                  onChange={handleChange}
                  variant="outlined"
                />
              </Grid>

              {/* buttons  */}
              <Grid item xs={12}>
                <input
                  type="file"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  id="companyLogo"
                />

                <CustomButton
                  variant="contained"
                  component="label" // This ensures the button opens the file dialog
                  disabled={uploading}
                >
                  <UploadFile style={{ marginRight: "8px" }} />
                  Upload Logo
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileUpload}
                    hidden
                  />
                </CustomButton>

                {/* <CustomButton
                  variant="contained"
                  component="span"
                  disabled={uploading}
                >
                  <UploadFile style={{ marginRight: "8px" }} />
                  Upload Logo
                </CustomButton> */}
              </Grid>
              {companyData.companyLogo && (
                <Grid item xs={12}>
                  <Card>
                    <CardMedia
                      component="img"
                      alt="Company Logo"
                      height="50"
                      style={{ objectFit: "contain", width: "25%" }}
                      image={companyData.companyLogo}
                    />
                  </Card>
                  <CustomButton
                    variant="outlined"
                    style={{ width: "20%", marginTop: "10px" }}
                    onClick={handleRemoveImage}
                  >
                    Remove Logo
                  </CustomButton>
                </Grid>
              )}
              <Grid item xs={12}>
                <CustomButton type="submit" variant="contained" color="primary">
                  Save
                </CustomButton>
                <CustomButton
                  variant="outlined"
                  onClick={() => setEditMode(false)}
                  style={{ marginLeft: "10px", width: "30%" }}
                >
                  Cancel
                </CustomButton>
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <Card elevation={0}>
          <CardContent>
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} className="flex gap-1">
                <Grid item>
                  <Typography variant="h5" align="left">
                    Company Details
                  </Typography>
                </Grid>

                <Grid item>
                  <CustomTooltip text="Add Time">
                    <IconButton
                      color="primary"
                      onClick={() => setEditMode(true)}
                    >
                      <CiEdit />
                    </IconButton>
                  </CustomTooltip>
                </Grid>
              </Grid>
              <Grid item xs={12} width="100%" maxHeight="80px">
                {companyData.companyLogo && (
                  <CustomTooltip text="Company Logo">
                    <CardMedia
                      component="img"
                      alt="Company Logo"
                      image={companyData.companyLogo}
                      title="Company Logo"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </CustomTooltip>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Name:</Typography>
                <Typography>{companyData.companyName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Email:</Typography>
                <Typography>{companyData.companyEmail}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Accounts Email:</Typography>
                <Typography>{companyData.companyAccountsEmail}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">HR Email:</Typography>
                <Typography>{companyData.companyHREmail}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Website:</Typography>
                <Typography>{companyData.companyWebsite}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Address:</Typography>
                <Typography>{companyData.companyAddress}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Correspondent Bank Name:
                </Typography>
                <Typography>
                  {companyData.companyCorrespondentBankName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Correspondent Swift Code:
                </Typography>
                <Typography>
                  {companyData.companyCorrespondentSwiftCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Intermediary Bank Clearing Code:
                </Typography>
                <Typography>
                  {companyData.companyIntermediaryBankClearingCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Intermediary Account Number:
                </Typography>
                <Typography>
                  {companyData.companyIntermediaryAccountNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Bank Swift Code:</Typography>
                <Typography>{companyData.companyBankSwiftCode}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">ABA/FED Number:</Typography>
                <Typography>{companyData.companyABAFEDNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Beneficiary Name:</Typography>
                <Typography>{companyData.companyBeneficiaryName}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">
                  Beneficiary Account Number:
                </Typography>
                <Typography>
                  {companyData.companyBeneficiaryAccountNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">Beneficiary Bank Name:</Typography>
                <Typography>
                  {companyData.companyBeneficiaryBankName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">IFSC Code:</Typography>
                <Typography>{companyData.companyIFSCCode}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">GSTIN:</Typography>
                <Typography>{companyData.companyGSTIN}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">SAC:</Typography>
                <Typography>{companyData.companySAC}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">PAN:</Typography>
                <Typography>{companyData.companyPAN}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body2">LUT:</Typography>
                <Typography>{companyData.companyLUT}</Typography>
              </Grid>
              <Grid item xs={12} md={12} display="flex" justifyContent="center">
                <CustomButton
                  style={{ width: "20%" }}
                  onClick={() => setEditMode(true)}
                >
                  Edit
                </CustomButton>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      <div style={{ marginTop: "20px" }}>
        <AddHolidays />
      </div>

      <div style={{ marginTop: "20px" }}>
        <AddDesignations />
      </div>

      <div style={{ marginTop: "20px" }}>
        <AddCurrencySettings />
      </div>
    </div>
  );
};

export default AddNewCompany;
