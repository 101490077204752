import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import formatDate, {
  ConfirmationAlert,
  customStyles,
  errorAlert,
  formatLetterDate,
  formatMonthDate,
  successAlert,
} from "../../utils/services";
import Select from "react-select";
import { BiDuplicate, BiSolidHide } from "react-icons/bi";
import { TbDiscount2Off } from "react-icons/tb";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Button,
  Typography,
  IconButton,
  Snackbar,
  TablePagination,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Discount, Download, Search, Update } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import "./Timespent.css";
import CustomButton from "../../components/ui/CustomButton";
import CustomTooltip from "../../components/ui/CustomTootltip";
import { useTSEmployees } from "../../helpers/hooks/useTSEmployees";
import NoDataFound from "../../components/NoDataFound";
import CustomDateRangePicker from "../../components/ui/CustomDateRangePicker";
import { IoMdEye } from "react-icons/io";
import CustomModal from "../../components/ui/CustomModal";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import { RiDeleteBin6Line } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import DiscountModal from "../../components/ui/DiscountModal";
import { fetchDesignations } from "../../store/slices/globalSlice";
import { fetchAllMatters } from "../../store/slices/matterSlice";
import { fetchEmployees } from "../../store/slices/employeesSlice";

const TimeSpent = () => {
  //constants
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { filteredEmployees } = useSelector((state) => state.employee);
  const { matters, filteredMatters } = useSelector((state) => state.matter);
  const { unBilledDesignations } = useSelector((state) => state.global);

  useEffect(() => {
    dispatch(fetchAllMatters());
    dispatch(fetchEmployees());
    dispatch(fetchDesignations());
  }, [dispatch]);

  const [filteredWorkHour, setFilteredWorkHour] = useState([]);
  const { employees } = useTSEmployees(filteredWorkHour);

  const [totalAmountForSelectedPeriod, setTotalAmountForSelectedPeriod] =
    useState("0");
  const [totalHoursForSelectedPeriod, setTotalHoursForSelectedPeriod] =
    useState("0");
  const [lastSentInvoiceDate, setLastSentInvoiceDate] = useState("");
  const [totalUnbilledAmount, setTotalUnbilledAmount] = useState("0");

  const uniqueNames = useMemo(() => {
    return employees
      .filter((item) => !unBilledDesignations.includes(item.designation))
      .map((item) => item.fullName);
  }, [employees, unBilledDesignations]);

  const filteredNames = useMemo(() => {
    return [
      ...new Set(
        filteredWorkHour && filteredWorkHour.map((item) => item?.user?.fullName)
      ),
    ];
  }, [filteredWorkHour]);

  const commonNames = useMemo(() => {
    return uniqueNames.filter((name) => filteredNames.includes(name)).sort();
  }, [uniqueNames, filteredNames]);

  let sendMail = false;
  const adminId = auth.user._id;

  //states
  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
  });

  const [client, setClient] = useState("");
  const [matter, setMatter] = useState(""); //matter id
  const [selectedMatterName, setSelectedMatterName] = useState("");
  const [selectedLawyer, setSelectedLawyer] = useState("");
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const today = new Date();
    const pastDate = new Date(today.setDate(today.getDate() - 2));
    return formatDate(pastDate);
  });
  const [endSelectedDate, setEndSelectedDate] = useState(
    formatDate(new Date())
  );
  const [showComment, setShowComment] = useState(true);
  const [workHour, setWorkHour] = useState(0);
  const [workMin, setWorkMin] = useState(30);
  const [workDescription, setWorkDescription] = useState("");
  const [comments, setComments] = useState("");
  const [entryId, setEntryId] = useState("");

  //add and edit time modal states
  const [showModal, setShowModal] = useState(false);
  const [editingTime, setEditingTime] = useState(null);
  const [date, setDate] = useState(formatDate(new Date()));
  const [modalClient, setModalClient] = useState("");
  const [modalMatter, setModalMatter] = useState(""); //matter id
  const [modalSelectedMatterName, setModalSelectedMatterName] = useState("");
  const [modalSelectedLawyerName, setModalSelectedLawyerName] = useState("");
  const [modalSelectedLawyerId, setModalSelectedLawyerId] = useState("");
  const [addressedComments, setAddressedComments] = useState({});
  const [advanceAmount, setAdvanceAmount] = useState(0);

  //pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const ope = 0;
  const needFin = false;
  const [totalCount, setTotalCount] = useState(0);

  //multiple selection entries states
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [newClient, setNewClient] = useState("");
  const [newMatter, setNewMatter] = useState(""); //matter id
  const [newSelectedMatterName, setNewSelectedMatterName] = useState("");

  const [singleDeleteSnackbarOpen, setSingleDeleteSnackbarOpen] =
    useState(false);
  const [multipleDeleteSnackbarOpen, setMultipleDeleteSnackbarOpen] =
    useState(false);
  const [singleWriteoffSnackbarOpen, setSingleWriteoffSnackbarOpen] =
    useState(false);
  const [multipleWriteoffSnackbarOpen, setMultipleWriteoffSnackbarOpen] =
    useState(false);

  // Store deleted and write-off items
  const [deletedItem, setDeletedItem] = useState(null); // For single delete
  const [deletedEntries, setDeletedEntries] = useState([]); // For multiple delete
  const [writeoffItem, setWriteoffItem] = useState(null); // For single write-off
  const [writeoffEntries, setWriteoffEntries] = useState([]); // For multiple write-off

  const [selectedItemWorkHours, setSelectedItemWorkHours] = useState("");

  //formatted date constants
  const formattedStartDate = formatLetterDate(startSelectedDate);
  const formattedEndDate = formatLetterDate(endSelectedDate);

  // states and functions for date range picker
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: "",
    end: "",
  });

  const [openDiscountModal, setOpenDiscountModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const link1Text = companyData.companyAccountsEmail;
  const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  const link2Text = companyData.companyWebsite;
  const link2URL = companyData.companyWebsite;

  const columns = [
    "Date",
    "Associate",
    // "Matter",
    "Description",
    "Time",
    // "Discount hrs",
    "Edit",
    "Disc",
    "W/O",
    "Del",
    "Duplicate",
  ];

  // Function to receive selected dates from DateRangePickerTst
  const handleDateRangeChange = (startDate, endDate) => {
    setSelectedDateRange({ start: startDate, end: endDate });
    setStartSelectedDate(startDate);
    setEndSelectedDate(endDate);
    console.log(startDate, endDate);
  };

  //pagination functions
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchCompanyDetails = useCallback(async () => {
    try {
      const { data } = await axiosInstance.get(`/company/details/${adminId}`);

      setCompanyData((prevData) => ({
        ...prevData,
        companyName: data.companyName || "",
        companyEmail: data.companyEmail || "",
        companyAddress: data.companyAddress || "",
        companyLogo: data.companyLogo || "",
        companyWebsite: data.companyWebsite || "",
        companyAccountsEmail: data.companyAccountsEmail || "",
      }));
    } catch (error) {
      errorAlert("Error in fetching company details!!");
    }
  }, [adminId, setCompanyData]);

  const filterBetweenDates = useCallback(async () => {
    if (matter === "") {
      errorAlert("Select the matter");
      return;
    }

    try {
      const { data } = await axiosInstance.get(
        `/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId: matter,
            needFin,
            sendMail,
            adminId,
            limit: rowsPerPage,
            page: page,
          },
        }
      );

      setFilteredWorkHour(data.workHours);
      setTotalCount(data.totalFilteredWorkHours);
      setTotalAmountForSelectedPeriod(data.totalAmount);
      setTotalHoursForSelectedPeriod(data.totalHours);
      setTotalUnbilledAmount(data.unbilledAmount);
      setLastSentInvoiceDate(data.lastInvoiceDate);
    } catch (error) {
      console.log(error);
      errorAlert("Error fetching filtered work hours");
    }
  }, [
    matter,
    startSelectedDate,
    selectedLawyer,
    endSelectedDate,
    needFin,
    sendMail,
    adminId,
    rowsPerPage,
    page,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchCompanyDetails();
      } catch (error) {
        console.error("Error fetching company details:", error);
      }
    };

    fetchData();
  }, [fetchCompanyDetails]);

  // Only trigger `filterBetweenDates` based on `page`, `rowsPerPage`, or date range
  useEffect(() => {
    if (startSelectedDate && endSelectedDate && matter) {
      filterBetweenDates();
    }
  }, [
    startSelectedDate,
    endSelectedDate,
    matter,
    page,
    rowsPerPage,
    filterBetweenDates,
  ]);

  let currency;
  const matterCurr = matters.find((m) => m._id === matter);
  if (matterCurr) {
    currency = matterCurr.currency + " ";
  } else {
    currency = "";
  }

  const onhandleApplyDiscount = async (entryId, discountValue) => {
    if (discountValue !== undefined) {
      try {
        const response = await axiosInstance.put(
          `/workhour/admin/updateWithDiscount`,
          { entryId, discountedWorkHours: discountValue }
        );

        // Check if the update was successful
        if (response.data) {
          // Update the state after applying discount
          setFilteredWorkHour((prevFilteredWorkHour) => {
            const updatedWorkHour = prevFilteredWorkHour.map((entry) =>
              entry._id === entryId
                ? { ...entry, workHour: response.data.workHourEntry.workHour }
                : entry
            );
            return updatedWorkHour;
          });

          successAlert(response.data.message);
        } else {
          // Handle update failure
          errorAlert("Applying discount failed", "Please try again...");
        }
      } catch (error) {
        // Handle other errors
        console.error("Error applying discount:", error);
        errorAlert(
          (error.message && error.message) || "Applying discount failed",
          "Please try again..."
        );
      }
    }
  };

  //Download TS
  const generateTimeSpent = async () => {
    try {
      // successAlert("Fetching data for the report, please wait...");

      // Fetch all workhours for the selected filters
      const { data } = await axiosInstance.get(
        `/workHour/filterWorkHourByUserId`,
        {
          params: {
            user: selectedLawyer,
            fromDate: startSelectedDate,
            toDate: endSelectedDate,
            matterId: matter,
            adminId,
            limit: 0, // Fetch all data without pagination
          },
        }
      );

      if (!data || data.workHours.length === 0) {
        errorAlert("No data available to generate the report.");
        return;
      }

      const allWorkHours = data.workHours;

      // Prepare table data
      const doubleUsedTableColumns = [
        "Associate Name",
        "Total Time",
        "Billed Time",
        "Hourly Rate",
        "Total Amount",
      ];

      const doubleUsedTableData = [];
      let teamTotalBilledAmount = 0;
      let persontotalHours = 0;

      // Populate the table rows
      commonNames.forEach((name) => {
        let personWorkHours = 0;

        const filteredData = allWorkHours.filter(
          (result) => result?.user?.fullName === name
        );

        filteredData.forEach((result) => {
          personWorkHours += parseFloat(result.workHour);
        });

        persontotalHours = parseFloat(personWorkHours);

        const newMatter = matters.find(
          (matter) => matter.name.trim() === selectedMatterName.trim()
        );

        let price = 0;
        if (newMatter) {
          if (newMatter.pricePerHour) {
            price = newMatter.pricePerHour.$numberDecimal;
          } else if (newMatter.resourceSpecificPrice.length !== 0) {
            const employeeEntry = newMatter.resourceSpecificPrice.find(
              (entry) => entry.includes(name)
            );

            if (employeeEntry) {
              const [, hours] = employeeEntry.split(":");
              price = hours.trim();
            }
          }
        }

        if (price && persontotalHours > 0) {
          let personTotalBilledAmount = price * personWorkHours;

          const rowData = [
            name,
            persontotalHours.toFixed(2),
            personWorkHours.toFixed(2),
            currency + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            currency +
              personTotalBilledAmount
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          ];
          doubleUsedTableData.push(rowData);
          teamTotalBilledAmount += personTotalBilledAmount;
        }
      });

      // Add footer row
      const footerRow = [
        {
          content: "Total Hours",
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: parseFloat(totalHoursForSelectedPeriod).toFixed(2),
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: parseFloat(totalHoursForSelectedPeriod).toFixed(2),
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content: "",
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
        {
          content:
            currency +
            teamTotalBilledAmount
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          styles: {
            fillColor: [97, 68, 58],
            textColor: [255, 255, 255],
            fontStyle: "bold",
          },
        },
      ];
      doubleUsedTableData.push(footerRow);

      // Generate the PDF
      const doc = new jsPDF();

      const pageWidth = doc.internal.pageSize.getWidth();
      const rightMargin = 15;
      const logoDesiredHeight = 20;
      const logoYPosition = 10;
      const logoXPosition = 13;

      function addHeader() {
        // Set the font style, size, and position of the header text
        const fontSize = 8;

        doc.setFont("helvetica", "normal");
        doc.setFontSize(fontSize);

        // Add the image to the header
        // doc.addImage(companyData.companyLogo, "PNG", 15, 10, 70, 9);
        doc.addImage(
          companyData.companyLogo,
          "PNG",
          logoXPosition,
          logoYPosition,
          0,
          logoDesiredHeight
        );

        // right header
        doc.setTextColor(46, 115, 176);

        const link2Width = doc.getTextWidth(link2Text);
        const link2X = pageWidth - link2Width - rightMargin;
        doc.textWithLink(link2Text, link2X, 19, {
          url: link2URL,
          underline: true,
        });

        // Set link color to blue and add underline
        const link1Width = doc.getTextWidth(link1Text);
        const link1X = pageWidth - link1Width - rightMargin;
        doc.textWithLink(link1Text, link1X, 22, {
          url: link1URL,
          underline: true,
        });

        doc.setTextColor(0);

        // Calculate text width and position to align it right
        const addressWidth = doc.getTextWidth(companyData.companyAddress);
        const addressX = pageWidth - addressWidth - rightMargin;
        doc.text(companyData.companyAddress, addressX, 26);

        // Reset text color to black
      }

      const Timespent = "/assets/Timespent.jpg";

      doc.addImage(Timespent, "JPEG", 0, 0, 210, 297);
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      const fontSize = 20;
      doc.setFontSize(fontSize);

      //center alignment width calculations
      const clientNameLength = (210 - doc.getTextWidth(client)) / 2;
      const matterNameLength = (210 - doc.getTextWidth(selectedMatterName)) / 2;

      const dateRangeLength =
        (210 -
          doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
        2;

      //client name
      doc.setFont("helvetica", "bold");
      doc.setTextColor(136, 106, 71);
      doc.setFontSize(20);
      doc.text(client, clientNameLength, 120);

      //matter name
      doc.setFontSize(20);
      doc.setFont("helvetica", "normal");
      doc.text(selectedMatterName, matterNameLength, 130);

      //horizontal line
      doc.setLineWidth(0.3);
      doc.setDrawColor(136, 106, 71);
      doc.line(40, 145, 180, 145);

      //date range
      doc.setFont("helvetica", "italic");
      doc.setTextColor(0, 0, 0); // Black color
      doc.setFontSize(15);
      doc.text(
        `${formattedStartDate} - ${formattedEndDate}`,
        dateRangeLength + 10,
        160
      );

      // ----------------2nd Page --------------------

      doc.addPage();
      addHeader();
      doc.setFontSize(10);
      doc.setFont("helvetica", "normal");

      doc.text("Dear Sir / Ma'am", 15, 40);
      doc.setFont("helvetica", "bold");
      doc.setTextColor(136, 106, 71);
      doc.text(
        `${client} - ${selectedMatterName} From Period ${formatMonthDate(
          startSelectedDate
        )} To ${formatMonthDate(endSelectedDate)}`,
        14,
        50
      );
      doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "normal");
      doc.text(
        "Please find enclosed our memorandum of time-spent on the given matter for the said period.",
        15,
        60
      );

      //---------------------------------Table 1 Duplicate Table Page 2----------------------------
      //Table 1 Page 2 Data
      // ----------------------------------Main Table declaration ------------------------

      const startY = 70; // Starting Y-coordinate for the table

      doc.autoTable(doubleUsedTableColumns, doubleUsedTableData, {
        startY,
        theme: "grid",
        tableLineColor: [0, 0, 0],
        tableLineWidth: 0.1,

        didParseCell: function (data) {
          if (data.row.index === 0 && data.row.section === "head") {
            // Header row styles
            data.cell.styles.fillColor = [136, 106, 71];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.halign = "left"; // Align header text to the left
            data.cell.styles.fontStyle = "bold";
            data.cell.styles.fontSize = 9;
          } else if (data.row.section === "body") {
            // Body row styles
            if (data.column.index !== 0) {
              // Right-align all columns except the first column
              data.cell.styles.halign = "right";
            }
          } else if (data.row.index === doubleUsedTableData.length - 1) {
            // Footer row align
            data.cell.styles.halign = "right"; // Align footer text to the right
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = "bold";
          } else if (
            data.row.index === doubleUsedTableData.length - 1 &&
            data.row.section === "foot"
          ) {
            // Footer row styles
            data.cell.styles.fillColor = [226, 213, 203];
            data.cell.styles.textColor = [255, 255, 255];
          }

          // Set padding for table data cells
          if (
            data.row.section === "body" &&
            data.column.index === 0 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 1 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 2 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 3 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
          if (
            data.row.section === "body" &&
            data.column.index === 4 &&
            data.row.index !== -1 &&
            data.row.index !== doubleUsedTableData.length - 1
          ) {
            data.cell.styles.fontSize = 9;
            data.cell.styles.fontStyle = "arial";
            data.cell.styles.textColor = [0, 0, 0];
          }
        },
      });

      // Calculate the table height
      const tableHeight = doc.autoTable.previous.finalY - startY;

      doc.text(
        "Summary of payable on this matter is provided below ",
        15,
        startY + tableHeight + 10
      );

      doc.setFont("helvetica", "bold");
      doc.setTextColor(136, 106, 71);
      doc.text(
        "Total Amount for the above period",
        15,
        startY + tableHeight + 20
      );
      doc.text(
        currency +
          (parseFloat(ope) + parseFloat(teamTotalBilledAmount))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        doc.internal.pageSize.getWidth() -
          doc.getTextWidth(
            currency +
              (parseFloat(ope) + parseFloat(teamTotalBilledAmount))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 20
      );

      doc.text(
        advanceAmount > 0
          ? "Total timespent till date"
          : `Unbilled amount since last invoice (${
              lastSentInvoiceDate !== undefined
                ? formatLetterDate(lastSentInvoiceDate)
                : "no invoices sent yet"
            })`,
        15,
        startY + tableHeight + 28
      );
      doc.text(
        currency +
          (parseFloat(ope) + parseFloat(totalUnbilledAmount))
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        doc.internal.pageSize.getWidth() -
          doc.getTextWidth(
            currency +
              (parseFloat(ope) + parseFloat(totalUnbilledAmount))
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          ) -
          15,
        startY + tableHeight + 28
      );

      if (advanceAmount > 0) {
        if (advanceAmount > totalUnbilledAmount) {
          doc.text("Advance received", 15, startY + tableHeight + 36);
          doc.text(
            currency +
              parseFloat(advanceAmount)
                .toFixed(2)
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            doc.internal.pageSize.getWidth() -
              doc.getTextWidth(
                currency +
                  parseFloat(advanceAmount)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              ) -
              15,
            startY + tableHeight + 36
          );
        }

        if (advanceAmount < totalUnbilledAmount) {
          doc.text("Amount payable", 15, startY + tableHeight + 36);
          doc.text(
            parseFloat(totalUnbilledAmount - advanceAmount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            doc.internal.pageSize.getWidth() -
              doc.getTextWidth(
                parseFloat(totalUnbilledAmount - advanceAmount)
                  .toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              ) -
              15,
            startY + tableHeight + 36
          );
        }

        let amountText;
        if (advanceAmount > totalUnbilledAmount) {
          // If the condition is true, calculate the amount
          amountText =
            currency +
            parseFloat(advanceAmount - totalUnbilledAmount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          // If the condition is false, set "NIL" directly
          amountText = "NIL";
        }

        doc.text("Unutilized amount", 15, startY + tableHeight + 44);
        doc.text(
          amountText,
          doc.internal.pageSize.getWidth() - doc.getTextWidth(amountText) - 15,
          startY + tableHeight + 44
        );
      }

      doc.setTextColor(0, 0, 0);
      doc.setFont("helvetica", "normal");

      doc.text(
        "Please do let us know if any changes are required. Please note that this is only a statement of timespent for ",
        15,
        startY + tableHeight + (advanceAmount > 0 ? 55 : 40)
      );
      doc.text(
        "your reference and not an invoice. The invoice shall be raised subsequently in the manner agreed with you.",
        15,
        startY + tableHeight + (advanceAmount > 0 ? 60 : 45)
      );

      doc.text(
        "Yours Sincerely,",
        15,
        startY + tableHeight + (advanceAmount > 0 ? 70 : 55)
      );
      doc.setFont("helvetica", "bold");
      doc.text(
        auth.user.fullName,
        15,
        startY + tableHeight + (advanceAmount > 0 ? 75 : 60)
      );
      doc.setFont("helvetica", "normal");

      // --------------------------------------3rd Page----------------------------------------------------
      doc.addPage();
      addHeader();

      let startY1 = 35; // Starting y-coordinate for each table

      commonNames.forEach((name, index) => {
        const personData = filteredWorkHour
          .filter(
            (result) =>
              result.user?.fullName === name &&
              result.workDate >= startSelectedDate
          )
          .sort((a, b) => new Date(a.workDate) - new Date(b.workDate)); // Sort the data by workDate in ascending order

        const billedTime = personData.reduce(
          (total, result) => total + parseFloat(result.workHour),
          0
        );

        const headerRow = [
          {
            content: name,
            colSpan: 2,
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
          {
            content: "Total Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },

          {
            content: "Billed Time",
            styles: {
              cellWidth: "auto", // Set cell width to auto to allow content alignment
              halign: "left",
              fillColor: [136, 106, 71],
              textColor: [255, 255, 255],
              fontStyle: "bold", // Set the font style to bold
            },
          },
        ];

        const tableRows = [headerRow]; // Initialize tableRows with the header row
        let commentsText = " ";

        // Add individual data rows for the person
        if (personData.length > 0) {
          personData.forEach((result) => {
            commentsText = result.comments || " ";

            const data = [
              formatMonthDate(result.workDate),
              showComment === true && commentsText !== " "
                ? `${result.workDescription}

-----------------------------------------------------------------------------------------------------------
||      ${commentsText}  ||`
                : result.workDescription,
              parseFloat(result.workHour).toFixed(2),
              parseFloat(result.workHour).toFixed(2),
            ];
            tableRows.push(data);
          });

          // Add footer row with total time
          const footerRow = [
            {
              content: "Total Time",
              colSpan: 2,
              styles: {
                halign: "left",
                fillColor: [97, 68, 58],
                textColor: [255, 255, 255],
                fontStyle: "bold",
              },
            },
            {
              content: billedTime.toFixed(2),
              styles: {
                halign: "right",
                fillColor: [97, 68, 58],
                textColor: [255, 255, 255],
                fontStyle: "bold",
              },
            },
            {
              content: billedTime.toFixed(2),
              styles: {
                halign: "right",
                fillColor: [97, 68, 58],
                textColor: [255, 255, 255],
                fontStyle: "bold",
              },
            },
          ];
          tableRows.push(footerRow);

          // Remove empty rows and columns from tableRows
          const filteredTableRows = tableRows.filter((row) =>
            row.some((cell) => cell !== "")
          );

          // Generate table for the current person

          doc.autoTable({
            body: filteredTableRows,
            startY: startY1,
            theme: "grid",
            tableLineColor: [0, 0, 0],
            tableLineWidth: 0.1,
            didParseCell: function (data) {
              if (data.row.index === 0 && data.row.section === "head") {
                // Header row styles
                data.cell.styles.fillColor = [136, 106, 71];
                data.cell.styles.textColor = [255, 255, 255];
                data.cell.styles.halign = "left";
                data.cell.styles.valign = "left"; // Align the text vertically at the center
                data.cell.styles.fontStyle = "bold"; // Set the font style to bold
              } else if (
                data.row.index === filteredTableRows.length - 1 &&
                data.row.section === "body"
              ) {
                // Footer row styles
                data.cell.styles.fillColor = [97, 68, 58];
                data.cell.styles.textColor = [255, 255, 255];
                // data.cell.styles.halign = "right";
                data.cell.styles.fontStyle = "bold";
              } else if (data.row.section === "body") {
                // Body row styles
                if (data.column.index !== 1) {
                  // Right-align all columns except the first column
                  data.cell.styles.halign = "left";
                }
              }
              // Set padding for table data cells
              if (
                data.row.section === "body" &&
                data.column.index === 0 &&
                data.row.index !== 0 &&
                data.row.index !== tableRows.length - 1
              ) {
                data.cell.styles.cellPadding = {
                  top: 3,
                  right: 2,
                  bottom: 3,
                  left: 3,
                };
                data.cell.styles.fontSize = 9;
                data.cell.styles.halign = "left";
                data.cell.styles.cellWidth = 20;
                data.cell.styles.textColor = [0, 0, 0];
              }
              if (
                data.row.section === "body" &&
                data.column.index === 1 &&
                data.row.index !== 0 &&
                data.row.index !== tableRows.length - 1
              ) {
                data.cell.styles.cellPadding = {
                  top: 3,
                  right: 4,
                  bottom: 3,
                  left: 4,
                };
                data.cell.styles.fontSize = 9;
                data.cell.styles.fontStyle = "times";
                data.cell.styles.halign = "left";
                data.cell.styles.cellWidth = 122; // Set the width to 100 units
                data.cell.styles.textColor = [0, 0, 0];
              }

              if (data.row.section === "body" && data.column.index === 1) {
                // Check if the cell contains the separator line indicating comments
                const cellText = data.cell.text;
                const hasComments = cellText.includes(
                  "-----------------------------------------------------------------------------------------------------------"
                );

                if (hasComments) {
                  data.cell.styles.fillColor = [215, 230, 230];
                }
              }

              if (
                data.row.section === "body" &&
                data.column.index === 2 &&
                data.row.index !== 0 &&
                data.row.index !== tableRows.length - 1
              ) {
                data.cell.styles.cellPadding = {
                  top: 3,
                  right: 2,
                  bottom: 3,
                  left: 2,
                };
                data.cell.styles.halign = "right";
                data.cell.styles.fontSize = 9;
                data.cell.styles.cellWidth = 20;
                data.cell.styles.textColor = [0, 0, 0];
              }
              if (
                data.row.section === "body" &&
                data.column.index === 3 &&
                data.row.index !== 0 &&
                data.row.index !== tableRows.length - 1
              ) {
                data.cell.styles.cellPadding = {
                  top: 3,
                  right: 2,
                  bottom: 3,
                  left: 2,
                };
                data.cell.styles.halign = "right";
                data.cell.styles.fontSize = 9;
                data.cell.styles.cellWidth = 20;
                data.cell.styles.textColor = [0, 0, 0];
              }
            },
          });
          startY1 = doc.lastAutoTable.finalY + 10; // Update startY for the next table
        }
      });

      doc.save(
        `${client} - ${selectedMatterName} From Period ${formatMonthDate(
          startSelectedDate
        )} To ${formatMonthDate(endSelectedDate)}.pdf`
      );
      successAlert("Timespent downloaded successfully.");
    } catch (error) {
      console.error("Error generating the report:", error);
      errorAlert("Failed to generate the report. Please try again.");
    }
  };

  const openModal = (time, type) => {
    setShowModal(true);

    if (type === "duplicate") {
      setEditingTime(null);
      setDate(time.workDate);
      setWorkDescription(time.workDescription);
      setModalSelectedLawyerName(time.user.fullName);
      setModalSelectedLawyerId(time.user._id);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    } else if (type === "add") {
      setEditingTime(null);
      setDate(formatDate(new Date()));
      setWorkDescription("");
      setModalSelectedLawyerId("");
      setModalSelectedLawyerName("");
      setModalMatter("");
      setModalSelectedMatterName("");
      setComments("");
      setWorkHour(0);
      setWorkMin(30);
    } else {
      setEditingTime(time);
      setDate(time.workDate);
      setEntryId(time._id);
      setWorkDescription(time.workDescription);
      setModalSelectedLawyerName(time.user.fullName);
      setModalSelectedLawyerId(time.user._id);
      // setUserId(time.user._id);
      setModalMatter(time.matter);
      setModalSelectedMatterName(time.matter.name);
      setComments(time.comments);
      const hour = time.workHour.split(".");
      setWorkHour(hour[0]);
      setWorkMin(Math.floor((hour[1] * 6) / 10));
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setDate(formatDate(new Date()));
    setWorkDescription("");
    // setUserId("");
    setModalSelectedLawyerName("");
    setModalSelectedLawyerId("");
    setModalMatter("");
    setModalSelectedMatterName("");
    setModalClient("");
    setComments("");
    setWorkHour(0);
    setWorkMin(30);
    setEntryId("");
  };

  //Form Validation
  const isValidObjField = () => {
    if (
      date === "" ||
      modalSelectedLawyerId === "" ||
      modalMatter === "" ||
      workDescription === "" ||
      workHour === "00" ||
      workMin === "00"
    ) {
      return false;
    } else {
      return true;
    }
    // return Object.values(obj).every(value => value.trim())
  };

  const isValidForm = () => {
    //all fields must be filled
    if (!isValidObjField()) return errorAlert("All Fields are Required!");

    return true;
  };

  //Add, Duplicate and Edit Time
  const handleSubmit = async (e) => {
    e.preventDefault();

    const time = {
      admin: auth.user._id,
      user: modalSelectedLawyerId,
      workDate: date,
      matter: modalMatter,
      workDescription,
      comments,
      workHour: `${workHour}.${workMin * (10 / 6)}`,
      reviewed: "true",
    };

    if (isValidForm()) {
      if (editingTime) {
        const response = await axiosInstance.put(
          `/workHour/update/${entryId}`,
          time
        );

        if (response.data) {
          successAlert("Time Updated Successfully!");
          setFilteredWorkHour((prevFilteredWorkHour) => {
            const updatedWorkHour = prevFilteredWorkHour.map((entry) =>
              entry._id === entryId ? response.data : entry
            );
            return updatedWorkHour;
          });
          closeModal();
          filterBetweenDates();
        }
      } else {
        try {
          const response = await axiosInstance.post(
            `/workHour/createNew`,
            time
          );

          if (response.data) {
            successAlert("Great!! Time Added Successfully.");

            // Call filterBetweenDates after adding time
            setFilteredWorkHour((prevFilteredWorkHour) => {
              // Include the new entry in the updated state
              const updatedWorkHour = [...prevFilteredWorkHour, response.data];
              return updatedWorkHour;
            });

            closeModal();
          }
        } catch (error) {
          console.error("Error adding time:", error);
          errorAlert("Error adding time");
        }
      }
    }
  };

  //address comment function
  const markCommentAsAddressed = (entryId) => {
    setAddressedComments((prev) => ({
      ...prev,
      [entryId]: !prev[entryId], // Toggle the addressed state
    }));
  };

  //mupltiple select entries functions
  const handleSelectEntry = (id, checked) => {
    if (checked) {
      setSelectedEntries([...selectedEntries, id]);
    } else {
      setSelectedEntries(selectedEntries.filter((entryId) => entryId !== id));
    }
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);

    // If "Select All" checkbox is checked, set all entry IDs as selected; otherwise, clear selection
    setSelectedEntries(
      isChecked
        ? filteredWorkHour
            .filter((entry) => entry.workDate >= startSelectedDate)
            .map((entry) => entry._id)
        : []
    );
  };

  const handleUpdateEntries = () => {
    // Send Axios request to backend to update selected entries with new matter code

    if (newMatter) {
      handleUpdateEntriesAlert();
    } else {
      errorAlert("Select the new matter");
    }
  };

  const handleUpdateEntriesAlert = (id) => {
    toast(
      <ConfirmationAlert
        onDeleteOff={() => onUpdateMultipleEntries(id)}
        question={"Are you sure to update matter of all selected entries?"}
        answer={"Yes, Update Matter"}
        icon={<Update />}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        closeButton: true,
        autoClose: false,
        draggable: false,
      }
    );
  };

  const onUpdateMultipleEntries = async (entryId) => {
    axiosInstance
      .post("/updateMatterCodeEntries", {
        entries: selectedEntries,
        newMatter,
      })
      .then((response) => {
        // Handle success response
        filterBetweenDates();
      })
      .catch((error) => {
        // Handle error
        console.error("Error updating entries:", error);
      });
  };

  const handleWriteoffSingle = async (entryId) => {
    try {
      const response = await axiosInstance.delete(
        `/workhour/admin/delete/${entryId}`
      );

      if (response.data) {
        successAlert("Entry written off successfully");

        console.log("data", response.data);

        // Store the single write-off entry for undo
        setWriteoffItem(response.data?.updatedWorkHour?._id);
        setSingleWriteoffSnackbarOpen(true);

        // Remove the entry from the UI after write-off
        setFilteredWorkHour((prev) =>
          prev.filter((entry) => entry._id !== entryId)
        );
      } else {
        errorAlert("Failed to write off entry.");
      }
    } catch (error) {
      errorAlert("An error occurred while writing off the entry.");
    }
  };

  const handleUndoWriteoffSingle = async () => {
    try {
      if (writeoffItem) {
        const response = await axiosInstance.post(
          `/workhour/restorefromwriteoff/${writeoffItem}`
        );

        if (response.data) {
          successAlert("Entry write-off undone successfully");

          // Restore the entry in the UI
          setFilteredWorkHour((prev) => [...prev, writeoffItem]);
          setWriteoffItem(null);
          setSingleWriteoffSnackbarOpen(false);
          filterBetweenDates();
        } else {
          errorAlert("Failed to restore the write-off.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while undoing the write-off.");
    }
  };

  const handleWriteoffMultiple = async () => {
    if (selectedEntries.length === 0) {
      errorAlert("No entries selected for write-off");
      return;
    }

    try {
      // Send delete requests for each selected entry
      const writeOffPromises = selectedEntries.map((entryId) =>
        axiosInstance.delete(`/workhour/admin/delete/${entryId}`)
      );

      // Await the responses for all requests
      const responses = await Promise.all(writeOffPromises);

      successAlert("Selected entries written off");

      // Extract the created write-off entries from the responses
      const writeOffData = responses.map(
        (response) => response.data?.updatedWorkHour
      );

      // Store the write-off entries for undo
      setWriteoffEntries(writeOffData);

      // Remove the written-off entries from the UI
      setFilteredWorkHour((prev) =>
        prev.filter((entry) => !selectedEntries.includes(entry._id))
      );

      setMultipleWriteoffSnackbarOpen(true);

      // Clear selected entries
      setSelectedEntries([]);
      setSelectAll(false);
    } catch (error) {
      errorAlert("Failed to write off selected entries.");
      console.error("Error during multiple write-off:", error);
    }
  };

  const handleUndoWriteoffMultiple = async () => {
    try {
      const restorePromises = writeoffEntries.map((entry) =>
        axiosInstance.post(`/workhour/restorefromwriteoff/${entry._id}`)
      );

      // Wait for all restore operations to complete
      await Promise.all(restorePromises);

      successAlert("Selected write-offs undone successfully");

      // Restore the write-off entries in the UI
      setFilteredWorkHour((prev) => [...prev, ...writeoffEntries]);

      // Clear write-off entries
      setWriteoffEntries([]);
      setMultipleWriteoffSnackbarOpen(false);
      filterBetweenDates();
    } catch (error) {
      errorAlert("Failed to undo write-off for selected entries.");
      console.error("Error during undoing multiple write-offs:", error);
    }
  };

  const handleSoftDeleteMultiple = async () => {
    if (selectedEntries.length === 0) {
      errorAlert("No entries selected for deletion");
      return;
    }

    try {
      const deletePromises = selectedEntries.map((entryId) => {
        return axiosInstance.delete(`/workhour/softdelete/${entryId}`);
      });

      await Promise.all(deletePromises);

      successAlert("Selected entries deleted");

      // Store the deleted entries for undo
      const deletedData = filteredWorkHour.filter((entry) =>
        selectedEntries.includes(entry._id)
      );
      setDeletedEntries(deletedData);

      // Update the UI
      setFilteredWorkHour((prev) =>
        prev.filter((entry) => !selectedEntries.includes(entry._id))
      );
      setMultipleDeleteSnackbarOpen(true);
      setSelectedEntries([]); // Clear selected entries
      setSelectAll(false); // Uncheck the select all checkbox
    } catch (error) {
      errorAlert("Failed to delete selected entries.");
    }
  };

  const handleUndoMultiple = async () => {
    try {
      const restorePromises = deletedEntries.map((entry) => {
        return axiosInstance.post(`/workhour/restore/${entry._id}`);
      });

      await Promise.all(restorePromises);

      successAlert("Selected entries restored successfully");

      // Restore entries in the UI
      setFilteredWorkHour((prev) => [...prev, ...deletedEntries]);
      setDeletedEntries([]);
      setMultipleDeleteSnackbarOpen(false);
    } catch (error) {
      errorAlert("Failed to restore selected entries.");
    }
  };

  const handleSoftDeleteSingle = async (entryId) => {
    try {
      const response = await axiosInstance.delete(
        `/workhour/softdelete/${entryId}`
      );

      if (response.data && response.data.deletedWorkhour) {
        successAlert("Entry deleted successfully");

        // Store the single deleted item for undo
        setDeletedItem({ entryId, ...response.data });
        setSingleDeleteSnackbarOpen(true);

        // Update UI after deletion
        setFilteredWorkHour((prev) =>
          prev.filter((entry) => entry._id !== entryId)
        );
      } else {
        errorAlert("Failed to delete entry.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting the entry.");
    }
  };

  const handleUndoSingle = async () => {
    try {
      if (deletedItem) {
        const response = await axiosInstance.post(
          `/workhour/restore/${deletedItem.entryId}`
        );

        if (response.data) {
          successAlert("Entry restored successfully");
          setFilteredWorkHour((prev) => [...prev, deletedItem]);
          setDeletedItem(null);
          setSingleDeleteSnackbarOpen(false);
          filterBetweenDates();
        } else {
          errorAlert("Failed to restore the entry.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while restoring the entry.");
    }
  };

  const handleDiscountClick = (itemId, workHour) => {
    setSelectedItemId(itemId);
    setOpenDiscountModal(true);
    setSelectedItemWorkHours(workHour); // Set work hours of the selected item
  };

  const handleModalClose = () => {
    setOpenDiscountModal(false);
    setSelectedItemId(null);
  };

  const handleDiscountSubmit = async (itemId, discountValue) => {
    console.log(
      "Submitting discount for item:",
      itemId,
      "with value:",
      discountValue
    );

    // Call the API to apply the discount using the passed discountValue directly
    await onhandleApplyDiscount(itemId, discountValue);

    // Close the modal after submitting
    handleModalClose();
  };

  //main table data show function
  const renderRows = () => {
    return commonNames.map((employee) => {
      let totalWorkHours = 0;
      return (
        <React.Fragment key={employee}>
          {filteredWorkHour
            .filter((result) => result?.user?.fullName === employee)
            .map((item, index) => {
              totalWorkHours += parseFloat(item.workHour);
              return (
                <TableRow key={item._id} role="checkbox" tabIndex={-1}>
                  <TableCell>
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      checked={selectedEntries.includes(item._id)}
                      onChange={(e) =>
                        handleSelectEntry(item._id, e.target.checked)
                      }
                    />
                  </TableCell>
                  <TableCell>{formatMonthDate(item.workDate)}</TableCell>
                  <TableCell>{item.user?.fullName}</TableCell>
                  {/* <TableCell>{item.matter?.name}</TableCell> */}
                  <TableCell>
                    {item.workDescription}
                    {showComment && item.comments && (
                      <>
                        <p
                          style={{
                            marginTop: "10px",
                            padding: "5px",
                            backgroundColor: "yellow",
                            color: "red",
                            textDecoration: addressedComments[item._id]
                              ? "line-through"
                              : "none",
                          }}
                        >
                          {item?.comments ? item.comments : ""}
                        </p>

                        <Button
                          style={{
                            backgroundColor: "lightgrey",
                            color: "red",
                            fontSize: "12px",
                          }}
                          onClick={() => markCommentAsAddressed(item._id)}
                        >
                          Address Comment
                        </Button>
                      </>
                    )}
                  </TableCell>
                  <TableCell>{parseFloat(item.workHour).toFixed(2)}</TableCell>
                  <TableCell>
                    <CustomTooltip text="Edit Entry">
                      <CiEdit
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(item, "edit")}
                      />
                    </CustomTooltip>
                  </TableCell>
                  {/* <TableCell>
                    <CustomTooltip text="Discount Entered Hours">
                      <Discount
                        size={18}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDiscount(item._id)}
                      />
                    </CustomTooltip>
                  </TableCell> */}
                  <TableCell>
                    <CustomTooltip text="Discount Entered Hours">
                      <Discount
                        size={18}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          handleDiscountClick(item._id, item.workHour)
                        }
                      />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell>
                    <CustomTooltip text="Complete WriteOff">
                      <TbDiscount2Off
                        size={18}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleWriteoffSingle(item._id)}
                      />
                    </CustomTooltip>
                  </TableCell>
                  <TableCell className="table-cell">
                    <CustomTooltip text="Delete Entry">
                      <RiDeleteBin6Line
                        size={18}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSoftDeleteSingle(item._id)}
                      />
                    </CustomTooltip>
                  </TableCell>

                  <TableCell className="table-cell">
                    <CustomTooltip text="Duplicate Entry">
                      <BiDuplicate
                        size={18}
                        style={{ cursor: "pointer" }}
                        onClick={() => openModal(item, "duplicate")}
                      />
                    </CustomTooltip>
                  </TableCell>
                </TableRow>
              );
            })}
          {totalWorkHours > 0 && (
            <React.Fragment>
              <TableRow style={{ backgroundColor: "black" }}>
                <TableCell
                  colSpan={4}
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  Total Work Hours of {employee}:
                </TableCell>
                <TableCell
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: "14px",
                  }}
                >
                  {totalWorkHours.toFixed(2)}
                </TableCell>
                <TableCell colSpan={7}></TableCell>
              </TableRow>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <ToastContainer />

      {/* Single Workhour  */}
      <Snackbar
        open={singleDeleteSnackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds
        onClose={() => setSingleDeleteSnackbarOpen(false)}
        message="Single entry deleted"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        action={
          <Button color="secondary" size="small" onClick={handleUndoSingle}>
            UNDO
          </Button>
        }
      />

      {/* Multiple Workhour  */}
      <Snackbar
        open={multipleDeleteSnackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds
        onClose={() => setMultipleDeleteSnackbarOpen(false)}
        message="Multiple entries deleted"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        action={
          <Button color="secondary" size="small" onClick={handleUndoMultiple}>
            UNDO
          </Button>
        }
      />

      {/* Snackbar for Single Write-off */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        open={singleWriteoffSnackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds
        onClose={() => setSingleWriteoffSnackbarOpen(false)}
        message="Single entry written off"
        action={
          <Button
            color="secondary"
            size="small"
            onClick={handleUndoWriteoffSingle}
          >
            UNDO
          </Button>
        }
      />

      {/* Snackbar for Multiple Write-offs */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        open={multipleWriteoffSnackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds
        onClose={() => setMultipleWriteoffSnackbarOpen(false)}
        message="Multiple entries written off"
        action={
          <Button
            color="secondary"
            size="small"
            onClick={handleUndoWriteoffMultiple}
          >
            UNDO
          </Button>
        }
      />

      {/* Search Bar  */}
      <Paper elevation={0} className="rounded-full">
        <Grid container spacing={1} alignItems="center" marginBottom="1rem">
          {/* Main Heading  */}
          <Grid item container spacing={1} xs={12} sm={7} md={3}>
            <Grid item>
              <Typography variant="h5">Timespent</Typography>
            </Grid>
            <Grid item>
              <CustomTooltip text="Add Time">
                <CustomButton
                  style={{
                    borderRadius: "50%",
                    minWidth: "5px",
                    height: "30px",
                    transition:
                      "transform 0.3s ease-in-out, background-color 0.3s ease",
                    "&:hover": {
                      backgroundColor: "#886a47",
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => openModal(null, "add")}
                >
                  <AddIcon />
                </CustomButton>
              </CustomTooltip>
            </Grid>
          </Grid>

          {/* Matters  */}
          <Grid item xs={12} sm={7} md={2.5}>
            <Select
              options={filteredMatters}
              value={filteredMatters.find(
                (option) => option.label === `${selectedMatterName} | ${client}`
              )}
              onChange={(e) => {
                setMatter(e.value);
                setSelectedMatterName(e.label.split("|")[0]);
                setClient(e.label.split("|")[1]);

                // Use find() instead of filter() to get a single object
                const selectedMatter = matters.find(
                  (matter) => matter._id === e.value
                );
                setAdvanceAmount(
                  selectedMatter?.advanceAmount.$numberDecimal || 0
                ); // Handle case if selectedMatter is undefined
              }}
              isSearchable
              placeholder="Select Matter"
              styles={customStyles}
            />
          </Grid>

          {/* Resources  */}
          <Grid item xs={12} sm={5} md={1.5}>
            <Select
              options={filteredEmployees}
              value={filteredEmployees.find(
                (resOption) => resOption.label === `${selectedLawyer}`
              )}
              onChange={(e) => {
                setSelectedLawyer(e.value);
              }}
              isSearchable
              placeholder="Resource"
              styles={customStyles}
            />
          </Grid>

          {/* Date Range Picker */}
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            display="flex"
            alignItems="center"
            gap={1}
          >
            <CustomTooltip text={"Select Dates"}>
              <CustomDateRangePicker
                startDate={selectedDateRange.start}
                endDate={selectedDateRange.end}
                onDateRangeChange={handleDateRangeChange}
                iconSize={30}
              />
            </CustomTooltip>

            <Typography>
              {formatMonthDate(startSelectedDate)} -{" "}
              {formatMonthDate(endSelectedDate)}
            </Typography>
          </Grid>

          {/* Search Icon Button */}
          <Grid item xs={4} sm={2} md={0.4}>
            <CustomTooltip text="Search workhours">
              <IconButton
                onClick={() => filterBetweenDates()}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <Search style={{ fontSize: "20px" }} />
              </IconButton>
            </CustomTooltip>
          </Grid>

          {/* Show/Hide Comments Icon Button */}
          <Grid item xs={4} sm={2} md={0.4}>
            <CustomTooltip
              text={showComment ? "Hide Comments" : "Show Comments"}
            >
              <IconButton
                onClick={() => setShowComment(!showComment)}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  // color: "white",
                  // backgroundColor: "black",
                  borderRadius: "50%",
                }}
              >
                {showComment ? (
                  <IoMdEye style={{ fontSize: "20px" }} />
                ) : (
                  <BiSolidHide style={{ fontSize: "20px" }} />
                )}
              </IconButton>
            </CustomTooltip>
          </Grid>

          {/* Download Icon Button */}
          <Grid item xs={4} sm={2} md={0.4}>
            <CustomTooltip text="Download Report">
              <IconButton
                onClick={generateTimeSpent}
                className=" text-black hover:bg-gray-800"
                style={{
                  padding: 5,
                  borderRadius: "50%",
                }}
              >
                <Download style={{ fontSize: "20px" }} />
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>

        {selectedEntries.length > 1 && (
          <Grid container spacing={1} alignItems="baseline" marginY="1rem">
            <Grid item xs={12} sm={7} md={3}>
              <Select
                options={filteredMatters}
                value={filteredMatters.find(
                  (option) =>
                    option.label === `${newSelectedMatterName} | ${newClient}`
                )}
                onChange={(e) => {
                  setNewMatter(e.value);
                  setNewSelectedMatterName(e.label.split("|")[0]);
                  setNewClient(e.label.split("|")[1]);
                }}
                isSearchable
                placeholder="Select New Matter"
                styles={customStyles}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={0.5}>
              <CustomTooltip text="Move all">
                <IconButton onClick={() => handleUpdateEntries()}>
                  <DriveFileMoveOutlinedIcon style={{ fontSize: "25px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>

            <Grid item xs={12} sm={4} md={0.5}>
              <CustomTooltip text="Delete all">
                <IconButton onClick={() => handleSoftDeleteMultiple()}>
                  <RiDeleteBin6Line style={{ fontSize: "25px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>

            <Grid item xs={12} sm={4} md={0.5}>
              <CustomTooltip text="Write-off all">
                <IconButton onClick={() => handleWriteoffMultiple()}>
                  <TbDiscount2Off style={{ fontSize: "25px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        )}
      </Paper>

      {/* Modal  */}
      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          title="Add Time" // Dynamic title
          onSubmit={handleSubmit}
        >
          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <input
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </div>

            <div style={{ width: "50%" }}>
              <Select
                options={filteredEmployees}
                value={filteredEmployees.find(
                  (resOption) =>
                    resOption.label === `${modalSelectedLawyerName}`
                )}
                onChange={(e) => {
                  setModalSelectedLawyerId(e.value);
                  setModalSelectedLawyerName(e.label);
                }}
                isSearchable
                placeholder="Select Resource"
                styles={customStyles}
              />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "100%" }}>
              {modalSelectedMatterName}

              <Select
                options={filteredMatters}
                value={filteredMatters.find(
                  (option) =>
                    option.label ===
                    `${modalSelectedMatterName} | ${modalClient}`
                )}
                onChange={(e) => {
                  setModalMatter(e.value);
                  setModalSelectedMatterName(e.label.split("|")[0]);
                  setModalClient(e.label.split("|")[1]);
                }}
                isSearchable
                placeholder="Select Matter"
                styles={customStyles}
              />
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <div style={{ width: "50%" }}>
              <select
                value={workHour}
                name="workHour"
                onChange={(event) => {
                  setWorkHour(event.target.value);
                }}
              >
                <option value="">Select Work Hour *</option>
                {Array.from({ length: 19 }, (_, i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ width: "50%" }}>
              <select
                value={workMin}
                name="workMin"
                onChange={(event) => {
                  setWorkMin(event.target.value);
                }}
              >
                <option value="">Select Work Minutes *</option>
                {Array.from({ length: 10 }, (_, i) => (
                  <option key={i * 6} value={i * 6}>
                    {i * 6}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex gap-4 w-full">
            <textarea
              type="text"
              placeholder="Work description"
              value={workDescription}
              onChange={(e) => setWorkDescription(e.target.value)}
            />
          </div>

          <div className="flex gap-4 w-full">
            <input
              type="text"
              style={{ width: "100%" }}
              placeholder="Comments for the reviewer"
              value={comments}
              onChange={(e) => setComments(e.target.value)}
            />
          </div>
        </CustomModal>
      )}

      {/* Main Table  */}
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100, 500, 1000]}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <TableContainer sx={{ maxHeight: "68vh" }}>
          <Table aria-label="reusable table">
            <TableHead>
              {/* {totalCount > 0 && ( */}
              <TableRow sx={{ height: "5px" }}>
                <TableCell colSpan={12}>
                  <div className="middle">
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label>Total amount for above period :</label>
                      <p>
                        {currency}
                        {parseFloat(totalAmountForSelectedPeriod)
                          ?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label>Total Hours for above period: </label>
                      <p>
                        {parseFloat(totalHoursForSelectedPeriod)?.toFixed(2)}
                      </p>
                    </div>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <label> Total unbilled amount : </label>
                      <p>
                        {currency}
                        {parseFloat(totalUnbilledAmount)
                          ?.toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </p>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
              {/* )} */}

              <TableRow>
                <TableCell id="table-header">
                  <CustomTooltip text="Select All">
                    <input
                      type="checkbox"
                      className="custom-checkbox"
                      checked={selectAll}
                      onChange={(e) => handleSelectAll(e)}
                    />
                  </CustomTooltip>
                </TableCell>
                {columns.map((column, index) => (
                  <TableCell key={index} id="table-header">
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {totalCount === 0 ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <NoDataFound message="Oops! No workhours found." />
                  </TableCell>
                </TableRow>
              ) : (
                renderRows()
              )}
            </TableBody>
          </Table>

          <DiscountModal
            open={openDiscountModal}
            onClose={handleModalClose}
            onSubmit={handleDiscountSubmit}
            itemId={selectedItemId}
            initialDiscount={selectedItemWorkHours} // Pass the work hours of the selected item
            existingWorkHours={selectedItemWorkHours} // Pass the existing work hours
          />
        </TableContainer>
      </Paper>
    </>
  );
};

export default TimeSpent;
