import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const { default: axiosInstance } = require("../helpers/axios");
const { timeConstants } = require("./constants");

export const addTime = async (auth, time) => {
  return async (dispatch) => {
    dispatch({ type: timeConstants.ADD_TIME_REQUEST });
    await axiosInstance
      .post(`/workHour/createNew`, time)
      .then(async (response) => {
        dispatch({
          type: timeConstants.ADD_TIME_SUCCESS,
        });
        toast.success({
          text1: "Time added successfully",
        });
      })
      .catch((error) => {
        // console.log(error?.response?.data);
        dispatch({
          type: timeConstants.ADD_TIME_FAILURE,
          error: error?.response?.data?.error,
        });
        toast.error({
          text1: error.response.data.error,
        });
      });
  };
};

export const getWorkHourByUserId = (
  startDate,
  endDate,
  matter,
  page,
  limit,
  reviewedStatus = true
) => {
  return async (dispatch) => {
    dispatch({ type: timeConstants.GET_WORK_HOUR_REQUEST });

    const queryParams = new URLSearchParams();
    if (startDate) queryParams.append("fromDate", startDate);
    if (endDate) queryParams.append("toDate", endDate);
    if (matter) queryParams.append("matter", matter);
    if (page !== undefined) queryParams.append("page", page);
    if (limit !== undefined) queryParams.append("limit", limit);
    if (reviewedStatus !== undefined)
      queryParams.append("reviewed", reviewedStatus);

    try {
      const response = await axiosInstance.get(
        `/workHour/getWorkHourByUserId?${queryParams.toString()}`
      );
      dispatch({
        type: timeConstants.GET_WORK_HOUR_SUCCESS,
        payload: {
          workHours: response?.data?.workHours,
          totalCount: response?.data?.totalCount,
        },
      });
    } catch (error) {
      dispatch({
        type: timeConstants.GET_WORK_HOUR_FAILURE,
        error: error?.response?.data?.error,
      });
      toast.error(error?.response?.data?.error);
    }
  };
};

export const addOpe = async (auth, formData) => {
  // console.log("OPE", formData);
  return async (dispatch) => {
    // const authToken = localStorage.getItem("authToken");
    dispatch({ type: timeConstants.ADD_NEW_OPE_REQUEST });
    await axiosInstance
      .post(`/OPE/createNew`, formData)
      .then(async (response) => {
        dispatch({
          type: timeConstants.ADD_NEW_OPE_SUCCESS,
        });
        if (response) {
          toast.success({
            text1: "OPE added successfully",
          });
        }
        dispatch(getWorkHourByUserId(auth.user._id));
      })
      .catch((error) => {
        // console.log(error?.response?.data.error);
        toast.error({
          text1: error.response.data,
        });
        alert(error.response.data);
        dispatch({
          type: timeConstants.ADD_NEW_OPE_FAILURE,
          error: error?.response?.data?.error,
        });
      });
  };
};

export const filterTimeByUserId = (fromDate, toDate, matterId) => {
  return async (dispatch) => {
    dispatch({ type: timeConstants.FILTER_DATA_FOR_USER_REQUEST });
    try {
      const response = await axiosInstance.get(
        `/workHour/filterWorkHourByUserId`,
        {
          params: {
            fromDate,
            toDate,
            matterId,
          },
        }
      );

      // console.log("Filtered User Data Entries", response.data);
      dispatch({
        type: timeConstants.FILTER_DATA_FOR_USER_SUCCESS,
        payload: {
          workHours: response?.data,
        },
      });
    } catch (error) {
      // console.log(error?.response?.data);
      dispatch({
        type: timeConstants.FILTER_DATA_FOR_USER_FAILURE,
        error: error?.response?.data?.error,
      });
      toast.error({
        text1: error.response.data.error,
      });
    }
  };
};

// actions/time.js
export const getPendingEntriesCount = (userId) => {
  return async (dispatch) => {
    try {
      const response = await axiosInstance.get(`/workHour/pendingCount`, {
        params: {
          userId,
        },
      });

      dispatch({
        type: timeConstants.GET_PENDING_ENTRIES_COUNT_SUCCESS,
        payload: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching pending entries count:", error);
    }
  };
};
