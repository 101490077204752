// reducers/currencyReducer.js
import {
  FETCH_CURRENCY_SETTINGS,
  UPDATE_CURRENCY_SETTINGS,
} from "../actions/currencyActions";

const initialState = {
  nativeCurrencyName: "",
  nativeCurrencyCode: "",
  conversionRates: [],
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCY_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CURRENCY_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default currencyReducer;
