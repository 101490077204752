import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllClients } from "../../actions";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import formatDate, {
  customStyles,
  errorAlert,
  successAlert,
} from "../../utils/services";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import axiosInstance from "../../helpers/axios";
import "./AddMatter.css";
import { fetchCurrencySettings } from "../../actions/currencyActions";
import { fetchDesignations } from "../../store/slices/globalSlice";
import { fetchEmployees } from "../../store/slices/employeesSlice";
import { fetchAllMatters } from "../../store/slices/matterSlice";

const AddMatter = ({ onClose }) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const adminClient = useSelector((state) => state.adminClient);
  const allCurrencyData = useSelector((state) => state.currency);

  const { billedDesignations, unBilledDesignations } = useSelector(
    (state) => state.global
  );
  const { clientList } = adminClient;
  const { employeesData, filteredEmployees } = useSelector(
    (state) => state.employee
  );

  useEffect(() => {
    dispatch(fetchDesignations());
    dispatch(fetchEmployees());
    dispatch(getAllClients());
    dispatch(fetchCurrencySettings());
  }, [dispatch]);

  const [currencyData, setCurrencyData] = useState({
    nativeCurrencyName: "",
    nativeCurrencyCode: "",
    conversionRates: [],
  });

  useEffect(() => {
    setCurrencyData(currencyData);
  }, [currencyData]);

  // Fetch currency settings when the component mounts if not already loaded
  useEffect(() => {
    if (!allCurrencyData.nativeCurrencyName) {
      dispatch(fetchCurrencySettings());
    }
  }, [dispatch, allCurrencyData.nativeCurrencyName]);

  // Set currency data to local state for easy manipulation (optional)
  useEffect(() => {
    setCurrencyData(allCurrencyData);
  }, [allCurrencyData]);

  // State Variables
  const [radioValue, setRadioValue] = useState("Price/hour");
  const [isResourceWise, setIsResourceWise] = useState(false); // Toggle between designation-wise and resource-wise
  const [resourceSpecificPrices, setResourceSpecificPrices] = useState([]);
  const [designationPrices, setDesignationPrices] = useState({});
  const [designationApplied, setDesignationApplied] = useState(false); // Flag to check if designation prices have been applied
  const [formData, setFormData] = useState({
    name: "",
    client: "",
    currency: "",
    pricePerHour: "",
    matterOpenDate: formatDate(new Date()),
    matterAddress: "",
    engagementLetter: [],
    flatFeesAmount: "",
    advanceAmount: "",
    matterType: "",
  });

  // Resource-wise States
  const [newResource, setNewResource] = useState(""); // Selected resource
  const [newResourcePrice, setNewResourcePrice] = useState(""); // Assigned price

  // File Upload States
  const [uploading, setUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  // Submission State
  const [isSubmitting, setIsSubmitting] = useState(false);

  const currencies = [
    currencyData.nativeCurrencyCode,
    ...currencyData.conversionRates.map((curr) => curr.currencyCode),
  ];

  // Prepare Client Options for Select
  const clientNames = clientList.map((client) => ({
    value: client._id,
    label: `${client.name}`,
  }));

  // Handle form data changes
  const handleOnChangeText = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  // Handle Designation-wise Price Changes
  const handleDesignationPriceChange = (designation, price) => {
    setDesignationPrices((prevPrices) => ({
      ...prevPrices,
      [designation]: price,
    }));
  };

  // Apply Designation-wise Prices to Resources
  const applyDesignationPrices = () => {
    const updatedResourceSpecificPrices = employeesData
      .filter(
        (emp) =>
          !unBilledDesignations.includes(emp.designation) &&
          emp.status !== "inActive"
      )
      .map((employee) => {
        // Get price based on designation
        const price = designationPrices[employee.designation];
        if (price) {
          return `${employee.fullName}: ${price}`;
        }
        return null; // Return null if no price is assigned
      })
      .filter((entry) => entry !== null); // Remove null entries

    setResourceSpecificPrices(updatedResourceSpecificPrices);

    setDesignationApplied(true); // Set flag to indicate prices have been applied
    successAlert("Designation-wise prices applied successfully.");
  };

  // Handle Adding a Resource in Resource-wise Case
  const handleAddNewResource = () => {
    if (newResource && newResourcePrice) {
      // Check if the resource is already added
      const isAlreadyAdded = resourceSpecificPrices.some((resource) =>
        resource.startsWith(newResource + ":")
      );
      if (isAlreadyAdded) {
        errorAlert("Resource already added.");
        return;
      }

      // Add new resource to resource-specific prices
      const newEntry = `${newResource.trim()}: ${newResourcePrice}`;
      setResourceSpecificPrices((prevState) => [...prevState, newEntry]);

      // Reset input fields
      setNewResource("");
      setNewResourcePrice("");
      successAlert("Resource added successfully.");
    } else {
      errorAlert("Please select a resource and assign a price.");
    }
  };

  //matter el pdf upload functions
  const handleRemoveFile = (index) => {
    const newSelectedFile = selectedFile.filter((_, i) => i !== index);
    setSelectedFile(newSelectedFile);
  };

  const handleFileUpload = async (e) => {
    // console.log(e.target.files);
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user._id);
        // Adjust the form data append lines as necessary for the new endpoint requirements

        // Update the fetch URL to the new endpoint
        uploadPromises.push(axiosInstance.post(`/uploadMatterEL`, formData));
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.data)
        );

        uploadResponses.forEach((response) => {
          if (!response.data) {
            console.error(
              `Error with status ${response.status} during file upload`
            );
          }
        });

        // Assume the API returns an object with a URL field for the uploaded file
        const uploadedFileUrls = uploadData.map((data) => data.url);
        setSelectedFile([...selectedFile, ...uploadedFileUrls]);
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    }
  };

  // Handle Form Submission
  const onAddMatter = async (event) => {
    event.preventDefault();

    // Prevent multiple submissions
    if (isSubmitting) return;

    setIsSubmitting(true); // Disable submission while working

    try {
      if (radioValue === "ResourceSpecific") {
        if (!isResourceWise && !designationApplied) {
          errorAlert("Please apply designation-wise prices before submitting.");
          setIsSubmitting(false);
          return;
        }

        if (isResourceWise && resourceSpecificPrices.length === 0) {
          errorAlert("Please assign prices to resources before submitting.");
          setIsSubmitting(false);
          return;
        }
      }

      // Validate and submit the form
      if (isValidForm()) {
        const form = new FormData();
        form.append("admin", auth.user._id);
        form.append("name", formData.name);
        form.append("client", formData.client);
        form.append("currency", formData.currency);
        form.append("pricePerHour", formData.pricePerHour);
        form.append("matterOpenDate", formData.matterOpenDate);
        form.append("engagementLetter", selectedFile);
        form.append("matterAddress", formData.matterAddress);
        form.append("flatFeesAmount", formData.flatFeesAmount || 0);
        form.append("advanceAmount", formData.advanceAmount || 0);

        form.append("matterType", formData.matterType);

        if (radioValue === "ResourceSpecific") {
          form.append(
            "resourceSpecificPrice",
            JSON.stringify(resourceSpecificPrices)
          );
        }

        const response = await axiosInstance.post(`/matter/createNew`, form);

        if (response.data) {
          successAlert("Matter Added Successfully!");
          dispatch(fetchAllMatters());
          // Reset form
          setFormData({
            name: "",
            client: "",
            currency: "",
            pricePerHour: "",
            matterOpenDate: formatDate(new Date()),
            matterAddress: "",
            engagementLetter: [],
            flatFeesAmount: "",
            advanceAmount: "",
            matterType: "",
          });
          setResourceSpecificPrices([]);
          setDesignationPrices({});
          setDesignationApplied(false);
          setNewResource("");
          setNewResourcePrice("");
          setSelectedFile([]);
          onClose();
        }
      }
    } catch (error) {
      errorAlert("Error occurred while adding the matter.");
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false); // Re-enable submission once done
    }
  };

  // Validation for form
  const isValidForm = () => {
    if (
      formData.name === "" ||
      formData.client === "" ||
      formData.currency === "" ||
      formData.matterOpenDate === ""
    ) {
      errorAlert("All fields are required!");
      return false;
    }

    if (radioValue === "ResourceSpecific") {
      if (!isResourceWise && Object.keys(designationPrices).length === 0) {
        errorAlert("Please assign prices to designations.");
        return false;
      }

      if (isResourceWise && resourceSpecificPrices.length === 0) {
        errorAlert("Please assign prices to resources.");
        return false;
      }
    }

    return true;
  };

  // Toggle between resource-wise and designation-wise pricing
  const handlePricingToggle = (isResourceWiseSelected) => {
    // If switching to Resource-wise, clear the resource-specific prices
    if (isResourceWiseSelected) {
      setResourceSpecificPrices([]); // Clear the resource-specific prices when switching to Resource-wise
    }
    setIsResourceWise(isResourceWiseSelected); // Update the toggle state
  };

  return (
    <>
      <ToastContainer />
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          overflow: "hidden",
          marginBottom: 4,
          padding: 5,
          borderRadius: 4,
        }}
      >
        <Typography variant="h5" align="center" marginBottom={3}>
          Add Matter
        </Typography>

        <Grid container spacing={3}>
          {/* Client and Matter Name */}
          <Grid item xs={12} sm={6}>
            <Select
              name="client"
              options={clientNames}
              value={clientNames.find(
                (option) => option.value === formData.client
              )}
              onChange={(e) => handleOnChangeText(e.value, "client")}
              isSearchable
              placeholder="Select Client *"
              styles={{
                control: (base) => ({
                  ...base,
                  height: "46px",
                  minHeight: "46px",
                }),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Matter Name *"
              fullWidth
              value={formData.name}
              onChange={(e) => handleOnChangeText(e.target.value, "name")}
              inputProps={{ style: { height: "13px" } }}
            />
          </Grid>

          {/* Currency and Engagement Letter Upload */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              value={formData.currency}
              onChange={(e) => handleOnChangeText(e.target.value, "currency")}
              SelectProps={{ native: true }}
              inputProps={{ style: { height: "20px" } }} // Set consistent height
            >
              <option value="">Select Currency *</option>
              {currencies &&
                currencies.map((curr) => <option value={curr}>{curr}</option>)}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <Typography variant="subtitle1" gutterBottom>
              Select Engagement Letter
            </Typography> */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "8px 12px",
                height: "56px",
              }}
            >
              <label
                htmlFor="upload-button"
                style={{
                  cursor: "pointer",
                  padding: "6px 12px",
                  backgroundColor: "#1976d2",
                  color: "white",
                  borderRadius: "4px",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Upload Engagement Letter
              </label>
              <input
                id="upload-button"
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleFileUpload}
                disabled={uploading}
                style={{ display: "none" }}
              />
              {uploading && (
                <CircularProgress size={20} style={{ marginLeft: "10px" }} />
              )}
            </div>
            <div style={{ marginTop: "10px" }}>
              {selectedFile.map((file, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <a href={file} target="_blank" rel="noopener noreferrer">
                    Open File
                  </a>
                  <Button
                    onClick={() => handleRemoveFile(index)}
                    color="error"
                    variant="outlined"
                    size="small"
                  >
                    Remove
                  </Button>
                </div>
              ))}
            </div>
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            Select Engagement Letter
            <div
              style={{ display: "flex", alignItems: "center", height: "36px" }}
            >
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                onChange={handleFileUpload}
                disabled={uploading}
              />
              {uploading && (
                <CircularProgress size={20} style={{ marginLeft: "10px" }} />
              )}
            </div>
            {selectedFile.map((file, index) => (
              <div key={index}>
                <a href={file} target="_blank" rel="noopener noreferrer">
                  Open File
                </a>
                <Button onClick={() => handleRemoveFile(index)} color="error">
                  Remove
                </Button>
              </div>
            ))}
          </Grid> */}

          {/* Pricing Options */}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Rating Method</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant={
                    radioValue === "Price/hour" ? "contained" : "outlined"
                  }
                  fullWidth
                  onClick={() => setRadioValue("Price/hour")}
                >
                  Blended Rates / Hour
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant={
                    radioValue === "ResourceSpecific" ? "contained" : "outlined"
                  }
                  fullWidth
                  onClick={() => setRadioValue("ResourceSpecific")}
                >
                  Resource Specific Rates
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Price Per Hour Input */}
          {radioValue === "Price/hour" && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Rates Per Hour *"
                value={formData.pricePerHour}
                onChange={(e) =>
                  handleOnChangeText(e.target.value, "pricePerHour")
                }
              />
            </Grid>
          )}

          {/* Resource Specific Pricing */}
          {radioValue === "ResourceSpecific" && (
            <>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Rating Type</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button
                      variant={isResourceWise ? "contained" : "outlined"}
                      fullWidth
                      onClick={() => handlePricingToggle(true)}
                    >
                      Resource-wise
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      variant={!isResourceWise ? "contained" : "outlined"}
                      fullWidth
                      onClick={() => handlePricingToggle(false)}
                    >
                      Designation-wise
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {isResourceWise ? (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Select
                        options={filteredEmployees.map((employee) => ({
                          value: employee.label,
                          label: employee.label,
                        }))}
                        value={
                          newResource
                            ? { label: newResource, value: newResource }
                            : null
                        }
                        onChange={(selectedOption) =>
                          setNewResource(selectedOption.value)
                        }
                        placeholder="Select Resource"
                        isSearchable
                        styles={{
                          ...customStyles,
                          control: (base) => ({
                            ...base,
                            height: "46px", // Set consistent height
                            minHeight: "46px", // Ensure minimum height
                          }),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        label="Rate"
                        fullWidth
                        type="number"
                        value={newResourcePrice}
                        onChange={(e) => setNewResourcePrice(e.target.value)}
                        onWheel={(e) => e.target.blur()} // Disable scroll for number input
                      />
                    </Grid>
                    <Grid item xs={2} alignSelf="center">
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={handleAddNewResource}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>

                  {resourceSpecificPrices.length > 0 && (
                    <div style={{ marginTop: "10px" }}>
                      <Typography variant="subtitle2">
                        Assigned Resources:
                      </Typography>
                      {resourceSpecificPrices.map((resource, index) => (
                        <Typography key={index} variant="body2">
                          {resource}
                        </Typography>
                      ))}
                    </div>
                  )}
                </Grid>
              ) : (
                <Grid item xs={12}>
                  {billedDesignations.map((designation) => (
                    <div
                      key={designation}
                      style={{
                        marginBottom: "10px",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <label>{designation}: </label>
                      <TextField
                        type="number"
                        placeholder="Rate *"
                        value={designationPrices[designation] || ""}
                        onChange={(e) =>
                          handleDesignationPriceChange(
                            designation,
                            e.target.value
                          )
                        }
                        style={{ marginLeft: "10px", width: "200px" }}
                        onWheel={(e) => e.target.blur()} // Disable scroll for number input
                      />
                    </div>
                  ))}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={applyDesignationPrices}
                    disabled={Object.keys(designationPrices).length === 0}
                  >
                    Apply Rates
                  </Button>

                  {/* Display Assigned Designation-wise Prices */}
                  {designationApplied && resourceSpecificPrices.length > 0 && (
                    <div style={{ marginTop: "20px" }}>
                      <Typography variant="h6">Assigned Resources:</Typography>
                      {resourceSpecificPrices.map((resource, index) => (
                        <div key={index} style={{ marginBottom: "10px" }}>
                          <Typography variant="body2">{resource}</Typography>
                        </div>
                      ))}
                    </div>
                  )}
                </Grid>
              )}
            </>
          )}

          {/* Matter Type and Flat Fees */}
          <Grid item xs={12} sm={6}>
            <TextField
              select
              fullWidth
              // label="Matter Type *"
              value={formData.matterType}
              onChange={(e) => handleOnChangeText(e.target.value, "matterType")}
              SelectProps={{ native: true }}
            >
              <option value="">Select Matter Type *</option>
              <option value="timespent">Time Spent</option>
              <option value="flatfees">Fixed Fees</option>
            </TextField>
          </Grid>

          {formData.matterType === "flatfees" && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={`Fixed Fees (${formData.currency}) *`}
                type="number"
                value={formData.flatFeesAmount}
                onChange={(e) =>
                  handleOnChangeText(e.target.value, "flatFeesAmount")
                }
                onWheel={(e) => e.target.blur()} // Disable scroll for number input
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={`Advance (${formData.currency})`}
              type="number"
              value={formData.advanceAmount}
              onChange={(e) =>
                handleOnChangeText(e.target.value, "advanceAmount")
              }
              onWheel={(e) => e.target.blur()}
            />
          </Grid>

          {/* Matter Open Date and Address */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Matter Open Date *"
              type="date"
              value={formData.matterOpenDate}
              onChange={(e) =>
                handleOnChangeText(e.target.value, "matterOpenDate")
              }
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Matter Address"
              multiline
              // rows={2}
              value={formData.matterAddress}
              onChange={(e) =>
                handleOnChangeText(e.target.value, "matterAddress")
              }
            />
          </Grid>

          {/* Submit and Cancel */}
          <Grid item xs={12} sm={6}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={onAddMatter}
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="outlined" fullWidth onClick={onClose}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AddMatter;
