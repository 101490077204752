import { timeConstants } from "../actions/constants";

const initialState = {
  writeOffHours: [], // Array to store the writeoff entries
  totalWriteoffCount: 0,
  loading: false,
  error: null,
};

const adminWriteoffReducer = (state = initialState, action) => {
  switch (action.type) {
    case timeConstants.FETCH_WRITEOFFS_REQUEST_BY_ADMIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case timeConstants.FETCH_WRITEOFFS_SUCCESS_BY_ADMIN:
      return {
        ...state,
        loading: false,
        writeOffHours: action.payload.writeoffHours,
        totalWriteoffCount: action.payload.totalWriteoffCount,
      };
    case timeConstants.FETCH_WRITEOFFS_FAILURE_BY_ADMIN:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default adminWriteoffReducer;
