import { authConstants } from "../actions/constants";

let authToken = null;
let user = null;

try {
  authToken = localStorage.getItem("authToken");
  user = JSON.parse(localStorage.getItem("user"));
} catch (error) {
  console.error("Error parsing JSON data:", error);
}

const initState = {
  authToken: authToken || null,
  user: authToken !== null ? user : null,
  authenticate: authToken ? true : false,
  authenticating: false,
  loading: false,
  error: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        authenticating: true,
        loading: true,
        error: null,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        authToken: action.payload.authToken,
        authenticate: true,
        authenticating: false,
        loading: false,
        error: null,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        authenticate: false,
        authenticating: false,
        loading: false,
      };
    case authConstants.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case authConstants.LOGOUT_SUCCESS:
      return {
        ...initState,
        authToken: null,
        user: null,
        authenticate: false,
        loading: false,
      };
    case authConstants.LOGOUT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default authReducer;

// import { authConstants } from "../actions/constants";

// let authToken = null;
// let user = null;

// try {
//   authToken = localStorage.getItem("authToken");
//   user = JSON.parse(localStorage.getItem("user"));
// } catch (error) {
//   // Handle the parsing error here, e.g., set user to a default value or log the error.
//   console.error("Error parsing JSON data:", error);
// }

// const initState = {
//   authToken: authToken || null,
//   user: authToken !== null ? user : null,
//   authenticate: authToken ? true : false,
//   authenticating: false,
//   loading: false,
//   error: null,
//   message: "",
// };

// export default (state = initState, action) => {
//   switch (action.type) {
//     case authConstants.LOGIN_REQUEST:
//       state = {
//         ...state,
//         authenticating: true,
//       };
//       break;
//     case authConstants.LOGIN_SUCCESS:
//       state = {
//         ...state,
//         user: action.payload.user,
//         authToken: action.payload.authToken,
//         authenticate: true,
//         authenticating: false,
//       };
//       break;
//     case authConstants.LOGIN_FAILURE:
//       state = {
//         ...state,
//         error: action.payload.error,
//         authenticate: false,
//         authenticating: false,
//       };
//       break;
//     case authConstants.LOGOUT_REQUEST:
//       state = {
//         ...state,
//         loading: true,
//       };
//       break;
//     case authConstants.LOGOUT_SUCCESS:
//       state = {
//         ...initState,
//         authToken: null,
//         user: null,
//         authenticate: false,
//       };
//       break;
//     case authConstants.LOGOUT_FAILURE:
//       state = {
//         ...state,
//         error: action.payload.error,
//         loading: false,
//       };
//       break;
//   }
//   return state;
// };
