import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const { default: axiosInstance } = require("../helpers/axios");
const { timeConstants } = require("./constants");

export const filterTimeByUserIdForAdmin = (
  userId,
  fromDate,
  toDate,
  matterId,
  needFin,
  sendMail,
  adminId,
  rowsPerPage,
  page,
) => {
  return async (dispatch) => {
    // console.log(fromDate, toDate, matterId, userId, needFin, sendMail);

    dispatch({ type: timeConstants.FILTER_DATA_FOR_ADMIN_REQUEST });

    await axiosInstance
      .get(`/workHour/filterWorkHourByUserId`, {
        params: {
          user: userId,
          fromDate,
          toDate,
          matterId,
          needFin,
          sendMail: sendMail === true ? true : false,
          adminId,
          limit: rowsPerPage,
          page,
        },
      })
      .then(async (response) => {
        dispatch({
          type: timeConstants.FILTER_DATA_FOR_ADMIN_SUCCESS,
          payload: {
            workHours: response.data.workHours,
            totalFilteredWorkHours: response.data.totalFilteredWorkHours,
          },
        });
      })
      .catch((error) => {
        // console.log(error);
        dispatch({
          type: timeConstants.FILTER_DATA_FOR_ADMIN_FAILURE,
          payload: {
            error: JSON.stringify(error.response),
          },
        });
        toast.error({
          text1: JSON.stringify(error.response),
        });
      });
  };
};

export const getTotalWorkHours = (adminId) => {
  return async (dispatch) => {
    dispatch({ type: timeConstants.GET_TOTAL_WORK_HOURS_REQUEST });

    try {
      const response = await axiosInstance.get(
        `/workhour/getWorkHoursTotals`,
        {
          params: {
            adminId,
          },
        }
      );

      dispatch({
        type: timeConstants.GET_TOTAL_WORK_HOURS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: timeConstants.GET_TOTAL_WORK_HOURS_FAILURE,
        payload: { error: error?.response?.data?.error },
      });
    }
  };
};
