import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import thunk from "redux-thunk";
import rootReducer from "../reducers";


//TODO : MAKE SURE THIS REDUX IS NOT AVAILABLE ON PRODUCTION, SO THAT NO ONE CAN ACCESS THE DATA DIRECTLY FROM REDUX
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;

// import { configureStore } from "@reduxjs/toolkit";
// import rootReducer from "../reducers"; // We'll refactor this below

// const store = configureStore({
//   reducer: rootReducer,
//   devTools: process.env.REACT_APP_NODE_ENV !== "production", // Enable Redux DevTools in non-production environments
// });

// export default store;
