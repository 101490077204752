import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../helpers/axios";

// Async Thunk for fetching matters with optional pagination parameters
export const fetchAllMatters = createAsyncThunk(
  "matter/fetchAllMatters",
  async ({ rowsPerPage = 0, page = 0 } = {}, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.get(`/matter/getAllMatters`, {
        params: {
          limit: rowsPerPage,
          page,
        },
      });

      const matters = res.data.matters;
      const totalMatters = res.data.totalMatters;

      return {
        matters,
        totalMatters,
      };
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.error || "Failed to fetch matters"
      );
    }
  }
);

const matterSlice = createSlice({
  name: "matter",
  initialState: {
    matters: [],
    filteredMatters: [],
    totalMatters: 0,
    matterLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllMatters.pending, (state) => {
        state.matterLoading = true;
        state.error = null;
      })
      .addCase(fetchAllMatters.fulfilled, (state, action) => {
        state.matters = action.payload.matters;
        state.filteredMatters = [
          { value: "", label: "Select Matter *" },
          ...action.payload.matters
            .filter((matter) => matter.status === "enable")
            .map((matter) => ({
              value: matter._id,
              label: `${matter.name} | ${matter.client.name}`,
            })),
        ];
        state.totalMatters = action.payload.totalMatters;
        state.matterLoading = false;
      })
      .addCase(fetchAllMatters.rejected, (state, action) => {
        state.matterLoading = false;
        state.error = action.payload;
      });
  },
});

export default matterSlice.reducer;
