import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  Snackbar,
  TablePagination,
  Typography,
} from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import formatDate, {
  customStyles,
  errorAlert,
  successAlert,
} from "../../utils/services";
import { Add, Remove } from "@mui/icons-material";
import axiosInstance from "../../helpers/axios";
import Header from "../../components/ui/Header";
import { COLORS } from "../../constants/theme";
import OpeCard from "../../components/ui/OpeCard";
import CustomTooltip from "../../components/ui/CustomTootltip";
import NoDataFound from "../../components/NoDataFound";
import CustomButton from "../../components/ui/CustomButton";
import useResponsive from "../../constants/useResponsive";
import { fetchAllMatters } from "../../store/slices/matterSlice";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const validationSchema = Yup.object().shape({
  selectedDate: Yup.string().required("Date is required"),
  matter: Yup.string().required("Matter is required"),
  description: Yup.string().required("Description is required"),
  amount: Yup.number().required("Amount is required"),
  // currency: Yup.string().required("Currency is required"),
});

const ResourceOPE = () => {
  const dispatch = useDispatch();
  const { isSm } = useResponsive();
  const auth = useSelector((state) => state.auth);
  const { filteredMatters } = useSelector((state) => state.matter);

  useEffect(() => {
    dispatch(fetchAllMatters());
  }, [dispatch]);

  const [expenses, setExpenses] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [editingOPE, setEditingOPE] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  // State to keep track of the deleted item
  const [deletedItem, setDeletedItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isFormVisible, setIsFormVisible] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      selectedDate: formatDate(new Date()),
      matter: "",
      client: "",
      selectedMatterName: "",
      description: "",
      amount: "",
      // currency: "INR",
    },
  });

  useEffect(() => {
    const entry = editingOPE;
    if (entry) {
      // setValue("selectedDate", formatDate(new Date(entry.expenseDate)));
      setValue("matter", entry.matter._id);
      setValue("selectedMatterName", entry.matter.name);
      setValue("description", entry.description);
      setValue("amount", entry.amount.$numberDecimal);
      // setValue("currency", entry.currency);
    }
  }, [editingOPE, setValue]);

  const onSubmit = async (data) => {
    // console.log("Submitting form with data:", data); // Debug log

    if (
      data.selectedDate &&
      data.matter &&
      data.description &&
      data.amount
      // &&
      // data.currency
    ) {
      const opeData = {
        admin: auth?.user?.company,
        user: auth?.user?._id,
        expenseDate: data.selectedDate,
        matter: data.matter,
        description: data.description,
        amount: data.amount,
        // currency: data.currency,
        status: "pending",
        opeBills: selectedImages,
      };

      try {
        // console.log("Onsubmit called with OPE data:", opeData); // Debug log

        let response;
        if (editingOPE) {
          response = await axiosInstance.put(
            `/expense/${editingOPE._id}`,
            opeData
          );
        } else {
          response = await axiosInstance.post(`/expense`, opeData);
        }

        if (response.data) {
          successAlert(
            editingOPE
              ? "Expense Updated Successfully!"
              : "Expense Added Successfully!"
          );
          // fetchAllopees();
          fetchExpenses();
          setEditingOPE(null);
          reset();
          setSelectedImages([]);
        }
      } catch (error) {
        console.error("Error:", error);
        errorAlert("Something went wrong", error);
      }
    } else {
      errorAlert("All Fields are Required!");
    }
  };

  const handleEdit = (entry) => {
    if (!isFormVisible) {
      setIsFormVisible((prev) => !prev);
    }
    setEditingOPE(entry);
  };

  const handleFileUpload = async (e) => {
    if (e.target.files.length > 0) {
      const files = e.target.files;
      const uploadPromises = [];

      // console.log("File Upload called!!"); // Debug log

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("adminId", auth.user.company);
        formData.append("employeeName", auth.user.fullName);
        formData.append("employeeId", auth.user._id);

        uploadPromises.push(
          axiosInstance.post(`/uploadExpensesBill`, formData)
        );
      }

      try {
        setUploading(true);
        const uploadResponses = await Promise.all(uploadPromises);
        const uploadData = await Promise.all(
          uploadResponses.map((res) => res.data)
        );
        const uploadedFileUrls = uploadData.map((data) => data.url);
        setSelectedImages([...selectedImages, ...uploadedFileUrls]);

        // Reset the file input field
        e.target.value = "";
      } catch (e) {
        console.error(e);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleRemoveImage = async (event, index) => {
    // Create a copy of the current selected images
    const updatedSelectedImages = [...selectedImages];
    const imageToRemove = updatedSelectedImages[index];

    try {
      // Optimistic UI Update: Remove the image from the state immediately
      setSelectedImages((prevImages) =>
        prevImages.filter((_, imgIndex) => imgIndex !== index)
      );

      // Send a request to the backend to delete the image
      const result = await axiosInstance.delete(`/deleteExpensesBill`, {
        data: { filePath: imageToRemove },
      });

      if (result.status !== 200) {
        throw new Error("Failed to delete image on the server");
      }

      console.log("Image deleted successfully from the server.");
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");

      // Restore the image in the state if deletion fails
      setSelectedImages(updatedSelectedImages);
    }
  };

  //Get expenses by pagination
  const fetchExpenses = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/expense/resource`, {
        params: { limit: rowsPerPage, page: page + 1, matter: searchQuery },
      });
      console.log(response);
      setExpenses(response.data.expenses);
      setTotalCount(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching expenses:", error);
      errorAlert("Unable to fetch expenses. Please try again.");
    }
  }, [rowsPerPage, page, searchQuery]);

  const toggleFormVisibility = () => {
    setIsFormVisible((prev) => !prev);
  };

  // Soft Delete
  const handleSoftDelete = async (entryId) => {
    try {
      const response = await axiosInstance.delete(
        `/expense/softdelete/${entryId}`
      );

      console.log("Delete response:", response.data);

      if (response.data) {
        setDeletedItem({ entryId, ...response.data });
        // Show the undo alert
        setSnackbarOpen(true);
        console.log("Deleted Item State:", { entryId, ...response.data }); // Debug the deleted item state
        // fetchAllopees();
        fetchExpenses();
      } else {
        errorAlert("Failed to delete workhour.");
      }
    } catch (error) {
      errorAlert("An error occurred while deleting workhour.");
    }
  };

  // Undo Soft Delete
  const handleUndo = async () => {
    try {
      if (deletedItem) {
        const response = await axiosInstance.post(
          `/expense/restore/${deletedItem.entryId}`
        );

        if (response.data) {
          setDeletedItem(null);
          successAlert("Workhour restored successfully.");
          // fetchAllopees();
          fetchExpenses();
        } else {
          errorAlert("Failed to restore workhour.");
        }
      }
    } catch (error) {
      errorAlert("An error occurred while restoring workhour.");
    }
  };

  useEffect(() => {
    // fetchAllopees();
    fetchExpenses();
  }, [fetchExpenses]);

  return (
    <>
      <Header />
      <ToastContainer />
      <CssBaseline />

      {/* Snackbar for Undo Action */}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Change the position here
        open={snackbarOpen}
        autoHideDuration={10000} // Hide after 10 seconds if no action is taken
        onClose={() => setSnackbarOpen(false)}
        message="Workhour deleted"
        action={
          <Button color="secondary" size="small" onClick={handleUndo}>
            UNDO
          </Button>
        }
      />

      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        paddingX={isSm ? 2 : 5}
        marginTop={2}
      >
        {/* Heading and Add/Remove Button aligned to the left */}
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          display="flex"
          alignItems="center"
          justifyContent="flex-start"
          marginBottom={3}
        >
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="h5">Expenses</Typography>
            <CustomTooltip text={isFormVisible ? "Close Form" : "Add expense"}>
              <CustomButton
                style={{
                  borderRadius: "50%",
                  minWidth: "30px", // Minimum width
                  width: "30px", // Fixed width
                  height: "30px", // Fixed height
                  flexShrink: 0, // Prevents the button from stretching
                  transition:
                    "transform 0.3s ease-in-out, background-color 0.3s ease",
                  "&:hover": {
                    backgroundColor: COLORS.primary,
                    transform: "scale(1.1)",
                  },
                }}
                onClick={toggleFormVisibility}
              >
                {isFormVisible ? <Remove /> : <Add />}
              </CustomButton>
            </CustomTooltip>
          </Box>
        </Grid>

        {/* Search Bar and Calendar Icon aligned to the center */}
        {(isSm ? !isFormVisible : true) && (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Box style={{ width: "100%" }}>
              <Select
                options={filteredMatters}
                value={filteredMatters.find(
                  (option) => option.value === searchQuery
                )}
                onChange={(option) => setSearchQuery(option.value)}
                isSearchable
                placeholder="Search Matter"
                styles={{
                  ...customStyles,
                  width: "100%",
                  control: (base) => ({
                    ...base,
                    height: '35px',
                    minHeight: '35px',
                  })
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        display="flex"
        justifyContent="center"
        spacing={2}
        paddingX={isSm ? 2 : 5}
        paddingY={2}
      >
        {isFormVisible && (
          <Grid item xs={12} sm={12} md={4}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} rowSpacing={{ xs: 2, sm: 2 }} columnSpacing={{ xs: 2, sm: 2, md: 2 }} marginBottom={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <input
                    type="date"
                    className={`w-full px-3 py-2 border rounded-md ${errors.selectedDate ? 'border-red-500' : 'border-gray-300'
                      }`}
                    {...register("selectedDate")}
                    style={{
                      borderColor: "#aaa",
                      backgroundColor: "#fff",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  />
                  {errors.selectedDate && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.selectedDate.message}
                    </p>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="matter"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={filteredMatters}
                        value={filteredMatters.find(
                          (option) => option.value === field.value
                        )}
                        onChange={(option) => {
                          setValue("matter", option.value);
                          setValue(
                            "selectedMatterName",
                            option.label.split("|")[0]
                          );
                          setValue("client", option.label.split("|")[1]);
                          trigger("matter");
                        }}
                        isSearchable
                        placeholder="Search Matter"
                        styles={{
                          control: (base) => ({
                            ...base,
                            height: '35px',
                            minHeight: '35px',
                            borderRadius: '0.375rem',
                            borderColor: errors.matter
                              ? 'red'
                              : base.borderColor,
                          }),
                          menu: (base) => ({
                            ...base,
                            zIndex: 9999
                          })
                        }}
                      />
                    )}
                  />
                  {errors.matter && (
                    <p className="text-red-500 text-sm mt-1">{errors.matter.message}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12}>
                  <input
                    placeholder="Amount"
                    type="number"
                    className={`w-full px-3 py-2 border rounded-md ${errors.amount ? 'border-red-500' : 'border-gray-300'
                      }`}
                    {...register("amount")}
                  />
                  {errors.amount && (
                    <p className="text-red-500 text-sm mt-1">{errors.amount.message}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={12}>
                  <textarea
                    placeholder="Bill description"
                    className={`w-full px-3 py-2 border rounded-md h-24 ${errors.description ? 'border-red-500' : 'border-gray-300'
                      }`}
                    {...register("description")}
                  />
                  {errors.description && (
                    <p className="text-red-500 text-sm mt-1">{errors.description.message}</p>
                  )}
                </Grid>
              </Grid>

              <Grid container marginBottom={2} flexDirection="column">
                <input
                  type="file"
                  accept={"image/jpeg, image/png, image/webp, image/heif"}
                  onChange={handleFileUpload}
                  disabled={uploading}
                  multiple
                  placeholder="Upload Bills"
                />
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '10px',
                    alignItems: 'center'
                  }}
                >
                  {selectedImages.map((file, index) => (
                    <div
                      key={index}
                      style={{
                        position: 'relative',
                        width: '100px',
                        height: '100px',
                        marginBottom: '10px',
                        marginTop: '30px'
                      }}
                    >
                      <img
                        src={file}
                        alt={`Selected image ${index + 1}`}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                          borderRadius: '8px',
                          border: '2px solid #808080',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                      />
                      <IconButton
                        color="error"
                        onClick={(event) => handleRemoveImage(event, index)}
                        size="small"
                        style={{
                          position: 'absolute',
                          top: '-10px',
                          right: '-10px',
                          backgroundColor: 'black',
                          color: 'white',
                          width: '30px',
                          height: '30px',
                          padding: '5px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </div>
                  ))}
                </div>
                {uploading && <p>Uploading...</p>}
              </Grid>

              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="flex-end"
              >
                <Grid item xs={6} sm={6}>
                  <CustomButton
                    type="submit"
                    variant="contained"
                    style={{ width: "100%" }}
                  >
                    {editingOPE ? "Update" : "Submit"}
                  </CustomButton>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <CustomButton
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      reset();
                      setEditingOPE(null);
                      setSelectedImages([]);
                    }}
                  >
                    Reset
                  </CustomButton>
                </Grid>
              </Grid>

            </Box>
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sm={12}
          md={isFormVisible ? 8 : 12}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ maxHeight: "70vh", overflow: "hidden" }}
        >
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* Table Pagination */}
            {totalCount > 0 && (
              <Box>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    padding: isSm ? "0 5px" : "0 16px",
                    "& .MuiTablePagination-toolbar": {
                      fontSize: isSm ? "16px" : "", // Text size for the toolbar
                    },
                    "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
                    {
                      fontSize: isSm ? "16px" : "", // Text size for labels and displayed rows
                    },
                    "& .MuiTablePagination-select": {
                      fontSize: isSm ? "16px" : "", // Text size for the dropdown (e.g., "25")
                      color: "black", // Match the text color of the rest of the style
                      fontWeight: "bold", // Optional: Bold text for emphasis
                    },
                    "& .MuiTablePagination-select:hover": {
                      backgroundColor: "transparent", // Optional: Ensure no hover effect for dropdown
                    },
                    "& .MuiTablePagination-select:focus": {
                      outline: "none", // Remove focus outline if needed
                    },
                    "& .MuiTablePagination-actions button": {
                      fontSize: "16px", // Icon button size
                    },
                  }}
                />
              </Box>
            )}

            {/* Cards Section with scrollable area */}
            <Box sx={{ flex: 1, overflowY: "auto" }}>
              {/* Scrollable content container */}

              {expenses.length === 0 ? (
                <NoDataFound message="Oops! No expenses found." />
              ) : (
                expenses.map((time, index) => (
                  <OpeCard
                    key={time._id}
                    time={time}
                    isFormVisible={isFormVisible}
                    handleEdit={handleEdit}
                    handleDelete={handleSoftDelete}
                  />
                ))
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {window.innerWidth <= 768 ? (
        <Grid item xs={12}>
          <Box sx={{ height: "70px" }}></Box> {/* Add 50px of space */}
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default ResourceOPE;
