import { useNavigate } from "react-router-dom";
import axiosInstance from "../../helpers/axios";
import { ToastContainer } from "react-toastify";
import { Box, Grid, TextField, CssBaseline, Typography } from "@mui/material";
import { errorAlert, successAlert } from "../../utils/services";
import CustomButton from "../../components/ui/CustomButton";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const ForgotPassword = () => {
  const navigation = useNavigate();

  const onBackToSignIn = () => {
    navigation("/");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const sendMail = async (data) => {
    const { email } = data;
    await axiosInstance
      .post(`/sendForgotPasswordOtp`, { email })
      .then((response) => {
        if (response.data) {
          successAlert(response.data);
          navigation("/Otp", {
            state: { user: response.data.user, type: "forgotPassword" },
          });
        }
      })
      .catch((error) => {
        errorAlert(
          error?.response?.data?.error
            ? error.response.data.error
            : "Network error! Please try again."
        );
      });
  };

  return (
    <>
      <ToastContainer />
      <CssBaseline />
      <Grid container>
        {/* Left Image  */}
        <Grid
          item
          md={6}
          justifyContent="center"
          alignItems="center"
          height="100vh"
          sx={{
            display: { xs: "none", md: "flex" },
            backgroundImage: `url(https://comput-images.blr1.cdn.digitaloceanspaces.com/login-bg-image.jpeg)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Typography
            variant="h3"
            align="center"
            color="white"
            fontWeight="600"
          >
            Forgot Password
          </Typography>
        </Grid>

        {/* Right Form  */}
        <Grid
          item
          md={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          padding={5}
          width="100%"
          gap="15px"
        >
          {/* Logo  */}
          <Box
            justifyContent="center"
            alignItems="center"
            sx={{
              display: { md: "none", xs: "flex" },
            }}
          >
            <img
              src={`https://comput-images.blr1.cdn.digitaloceanspaces.com/Comput_labs_black.png`}
              alt="logo"
              style={{ maxWidth: "30%" }}
              loading="lazy"
            />
          </Box>

          <Typography variant="h5">Reset Password</Typography>

          {/* Form  */}
          <Box
            component="form"
            onSubmit={handleSubmit(sendMail)}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <TextField
              fullWidth
              variant="outlined"
              label="Email address"
              margin="normal"
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <CustomButton type="submit">Reset</CustomButton>
          </Box>

          {/* Extra Button  */}
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            marginTop="10px"
          >
            <CustomButton variant="text" color="gray" onClick={onBackToSignIn}>
              Back to Log In
            </CustomButton>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPassword;
