import { authConstants } from "./constants";
import axiosInstance from "../helpers/axios";

export const AdminSignIn = (user) => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGIN_REQUEST });

    try {
      const res = await axiosInstance.post(`/signin`, { ...user });

      if (res && res.data) {
        const { token, user } = res.data;

        localStorage.setItem("authToken", token);
        localStorage.setItem("user", JSON.stringify(user));

        dispatch({
          type: authConstants.LOGIN_SUCCESS,
          payload: {
            authToken: token,
            user: user,
          },
        });
      } else {
        dispatch({
          type: authConstants.LOGIN_FAILURE,
          payload: { error: "Invalid response from server" },
        });
      }
    } catch (error) {
      console.log("Error in sig in", error);
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: error.response?.data?.message || "Unknown error" },
      });
    }
  };
};

export const isUserLoggedIn = () => {
  return async (dispatch) => {
    const token = localStorage.getItem("token");

    if (token) {
      // const user = JSON.parse(AsyncStorage.getItem("user"));
      await axiosInstance
        .post(`/verifyToken`)
        .then(async (res) => {
          const user = localStorage.getItem("user");
          dispatch({
            type: authConstants.LOGIN_SUCCESS,
            payload: {
              token,
              status: user.status,
              user: JSON.parse(user),
            },
          });
        })
        .catch((error) => {
          dispatch({
            type: authConstants.LOGIN_FAILURE,
            payload: { error: error },
          });
        });
    } else {
      dispatch({
        type: authConstants.LOGIN_FAILURE,
        payload: { error: "failed to login" },
      });
    }
  };
};

export const signout = () => {
  return async (dispatch) => {
    dispatch({ type: authConstants.LOGOUT_REQUEST });
    const pushNotifyToken = localStorage.getItem("pushNotifyToken");

    const res = await axiosInstance.post("/signout", {
      pushNotifyToken,
    });

    // console.log("Sign out response:", res.status);
    if (res.status === 200) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("selectedComponent");
      dispatch({
        type: authConstants.LOGOUT_SUCCESS,
      });
    } else {
      dispatch({
        type: authConstants.LOGOUT_FAILURE,
        payload: {
          error: res.data.error,
        },
      });
    }
  };
};
