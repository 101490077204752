import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import InputAdornment from "@mui/material/InputAdornment";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import { COLORS } from "../../constants/theme";

const DiscountModal = ({
  open,
  onClose,
  onSubmit,
  itemId,
  initialDiscount,
  existingWorkHours,
}) => {
  const [discountHours, setDiscountHours] = useState(initialDiscount || "");
  const [remainingHours, setRemainingHours] = useState(existingWorkHours || 0);

  useEffect(() => {
    // Calculate remaining hours dynamically as the discount is typed
    const discountValue = parseFloat(discountHours) || 0;
    setRemainingHours(
      existingWorkHours - discountValue >= 0
        ? (existingWorkHours - discountValue).toFixed(2)
        : 0
    );
  }, [discountHours, existingWorkHours]);

  const handleSubmit = () => {
    onSubmit(itemId, discountHours);
    setDiscountHours("");
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1.5rem",
          color: COLORS.primary,
        }}
      >
        Apply Discount on Work Hours
      </DialogTitle>
      <DialogContent style={{ padding: "20px" }}>
        {/* Show current work hours and discount details */}
        <Typography variant="body1" style={{ marginBottom: "10px" }}>
          <strong>Total Work Hours:</strong> {existingWorkHours} hrs
        </Typography>
        <Typography
          variant="body1"
          style={{ marginBottom: "10px", color: "#f44336" }}
        >
          <strong>Discounted Hours:</strong> {discountHours || "0"} hrs
        </Typography>
        <Typography
          variant="body1"
          style={{ marginBottom: "20px", color: "#4caf50" }}
        >
          <strong>Remaining Hours:</strong> {remainingHours} hrs
        </Typography>

        <TextField
          autoFocus
          label="Discount Hours"
          value={discountHours}
          onChange={(e) => {
            // Only allow numbers, including decimal points
            const value = e.target.value;
            if (/^\d*\.?\d*$/.test(value)) {
              setDiscountHours(value);
            }
          }}
          fullWidth
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WorkOutlineIcon style={{ color: "#f44336" }} />
              </InputAdornment>
            ),
            inputProps: {
              inputMode: "decimal", // Allows decimal input on mobile keyboards
              pattern: "[0-9]*", // Pattern for numeric input
            },
          }}
        />
      </DialogContent>
      <DialogActions
        style={{ justifyContent: "center", paddingBottom: "20px" }}
      >
        <Button
          onClick={onClose}
          variant="contained"
          color="error"
          startIcon={<CancelIcon />}
          style={{ marginRight: "10px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="success"
          startIcon={<CheckCircleIcon />}
          disabled={discountHours > existingWorkHours || discountHours <= 0}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DiscountModal;
