import React from "react";
import { TableRow, TableCell } from "@mui/material";
import { Skeleton } from "@mui/material";

const TableSkeletonLoader = ({ rows = 5, colSpan = 11 }) => {
  return (
    <>
      {[...Array(rows)].map((_, index) => (
        <TableRow key={index}>
          <TableCell colSpan={colSpan}>
            <Skeleton variant="rectangular" height={30} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

export default TableSkeletonLoader;
