import { timeConstants } from "../actions/constants";

const initState = {
  userWorkHour: [],
  workHourLoading: false,
  opeLoading: false,
  loading: false,
  error: null,
  message: "",
  filterLoading: false,
  filteredWorkHour: [],
  filteredError: null,
  totalFilteredWorkHours: 0,
  totalWorkHours: {
    totalTodayWorkHours: 0,
    totalThisMonthWorkHours: 0,
    totalThisYearWorkHours: 0,
    totalLoading: false,
    totalError: null,
  },
};

const adminTimeReducer = (state = initState, action) => {
  switch (action.type) {
    case timeConstants.FILTER_DATA_FOR_ADMIN_REQUEST:
      return {
        ...state,
        filterLoading: true,
      };
    case timeConstants.FILTER_DATA_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        filteredWorkHour: action.payload.workHours,
        totalFilteredWorkHours: action.payload.totalFilteredWorkHours,
        filterLoading: false,
      };
    case timeConstants.FILTER_DATA_FOR_ADMIN_FAILURE:
      return {
        ...state,
        filteredError: action.payload.error,
        filterLoading: false,
      };

    // New action types for getting total work hours
    case timeConstants.GET_TOTAL_WORK_HOURS_REQUEST:
      return {
        ...state,
        totalWorkHours: {
          ...state.totalWorkHours,
          totalLoading: true,
        },
      };
    case timeConstants.GET_TOTAL_WORK_HOURS_SUCCESS:
      return {
        ...state,
        totalWorkHours: {
          ...state.totalWorkHours,
          totalTodayWorkHours: action.payload.totalTodayWorkHours,
          totalThisMonthWorkHours: action.payload.totalThisMonthWorkHours,
          totalThisYearWorkHours: action.payload.totalThisYearWorkHours,
          totalLoading: false,
        },
      };
    case timeConstants.GET_TOTAL_WORK_HOURS_FAILURE:
      return {
        ...state,
        totalWorkHours: {
          ...state.totalWorkHours,
          totalError: action.payload.error,
          totalLoading: false,
        },
      };
    default:
      return state;
  }
};

export default adminTimeReducer;

// import { timeConstants } from "../actions/constants";

// const initState = {
//   userWorkHour: [],
//   workHourLoading: false,
//   opeLoading: false,
//   loading: false,
//   error: null,
//   message: "",
//   filterLoading: false,
//   filteredWorkHour: [],
//   filteredError: null,
//   totalFilteredWorkHours: 0,
//   totalWorkHours: {
//     totalTodayWorkHours: 0,
//     totalThisMonthWorkHours: 0,
//     totalThisYearWorkHours: 0,
//     totalLoading: false,
//     totalError: null,
//   },
// };

// const adminTimeReducer = (state = initState, action) => {
//   switch (action.type) {
//     case timeConstants.FILTER_DATA_FOR_ADMIN_REQUEST:
//       state = {
//         ...state,
//         filterLoading: true,
//       };
//       break;
//     case timeConstants.FILTER_DATA_FOR_ADMIN_SUCCESS:
//       state = {
//         ...state,
//         filteredWorkHour: action.payload.workHours,
//         totalFilteredWorkHours: action.payload.totalFilteredWorkHours,
//         filterLoading: false,
//       };
//       break;
//     case timeConstants.FILTER_DATA_FOR_ADMIN_FAILURE:
//       state = {
//         ...state,
//         filteredError: action.payload.error,
//         filterLoading: false,
//       };
//       break;

//     // New action types for getting total work hours
//     case timeConstants.GET_TOTAL_WORK_HOURS_REQUEST:
//       state = {
//         ...state,
//         totalWorkHours: {
//           ...state.totalWorkHours,
//           totalLoading: true,
//         },
//       };
//       break;
//     case timeConstants.GET_TOTAL_WORK_HOURS_SUCCESS:
//       state = {
//         ...state,
//         totalWorkHours: {
//           ...state.totalWorkHours,
//           totalTodayWorkHours: action.payload.totalTodayWorkHours,
//           totalThisMonthWorkHours: action.payload.totalThisMonthWorkHours,
//           totalThisYearWorkHours: action.payload.totalThisYearWorkHours,
//           totalLoading: false,
//         },
//       };
//       break;
//     case timeConstants.GET_TOTAL_WORK_HOURS_FAILURE:
//       state = {
//         ...state,
//         totalWorkHours: {
//           ...state.totalWorkHours,
//           totalError: action.payload.error,
//           totalLoading: false,
//         },
//       };
//       break;
//     default:
//       return state;
//   }
// };

// export default adminTimeReducer;
