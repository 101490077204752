import React, { useState, useEffect, useCallback } from "react";
import {
  Paper,
  Grid,
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Tab,
  Box,
  CircularProgress,
} from "@mui/material";
import { Download } from "@mui/icons-material";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import formatDate, {
  errorAlert,
  formatCurrency,
  formatLetterDate,
  formatMonthDate,
} from "../../utils/services";
import axiosInstance from "../../helpers/axios";
import CustomTooltip from "../../components/ui/CustomTootltip";
import PuffLoader from "react-spinners/PuffLoader";

// Import Recharts components
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LabelList,
} from "recharts";
import { fetchCurrencySettings } from "../../actions/currencyActions";
import NoDataFound from "../../components/NoDataFound";

const Financial = () => {
  // Constants
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const currencyData = useSelector((state) => state.currency);
  const [localCurrencyData, setLocalCurrencyData] = useState(currencyData);

  useEffect(() => {
    setLocalCurrencyData(currencyData);
  }, [currencyData]);

  // Fetch currency settings when the component mounts if not already loaded
  useEffect(() => {
    if (!currencyData.nativeCurrencyName) {
      dispatch(fetchCurrencySettings());
    }
  }, [dispatch, currencyData.nativeCurrencyName]);

  // States
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const [companyData, setCompanyData] = useState({
    companyName: "",
    companyEmail: "",
    companyAddress: "",
    companyLogo: null,
    companyWebsite: "",
    companyAccountsEmail: "",
  });

  // Year selection state
  const [selectedYear, setSelectedYear] = useState(() => {
    const today = new Date();
    const currentYear = today.getFullYear();
    return currentYear;
  });

  // Function to generate years array (last 4 years)
  const generateYearsArray = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 4; i++) {
      years.push(currentYear - i);
    }
    return years;
  };

  const years = generateYearsArray();

  // Start and End dates based on selectedYear
  const [startSelectedDate, setStartSelectedDate] = useState(() => {
    const newStartDate = formatDate(new Date(selectedYear, 3, 1)); // April 1st
    return newStartDate;
  });

  const [endSelectedDate, setEndSelectedDate] = useState(() => {
    const newEndDate = formatDate(new Date(selectedYear + 1, 2, 31)); // March 31st of next year
    return newEndDate;
  });

  // Update start and end dates when selectedYear changes
  useEffect(() => {
    const newStartDate = formatDate(new Date(selectedYear, 3, 1)); // April 1st
    const newEndDate = formatDate(new Date(selectedYear + 1, 2, 31)); // March 31st of next year
    setStartSelectedDate(newStartDate);
    setEndSelectedDate(newEndDate);
  }, [selectedYear]);

  // Additional state for top N selection
  const [topN, setTopN] = useState(5);

  const handleTopNChange = (event) => {
    setTopN(event.target.value);
  };

  const adminId = auth.user._id;

  // Fetch Company Details
  const fetchCompanyDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`/company/details/${adminId}`);

      // Check if company data exists
      if (response.data && Object.keys(response.data).length > 0) {
        setCompanyData({
          ...companyData,
          companyName: response.data.companyName || "",
          companyEmail: response.data.companyEmail || "",
          companyAddress: response.data.companyAddress || "",
          companyLogo: response.data.companyLogo || "",
          companyWebsite: response.data.companyWebsite || "",
          companyAccountsEmail: response.data.companyAccountsEmail || "",
        });
      } else {
        // If no company data is found, display an appropriate message
        console.log("No company data found.");
      }
    } catch (error) {
      errorAlert("Error in fetching company details!");
    }
  }, [adminId]);

  useEffect(() => {
    fetchCompanyDetails();
  }, [fetchCompanyDetails]);

  // Combined Invoices Data for Summary Cards and Invoices Tab
  const [invoicesData, setInvoicesData] = useState({
    collectedInvoicesTotal: 0,
    pendingInvoicesTotal: 0,
    totalBilledSinceApril: 0,
    collectedInvoices: [],
    pendingInvoices: [],
    nativeCurrencyCode: "",
  });

  const [isInvoicesDataLoading, setIsInvoicesDataLoading] = useState(false);

  useEffect(() => {
    const fetchInvoicesData = async () => {
      setIsInvoicesDataLoading(true);
      try {
        const response = await axiosInstance.get("/reports/invoices-summary", {
          params: { startSelectedDate, endSelectedDate },
        });

        setInvoicesData(response.data);
      } catch (error) {
        errorAlert("Error fetching invoices data. Try again...");
      } finally {
        setIsInvoicesDataLoading(false);
      }
    };

    fetchInvoicesData();
  }, [startSelectedDate, endSelectedDate]);

  // Tab Data States and Date Ranges
  const [matterPerformanceData, setMatterPerformanceData] = useState([]);
  const [allMatterTotalBillableAmount, setAllMatterTotalBillableAmount] =
    useState(0);
  const [allMatterTotalBilledAmount, setAllMatterTotalBilledAmount] =
    useState(0);
  const [isMatterDataLoading, setIsMatterDataLoading] = useState(false);
  const [matterDataDateRange, setMatterDataDateRange] = useState({
    start: "",
    end: "",
  });

  const [resourceEfficiencyData, setResourceEfficiencyData] = useState([]);
  const [isResourceDataLoading, setIsResourceDataLoading] = useState(false);
  const [resourceDataDateRange, setResourceDataDateRange] = useState({
    start: "",
    end: "",
  });

  const [reimbursementsData, setReimbursementsData] = useState({
    expensesChartData: [],
  });
  const [isReimbursementsDataLoading, setIsReimbursementsDataLoading] =
    useState(false);
  const [reimbursementsDataDateRange, setReimbursementsDataDateRange] =
    useState({
      start: "",
      end: "",
    });

  const [clockedHoursData, setClockedHoursData] = useState({
    clockedHoursChartData: [],
  });
  const [isClockedHoursDataLoading, setIsClockedHoursDataLoading] =
    useState(false);
  const [clockedHoursDataDateRange, setClockedHoursDataDateRange] = useState({
    start: "",
    end: "",
  });

  // Fetch Data Based on Active Tab
  useEffect(() => {
    if (tabIndex === 2) {
      // Matter Performance Tab
      if (
        matterPerformanceData.length > 0 &&
        matterDataDateRange.start === startSelectedDate &&
        matterDataDateRange.end === endSelectedDate
      ) {
        // Data already fetched; do nothing
      } else {
        const fetchMatterPerformanceData = async () => {
          setIsMatterDataLoading(true);
          try {
            const response = await axiosInstance.get(
              "/reports/matter-performance",
              {
                params: { startSelectedDate, endSelectedDate },
              }
            );
            setMatterPerformanceData(response.data.data);
            setAllMatterTotalBillableAmount(
              response.data.allMatterTotalBillableAmount
            );
            setAllMatterTotalBilledAmount(
              response.data.allMatterTotalBilledAmount
            );
            setMatterDataDateRange({
              start: startSelectedDate,
              end: endSelectedDate,
            });
          } catch (error) {
            errorAlert("Error fetching matter performance data. Try again...");
          } finally {
            setIsMatterDataLoading(false);
          }
        };
        fetchMatterPerformanceData();
      }
    } else if (tabIndex === 1) {
      // Resource Efficiency Tab
      if (
        resourceEfficiencyData.length > 0 &&
        resourceDataDateRange.start === startSelectedDate &&
        resourceDataDateRange.end === endSelectedDate
      ) {
        // Data already fetched; do nothing
      } else {
        const fetchResourceEfficiencyData = async () => {
          setIsResourceDataLoading(true);
          try {
            const response = await axiosInstance.get(
              "/reports/resource-efficiency",
              {
                params: { startSelectedDate, endSelectedDate },
              }
            );
            setResourceEfficiencyData(response.data.data);
            setResourceDataDateRange({
              start: startSelectedDate,
              end: endSelectedDate,
            });
          } catch (error) {
            errorAlert("Error fetching resource efficiency data. Try again...");
          } finally {
            setIsResourceDataLoading(false);
          }
        };
        fetchResourceEfficiencyData();
      }
    } else if (tabIndex === 0) {
      // Reimbursements Tab
      if (
        reimbursementsData.expensesChartData.length > 0 &&
        reimbursementsDataDateRange.start === startSelectedDate &&
        reimbursementsDataDateRange.end === endSelectedDate
      ) {
        // Data already fetched; do nothing
      } else {
        const fetchReimbursementsData = async () => {
          setIsReimbursementsDataLoading(true);
          try {
            const response = await axiosInstance.get(
              "/reports/reimbursements",
              {
                params: { startSelectedDate, endSelectedDate },
              }
            );

            setReimbursementsData(response.data);
            setReimbursementsDataDateRange({
              start: startSelectedDate,
              end: endSelectedDate,
            });
          } catch (error) {
            errorAlert(error);
          } finally {
            setIsReimbursementsDataLoading(false);
          }
        };

        fetchReimbursementsData();
      }
    } else if (tabIndex === 4) {
      // Clocked Hours Tab
      if (
        clockedHoursData.clockedHoursChartData.length > 0 &&
        clockedHoursDataDateRange.start === startSelectedDate &&
        clockedHoursDataDateRange.end === endSelectedDate
      ) {
        // Data already fetched; do nothing
      } else {
        const fetchClockedHoursData = async () => {
          setIsClockedHoursDataLoading(true);
          try {
            const response = await axiosInstance.get("/reports/clocked-hours", {
              params: { startSelectedDate, endSelectedDate },
            });

            setClockedHoursData(response.data);
            setClockedHoursDataDateRange({
              start: startSelectedDate,
              end: endSelectedDate,
            });
          } catch (error) {
            errorAlert("Error fetching clocked hours data. Try again...");
          } finally {
            setIsClockedHoursDataLoading(false);
          }
        };

        fetchClockedHoursData();
      }
    }
    // No need to fetch invoices data again for tabIndex === 2
  }, [tabIndex, startSelectedDate, endSelectedDate]);

  // Prepare data for charts with grouping
  const MATTER_THRESHOLD = topN;

  // Sort matters by contribution descending
  const sortedMatterData = [...matterPerformanceData].sort(
    (a, b) => b.contributionToTotal - a.contributionToTotal
  );

  // Get top N matters
  const topMatters = sortedMatterData.slice(0, MATTER_THRESHOLD);

  // Calculate "Others"
  const otherMatters = sortedMatterData.slice(MATTER_THRESHOLD);

  const othersContribution = otherMatters.reduce(
    (acc, curr) => acc + curr.contributionToTotal,
    0
  );

  // Prepare data for chart
  const matterChartData = [
    ...topMatters,
    ...(othersContribution > 0
      ? [
          {
            matter: "Others",
            contributionToTotal: othersContribution,
          },
        ]
      : []),
  ];

  // Similar preparation for resource chart
  const RESOURCE_THRESHOLD = topN;

  const sortedResourceData = [...resourceEfficiencyData]
    .sort((a, b) => b.billableHoursUtilized - a.billableHoursUtilized)
    .filter((item) => item.resource !== "Total Hours");

  const topResources = sortedResourceData.slice(0, RESOURCE_THRESHOLD);

  const otherResources = sortedResourceData.slice(RESOURCE_THRESHOLD);
  const othersBillableHours = otherResources.reduce(
    (acc, curr) => acc + curr.billableHoursUtilized,
    0
  );

  const resourceChartData = [
    ...topResources,
    ...(othersBillableHours > 0
      ? [
          {
            resource: "Others",
            billableHoursUtilized: othersBillableHours,
          },
        ]
      : []),
  ];

  // Invoices Data
  const {
    collectedInvoicesTotal,
    pendingInvoicesTotal,
    totalBilledSinceApril,
    collectedInvoices,
    pendingInvoices,
    nativeCurrencyCode,
  } = invoicesData;

  const totalInvoiceAmounts = [
    { name: "Collected", value: collectedInvoicesTotal || 0 },
    { name: "Pending", value: pendingInvoicesTotal || 0 },
  ];

  const computeCollectedInvoicesData = () => {
    return collectedInvoices.map((invoice) => ({
      raisedDate: formatMonthDate(invoice.invoiceDate),
      invoiceNumber: invoice.invoiceNumber,
      invoicePeriod: `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
        invoice.endDate
      )}`,
      matter: invoice.matter.name,
      collectionDate: formatMonthDate(invoice.collectionDate),
      amount: parseFloat(invoice.amountInNativeCurrency),
    }));
  };

  const computePendingInvoicesData = () => {
    return pendingInvoices.map((invoice) => ({
      raisedDate: formatMonthDate(invoice.invoiceDate),
      invoiceNumber: invoice.invoiceNumber,
      invoicePeriod: `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
        invoice.endDate
      )}`,
      matter: invoice.matter.name,
      amount: parseFloat(invoice.amountInNativeCurrency),
    }));
  };

  // Reimbursements Data
  const { expensesChartData } = reimbursementsData;

  // Clocked Hours Data
  const { clockedHoursChartData } = clockedHoursData;

  // Formatting dates
  const formattedMonthStartDate = formatMonthDate(startSelectedDate);
  const formattedMonthEndDate = formatMonthDate(endSelectedDate);

  const COLORS2 = [
    "#6C63FF", // Lavender blue
    "#BFD7EA", // Soft blue
    "#FFC107", // Soft amber
    "#8DC3A7", // Soft teal green
    "#FF8C94", // Soft coral
    "#4E5D6C", // Muted dark blue-gray
    "#6C757D", // Soft gray
    "#ADB5BD", // Light gray
    "#17A2B8", // Light cyan
    "#E9ECEF", // Off-white gray
    "#A9A9A9", // Dark gray
    "#FFD700", // Gold
  ];

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }

  //constants for pdf
  const link1Text = companyData.companyAccountsEmail;
  const link1URL = `mailto:${companyData.companyAccountsEmail}`;
  const link2Text = companyData.companyWebsite;
  const link2URL = companyData.companyWebsite;

  // Function to generate PDF
  const generateFinancialReport = () => {
    const doc = new jsPDF({
      orientation: "landscape",
    });

    const formattedStartDate = formatLetterDate(startSelectedDate);
    const formattedEndDate = formatLetterDate(endSelectedDate);

    const formattedMonthStartDate = formatMonthDate(startSelectedDate);
    const formattedMonthEndDate = formatMonthDate(endSelectedDate);

    const dateRangeLength =
      (297 - doc.getTextWidth(`${formattedStartDate} - ${formattedEndDate}`)) /
      2;

    // Header fixed for every new page
    const pageWidth = doc.internal.pageSize.getWidth();
    const rightMargin = 15; // You can adjust the right margin as needed
    const logoDesiredHeight = 20; // Set the desired height for the logo
    const logoYPosition = 10; // Set the Y position for the logo
    const logoXPosition = 13; // Set the X position for the logo

    function addHeader() {
      // Set the font style, size, and position of the header text
      const fontSize = 8;

      doc.setFont("helvetica", "normal");
      doc.setFontSize(fontSize);

      // Add the image to the header
      doc.addImage(
        companyData.companyLogo,
        "PNG",
        logoXPosition,
        logoYPosition,
        0,
        logoDesiredHeight
      );

      // Right header
      doc.setTextColor(46, 115, 176);

      const link2Width = doc.getTextWidth(link2Text);
      const link2X = pageWidth - link2Width - rightMargin;
      doc.textWithLink(link2Text, link2X, 19, {
        url: link2URL,
        underline: true,
      });

      // Set link color to blue and add underline
      const link1Width = doc.getTextWidth(link1Text);
      const link1X = pageWidth - link1Width - rightMargin;
      doc.textWithLink(link1Text, link1X, 22, {
        url: link1URL,
        underline: true,
      });

      doc.setTextColor(0);

      // Calculate text width and position to align it right
      const addressWidth = doc.getTextWidth(companyData.companyAddress);
      const addressX = pageWidth - addressWidth - rightMargin;
      doc.text(companyData.companyAddress, addressX, 26);

      // Reset text color to black
    }

    const FinancialReport = "/assets/FinancialReport.jpg";

    doc.addImage(FinancialReport, "JPEG", 0, 0, 297, 210);
    addHeader();

    const fontSize = 20;
    doc.setFontSize(fontSize);

    // Date range
    doc.setFont("helvetica", "italic");
    doc.setTextColor(0, 0, 0); // Black color
    doc.setFontSize(15);
    doc.text(
      `${formattedStartDate} - ${formattedEndDate}`,
      dateRangeLength,
      100
    );

    // Horizontal line
    doc.setLineWidth(0.3);
    doc.setDrawColor(136, 106, 71);
    doc.line(90, 110, 200, 110);

    // -------------------------3rd Page -- Matterwise Performance Report---------------------

    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Matterwise Performance Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Matterwise Performance Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    // Table Data 1
    const matterTableColumns = [
      "Client",
      "Matter",
      "Clocked Hours",
      `Clocked Amount (${nativeCurrencyCode})`,
      `Billed Amount (${nativeCurrencyCode})`,
      "Discount %",
      "Contribution To Total %",
    ];
    const matterTableData = [];

    if (matterPerformanceData.length > 0) {
      matterPerformanceData.forEach((matter) => {
        const matterTableDataArray = [
          matter.client,
          matter.matter,
          parseFloat(matter.hoursClocked).toFixed(2),
          parseFloat(matter.actualTimeClocked)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          parseFloat(matter.timeBilled)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          parseFloat(matter.discountPercentage).toFixed(2) + "%",
          parseFloat(matter.contributionToTotal).toFixed(2) + "%",
        ];

        matterTableData.push(matterTableDataArray);
      });
    }

    // Footer Row
    const matterFooterRow = [
      {
        content: `Total Amount (${nativeCurrencyCode})`,
        colSpan: 3,
        styles: {
          fillColor: [97, 68, 58],
          textColor: [255, 255, 255],
          fontStyle: "bold",
        },
      },
      allMatterTotalBillableAmount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      allMatterTotalBilledAmount
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      "",
      "",
    ];

    matterTableData.push(matterFooterRow);

    doc.autoTable(matterTableColumns, matterTableData, {
      startY: 50,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (
          data.row.section === "body" &&
          data.column.index >= 2 &&
          data.column.index <= 6
        ) {
          data.cell.styles.halign = "right";
        }
        if (data.column.index === 0 || data.column.index === 1) {
          data.cell.styles.cellWidth = 60;
        }
      },
    });

    // -------------------------4th Page -- Resource Efficiency Report--------------------
    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Efficiency and Utilization Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Efficiency and Utilization Report (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );
    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    const resourceTableColumns = [
      "Resource",
      "No. of days worked",
      "Available Clocked Hours",
      "Clocked Hours",
      "Billed Hours",
      "Discount %",
      "Avg Clocked hrs/day",
    ];

    const resourceTableData = [];

    if (resourceEfficiencyData.length > 0) {
      resourceEfficiencyData.forEach((resource) => {
        const tableDataArray = [
          resource.resource,
          resource.noOfDaysWorked,
          parseFloat(resource.availBillableHours).toFixed(2),
          parseFloat(resource.billableHoursUtilized).toFixed(2),
          parseFloat(resource.actualBilled).toFixed(2),
          parseFloat(resource.discounting).toFixed(2) + "%",
          parseFloat(resource.avgBillableHrsPerDay || 0).toFixed(2),
        ];

        resourceTableData.push(tableDataArray);
      });
    }

    // Efficiency and Utilization Table
    doc.autoTable(resourceTableColumns, resourceTableData, {
      startY: 50,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
          if (data.column.index >= 1 && data.column.index <= 6) {
            data.cell.styles.halign = "right";
          }
        }
        if (data.column.index === 0) {
          data.cell.styles.halign = "left";
          data.cell.styles.cellWidth = 60;
        }
      },
    });

    // -----------------------Next Page -- Invoices Tracker------------------------------
    doc.addPage();
    addHeader();

    // Collected Invoices Data
    const invoiceCollectedTableColumns = [
      "Raised Date",
      "Invoice No.",
      "Invoice Period",
      "Matter",
      "Collection Date",
      `Amount (${nativeCurrencyCode})`,
    ];

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Collections in this period (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Collections in this period (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );

    const invoiceCollectedTableData = [];

    if (collectedInvoices.length > 0) {
      collectedInvoices.forEach((invoice) => {
        const invoiceRowData = [
          formatMonthDate(invoice.invoiceDate),
          invoice.invoiceNumber,
          `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
            invoice.endDate
          )}`,
          invoice.matter.name,
          formatMonthDate(invoice.collectionDate),
          parseFloat(invoice.amountInNativeCurrency)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        ];
        invoiceCollectedTableData.push(invoiceRowData);
      });
    }

    const invoiceCollectedFooterRow = [
      {
        content: `Total Collected Amount (${nativeCurrencyCode})`,
        colSpan: 5,
      },
      collectedInvoicesTotal
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    invoiceCollectedTableData.push(invoiceCollectedFooterRow);

    doc.autoTable(invoiceCollectedTableColumns, invoiceCollectedTableData, {
      startY: 50,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body" && data.column.index === 5) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 35;
        }
      },
    });

    // Pending Invoices Next Page
    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);

    // Pending Invoices Data
    doc.text(
      `Pending Invoices Tracker (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Pending Invoices Tracker (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );

    doc.setTextColor(0, 0, 0);
    doc.setFont("helvetica", "normal");
    doc.setFontSize(10);

    const invoicePendingTableData = [];
    const invoicePendingTableColumns = [
      "Raised Date",
      "Invoice No.",
      "Invoice Period",
      "Matter",
      `Amount (${nativeCurrencyCode})`,
    ];

    if (pendingInvoices.length > 0) {
      pendingInvoices.forEach((invoice) => {
        const invoiceRowData = [
          formatMonthDate(invoice.invoiceDate),
          invoice.invoiceNumber,
          `${formatMonthDate(invoice.startDate)} - ${formatMonthDate(
            invoice.endDate
          )}`,
          invoice.matter.name,
          parseFloat(invoice.amountInNativeCurrency)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        ];
        invoicePendingTableData.push(invoiceRowData);
      });
    }

    const invoicePendingFooterRow = [
      {
        content: `Total Pending Amount (${nativeCurrencyCode})`,
        colSpan: 4,
      },
      pendingInvoicesTotal
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ];

    invoicePendingTableData.push(invoicePendingFooterRow);

    doc.autoTable(invoicePendingTableColumns, invoicePendingTableData, {
      startY: 50,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (
          data.row.index === data.table.body.length - 1 &&
          data.row.section === "body"
        ) {
          data.cell.styles.fillColor = [97, 68, 58];
          data.cell.styles.textColor = [255, 255, 255];
          data.cell.styles.fontStyle = "bold";
        }
        if (data.row.section === "body" && data.column.index === 4) {
          data.cell.styles.halign = "right";
          data.cell.styles.cellWidth = 35;
        }
      },
    });

    // -----------------------New Page -- Clocked Hours Over Time------------------------------
    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Clocked Hours Over Time (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Clocked Hours Over Time (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );

    // Prepare table data for clocked hours
    const clockedHoursTableColumns = ["Month", "Clocked Hours"];
    const clockedHoursTableData = clockedHoursChartData.map((item) => [
      item.month,
      parseFloat(item.hours)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ]);

    doc.autoTable(clockedHoursTableColumns, clockedHoursTableData, {
      startY: 50,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (data.column.index === 1) {
          data.cell.styles.halign = "right";
        }
      },
    });

    // -----------------------New Page -- Reimbursements Over Time------------------------------
    doc.addPage();
    addHeader();

    doc.setFont("helvetica", "bold");
    doc.setTextColor(136, 106, 71);
    doc.setFontSize(15);
    doc.text(
      `Reimbursements Over Time (${formattedMonthStartDate} - ${formattedMonthEndDate})`,
      (297 -
        doc.getTextWidth(
          `Reimbursements Over Time (${formattedMonthStartDate} - ${formattedMonthEndDate})`
        )) /
        2,
      40
    );

    // Prepare table data for reimbursements
    const reimbursementsTableColumns = [
      "Month",
      `Amount (${nativeCurrencyCode})`,
    ];
    const reimbursementsTableData = expensesChartData.map((item) => [
      item.month,
      parseFloat(item.amount)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    ]);

    doc.autoTable(reimbursementsTableColumns, reimbursementsTableData, {
      startY: 50,
      theme: "grid",
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      didParseCell: function (data) {
        if (data.row.index === 0 && data.row.section === "head") {
          data.cell.styles.fillColor = [136, 106, 71];
          data.cell.styles.textColor = [255, 255, 255];
        }
        if (data.row.section === "body") {
          data.cell.styles.fontSize = 9;
          data.cell.styles.fontStyle = "arial";
        }
        if (data.column.index === 1) {
          data.cell.styles.halign = "right";
        }
      },
    });

    // END TABLES

    doc.save(
      `Financial_Report ${formattedMonthStartDate} - ${formattedMonthEndDate}.pdf`
    );
  };

  return (
    <>
      <ToastContainer />
      <div>
        {/* Search Bar  */}
        <Paper elevation={0} className="rounded-full">
          {/* Year Selection Dropdown */}
          <Grid container spacing={1} alignItems="center" marginBottom="1rem">
            <Grid item xs={12} sm={6} md={3}>
              <Typography variant="h5">Financial Dashboard</Typography>
            </Grid>

            {/* Year Dropdown */}
            <Grid item xs={12} sm={6} md={1.5}>
              <FormControl
                variant="outlined"
                size="small"
                style={{ minWidth: 120 }}
              >
                <Select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}-{year + 1}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Download Icon Button */}
            <Grid item xs={4} sm={4} md={0.4}>
              <CustomTooltip text="Download Report">
                <IconButton
                  onClick={generateFinancialReport}
                  className=" text-black hover:bg-gray-800"
                  style={{
                    padding: 5,
                    borderRadius: "50%",
                  }}
                >
                  <Download style={{ fontSize: "20px" }} />
                </IconButton>
              </CustomTooltip>
            </Grid>
          </Grid>
        </Paper>

        {/* Summary Cards */}
        <Grid container spacing={3} style={{ marginBottom: "1rem" }}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} style={{ padding: "1rem" }}>
              <Typography variant="subtitle1">
                Total Billed Since April
              </Typography>
              <Typography variant="h5">
                {isInvoicesDataLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  formatCurrency(totalBilledSinceApril)
                )}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} style={{ padding: "1rem" }}>
              <Typography variant="subtitle1">Total Collections</Typography>
              <Typography variant="h5">
                {isInvoicesDataLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  formatCurrency(collectedInvoicesTotal)
                )}
              </Typography>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} style={{ padding: "1rem" }}>
              <Typography variant="subtitle1">Pending Invoices</Typography>
              <Typography variant="h5">
                {isInvoicesDataLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  formatCurrency(pendingInvoicesTotal)
                )}
              </Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Tabs for content navigation */}
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          style={{ marginBottom: "1rem" }}
        >
          <Tab label="Invoices" />
          <Tab label="Resource Performance" />
          <Tab label="Matter Performance" />
          <Tab label="Reimbursements" />
          <Tab label="Clocked Hours" />
        </Tabs>

        {/* Matterwise Performance Report Table */}
        <TabPanel value={tabIndex} index={2}>
          {isMatterDataLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="300px"
            >
              <PuffLoader color="blue" size={170} />
            </Box>
          ) : (
            <>
              {matterChartData.length === 0 &&
                matterPerformanceData.length === 0 && <NoDataFound />}

              {/* Matter Contribution Bar Chart */}
              {matterChartData.length > 0 && (
                <>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ minWidth: 120, marginBottom: "1rem" }}
                  >
                    <Select value={topN} onChange={handleTopNChange}>
                      {[5, 10, 15, 20].map((number) => (
                        <MenuItem key={number} value={number}>
                          Top {number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Paper
                    elevation={3}
                    style={{ marginTop: "1rem", padding: "1rem" }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Matter Contribution
                    </Typography>

                    <ResponsiveContainer width="100%" height={500}>
                      <BarChart
                        data={matterChartData}
                        margin={{ top: 20, right: 40, left: 40, bottom: 100 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="matter"
                          tick={{
                            angle: -20,
                            dy: 30,
                            fontSize: 12,
                          }}
                          interval={0}
                        />
                        <YAxis />
                        <RechartsTooltip formatter={(value) => `${value}%`} />
                        <Legend verticalAlign="top" align="right" />
                        <Bar
                          dataKey="contributionToTotal"
                          fill="#8884d8"
                          name="Contribution (%)"
                        >
                          {matterChartData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS2[index % COLORS2.length]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Paper>
                </>
              )}

              {matterPerformanceData.length > 0 && (
                <Paper
                  elevation={3}
                  style={{ marginTop: "1rem", padding: "1rem" }}
                >
                  <Typography variant="h6" gutterBottom>
                    Matterwise Performance Report
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Client</TableCell>
                          <TableCell>Matter</TableCell>
                          <TableCell align="right">Clocked Hours</TableCell>
                          <TableCell align="right">
                            Clocked Amount (
                            {localCurrencyData.nativeCurrencyCode})
                          </TableCell>
                          <TableCell align="right">
                            Billed Amount (
                            {localCurrencyData.nativeCurrencyCode})
                          </TableCell>
                          <TableCell align="right">Discount %</TableCell>
                          <TableCell align="right">
                            Contribution To Total %
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {matterPerformanceData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.client}</TableCell>
                            <TableCell>{row.matter}</TableCell>
                            <TableCell align="right">
                              {parseFloat(row.hoursClocked).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(row.actualTimeClocked).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(row.timeBilled).toLocaleString(
                                "en-IN",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(row.discountPercentage).toFixed(2)}%
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(row.contributionToTotal).toFixed(2)}%
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={3}>
                            <strong>
                              Total Amount (
                              {localCurrencyData.nativeCurrencyCode})
                            </strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>
                              {allMatterTotalBillableAmount
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>
                              {allMatterTotalBilledAmount
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
            </>
          )}
        </TabPanel>

        {/* Efficiency and Utilization Report Table */}
        <TabPanel value={tabIndex} index={1}>
          {isResourceDataLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="300px"
            >
              <PuffLoader color="blue" size={170} />
            </Box>
          ) : (
            <>
              {resourceChartData.length === 0 &&
                resourceEfficiencyData.length === 1 && <NoDataFound />}

              {resourceChartData.length > 0 && (
                <>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ minWidth: 120, marginBottom: "1rem" }}
                  >
                    <Select value={topN} onChange={handleTopNChange}>
                      {[5, 10, 15, 20].map((number) => (
                        <MenuItem key={number} value={number}>
                          Top {number}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Resource Contribution Bar Chart */}
                  <Paper
                    elevation={3}
                    style={{ marginTop: "1rem", padding: "1rem" }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Resource Contribution
                    </Typography>
                    <ResponsiveContainer width="100%" height={500}>
                      <BarChart
                        data={resourceChartData}
                        margin={{ top: 20, right: 40, left: 40, bottom: 80 }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="resource"
                          tick={{
                            angle: -20,
                            dy: 30,
                            fontSize: 12,
                          }}
                          interval={0}
                        />
                        <YAxis />
                        <RechartsTooltip
                          formatter={(value) => `${value} hrs`}
                        />
                        <Legend verticalAlign="top" align="right" />
                        <Bar
                          dataKey="billableHoursUtilized"
                          fill="#8884d8"
                          name="Clocked Hours"
                        >
                          {resourceChartData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS2[index % COLORS2.length]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Paper>
                </>
              )}

              {resourceEfficiencyData.length > 1 && (
                <Paper
                  elevation={3}
                  style={{ marginTop: "1rem", padding: "1rem" }}
                >
                  <Typography variant="h6" gutterBottom>
                    Efficiency and Utilization Report
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Resource</TableCell>
                          <TableCell align="right">Days worked</TableCell>
                          <TableCell align="right">
                            Available Clocked Hours
                          </TableCell>
                          <TableCell align="right">Clocked Hours</TableCell>
                          <TableCell align="right">Billed Hours</TableCell>
                          <TableCell align="right">W/O</TableCell>
                          <TableCell align="right">
                            Avg Clocked hrs/day
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resourceEfficiencyData.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.resource}</TableCell>
                            <TableCell align="right">
                              {row.noOfDaysWorked}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(row.availBillableHours).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(row.billableHoursUtilized).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(row.actualBilled).toFixed(2)}
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(row.discounting).toFixed(2)}%
                            </TableCell>
                            <TableCell align="right">
                              {parseFloat(
                                row.avgBillableHrsPerDay || 0
                              ).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              )}
            </>
          )}
        </TabPanel>

        {/* Invoices  */}
        <TabPanel value={tabIndex} index={0}>
          {isInvoicesDataLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="300px"
            >
              <PuffLoader color="blue" size={170} />
            </Box>
          ) : (
            <>
              {computePendingInvoicesData().length === 0 &&
                computeCollectedInvoicesData().length === 0 && <NoDataFound />}

              {/* Invoices Pie Chart  */}
              {(computePendingInvoicesData().length > 0 ||
                computeCollectedInvoicesData().length > 0) && (
                <ResponsiveContainer width="100%" height={400}>
                  <PieChart>
                    <Pie
                      data={totalInvoiceAmounts}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={150}
                      label={(entry) =>
                        `${
                          entry.name
                        }: ${nativeCurrencyCode} ${entry.value.toLocaleString(
                          "en-IN",
                          {
                            minimumFractionDigits: 2,
                          }
                        )}`
                      }
                      labelStyle={{
                        fill: "white",
                        fontSize: "12px",
                      }}
                    >
                      {totalInvoiceAmounts.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS2[index % COLORS2.length]}
                        />
                      ))}
                    </Pie>
                    <RechartsTooltip
                      formatter={(value) =>
                        `${nativeCurrencyCode} ${value.toLocaleString("en-IN", {
                          minimumFractionDigits: 2,
                        })}`
                      }
                      contentStyle={{
                        backgroundColor: "white",
                        border: "none",
                        color: "#F2F2F2",
                      }}
                    />
                    <Legend
                      iconType="circle"
                      layout="horizontal"
                      align="center"
                    />
                  </PieChart>
                </ResponsiveContainer>
              )}

              {/* Pending Invoices */}
              {computePendingInvoicesData().length > 0 && (
                <>
                  <Typography variant="h6" gutterBottom marginTop={5}>
                    Pending Invoices Tracker ({formattedMonthStartDate} -{" "}
                    {formattedMonthEndDate})
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Raised Date</TableCell>
                          <TableCell>Invoice No.</TableCell>
                          <TableCell>Invoice Period</TableCell>
                          <TableCell>Matter</TableCell>
                          <TableCell align="right">
                            Amount ({nativeCurrencyCode})
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {computePendingInvoicesData().map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.raisedDate}</TableCell>
                            <TableCell>{row.invoiceNumber}</TableCell>
                            <TableCell>{row.invoicePeriod}</TableCell>
                            <TableCell>{row.matter}</TableCell>
                            <TableCell align="right">
                              {row.amount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={4}>
                            <strong>
                              Total Pending Amount ({nativeCurrencyCode})
                            </strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>
                              {pendingInvoicesTotal.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              {/* Collected Invoices */}
              {computeCollectedInvoicesData().length > 0 && (
                <>
                  <Typography variant="h6" gutterBottom marginTop={5}>
                    Collections in this period ({formattedMonthStartDate} -{" "}
                    {formattedMonthEndDate})
                  </Typography>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Raised Date</TableCell>
                          <TableCell>Invoice No.</TableCell>
                          <TableCell>Invoice Period</TableCell>
                          <TableCell>Matter</TableCell>
                          <TableCell>Collection Date</TableCell>
                          <TableCell align="right">
                            Amount ({nativeCurrencyCode})
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {computeCollectedInvoicesData().map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.raisedDate}</TableCell>
                            <TableCell>{row.invoiceNumber}</TableCell>
                            <TableCell>{row.invoicePeriod}</TableCell>
                            <TableCell>{row.matter}</TableCell>
                            <TableCell>{row.collectionDate}</TableCell>
                            <TableCell align="right">
                              {row.amount.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell colSpan={5}>
                            <strong>
                              Total Collected Amount ({nativeCurrencyCode})
                            </strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>
                              {collectedInvoicesTotal.toLocaleString("en-IN", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </strong>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
            </>
          )}
        </TabPanel>

        {/* Reimbursements  */}
        <TabPanel value={tabIndex} index={3}>
          {isReimbursementsDataLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="300px"
            >
              <PuffLoader color="blue" size={170} />
            </Box>
          ) : (
            <>
              {expensesChartData.length === 0 && <NoDataFound />}

              {/* Monthly Bar Graph Reimbursements  */}
              {expensesChartData.length > 0 && (
                <Paper
                  elevation={3}
                  style={{ marginTop: "1rem", padding: "1rem" }}
                >
                  <Typography variant="h6" gutterBottom>
                    Reimbursements Over Time ({formattedMonthStartDate} -{" "}
                    {formattedMonthEndDate})
                  </Typography>
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={expensesChartData}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis
                        tickFormatter={(value) =>
                          `${nativeCurrencyCode} ${(value / 1000).toFixed(2)}k`
                        }
                      />
                      <Legend />
                      <RechartsTooltip
                        formatter={(value) =>
                          `${nativeCurrencyCode} ${parseFloat(
                            value
                          ).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                          })}`
                        }
                      />
                      <Bar
                        dataKey="amount"
                        fill="#8884d8"
                        name="Reimbursements"
                      >
                        {expensesChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS2[index % COLORS2.length]}
                          />
                        ))}
                        <LabelList
                          dataKey="amount"
                          position="top"
                          formatter={(value) =>
                            `${nativeCurrencyCode} ${parseFloat(
                              value
                            ).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                            })}`
                          }
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
              )}
            </>
          )}
        </TabPanel>

        {/* Clocked Hours  */}
        <TabPanel value={tabIndex} index={4}>
          {isClockedHoursDataLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="300px"
            >
              <PuffLoader color="blue" size={170} />
            </Box>
          ) : (
            <>
              {clockedHoursChartData.length === 0 && <NoDataFound />}

              {/* Monthly Bar Graph for Clocked Hours */}
              {clockedHoursChartData.length > 0 && (
                <Paper
                  elevation={3}
                  style={{ marginTop: "1rem", padding: "1rem" }}
                >
                  <Typography variant="h6" gutterBottom>
                    Clocked Hours Over Time ({formattedMonthStartDate} -{" "}
                    {formattedMonthEndDate})
                  </Typography>
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                      data={clockedHoursChartData}
                      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" />
                      <YAxis
                        tickFormatter={(value) => `${value.toFixed(2)} hrs`}
                      />
                      <Legend />
                      <RechartsTooltip
                        formatter={(value) =>
                          `${parseFloat(value).toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                          })} hrs`
                        }
                      />
                      <Bar dataKey="hours" fill="#8884d8" name="Clocked Hours">
                        {clockedHoursChartData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS2[index % COLORS2.length]}
                          />
                        ))}
                        <LabelList
                          dataKey="hours"
                          position="top"
                          formatter={(value) =>
                            `${parseFloat(value).toLocaleString("en-IN", {
                              minimumFractionDigits: 2,
                            })} hrs`
                          }
                        />
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Paper>
              )}
            </>
          )}
        </TabPanel>
      </div>
    </>
  );
};

export default Financial;

// import React, { useState, useEffect } from "react";
// import {
//   Paper,
//   Tabs,
//   Tab,
//   Typography,
//   Grid,
//   IconButton,
//   Box,
// } from "@mui/material";
// import { Download } from "@mui/icons-material";
// import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import formatDate from "../../utils/services";
// import Invoices from "../../components/admin/financial/Invoices";
// import ResourcePerformance from "../../components/admin/financial/ResourcePerformance";
// import MatterPerformance from "../../components/admin/financial/MatterPerformance";
// import Reimbursements from "../../components/admin/financial/Reimbursements";
// import ClockedHours from "../../components/admin/financial/ClockedHours";
// import TabPanel from "../../components/admin/financial/TabPanel";
// import CustomTooltip from "../../components/ui/CustomTootltip";

// const Financial = () => {
//   const [tabIndex, setTabIndex] = useState(0);
//   const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
//   const [startSelectedDate, setStartSelectedDate] = useState(
//     formatDate(new Date(selectedYear, 3, 1)) // April 1st
//   );
//   const [endSelectedDate, setEndSelectedDate] = useState(
//     formatDate(new Date(selectedYear + 1, 2, 31)) // March 31st of next year
//   );

//   useEffect(() => {
//     setStartSelectedDate(formatDate(new Date(selectedYear, 3, 1)));
//     setEndSelectedDate(formatDate(new Date(selectedYear + 1, 2, 31)));
//   }, [selectedYear]);

//   const handleTabChange = (event, newValue) => setTabIndex(newValue);

//   const handleDownloadReport = () => {
//     // Functionality for downloading the report
//     console.log("Download Financial Report");
//   };

//   return (
//     <>
//       <ToastContainer />
//       <Paper elevation={3} style={{ padding: "1rem", marginBottom: "1rem" }}>
//         <Grid container spacing={2} alignItems="center">
//           <Grid item xs={8}>
//             <Typography variant="h5">Financial Dashboard</Typography>
//           </Grid>
//           <Grid item xs={4}>
//             <CustomTooltip text="Download Report">
//               <IconButton onClick={handleDownloadReport}>
//                 <Download />
//               </IconButton>
//             </CustomTooltip>
//           </Grid>
//         </Grid>
//       </Paper>
//       <Tabs
//         value={tabIndex}
//         onChange={handleTabChange}
//         indicatorColor="primary"
//         textColor="primary"
//         variant="scrollable"
//         scrollButtons="auto"
//       >
//         <Tab label="Invoices" />
//         <Tab label="Resource Performance" />
//         <Tab label="Matter Performance" />
//         <Tab label="Reimbursements" />
//         <Tab label="Clocked Hours" />
//       </Tabs>

//       <TabPanel value={tabIndex} index={0}>
//         <Invoices
//           startSelectedDate={startSelectedDate}
//           endSelectedDate={endSelectedDate}
//         />
//       </TabPanel>
//       <TabPanel value={tabIndex} index={1}>
//         <ResourcePerformance
//           startSelectedDate={startSelectedDate}
//           endSelectedDate={endSelectedDate}
//         />
//       </TabPanel>
//       <TabPanel value={tabIndex} index={2}>
//         <MatterPerformance
//           startSelectedDate={startSelectedDate}
//           endSelectedDate={endSelectedDate}
//         />
//       </TabPanel>
//       <TabPanel value={tabIndex} index={3}>
//         <Reimbursements
//           startSelectedDate={startSelectedDate}
//           endSelectedDate={endSelectedDate}
//         />
//       </TabPanel>
//       <TabPanel value={tabIndex} index={4}>
//         <ClockedHours
//           startSelectedDate={startSelectedDate}
//           endSelectedDate={endSelectedDate}
//         />
//       </TabPanel>
//     </>
//   );
// };

// export default Financial;
